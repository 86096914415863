import { navigate } from "@reach/router";
import React from "react";
import { useStateValue } from "../../hooks/StateContext";
import { setApplicationStateToDefault } from "../../models/ApplicationStateActions";
import ContinueButton from "../Controls/ContinueButton";
import SectionTitle from "../Controls/SectionTitle";

const RetrieveQuoteApplicationComplete = () => {
    const { dispatch } = useStateValue();

    const newInsuranceQuote = () => {
        dispatch(setApplicationStateToDefault());
        navigate('/');
    };

    return (
        <>
            <SectionTitle title="This application has already been completed." />
            <ContinueButton onClick={newInsuranceQuote}>Get a new Quote</ContinueButton>
        </>
    );
};

export default RetrieveQuoteApplicationComplete;