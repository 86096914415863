import { navigate } from "@reach/router";
import React, { useEffect, useState } from "react";
import useDecryptRetrieveQuote from "../../hooks/useDecryptRetrieveQuote";
import ApiError from "../ApiError";
import Loading from "../Controls/Loading";

interface Props {
    code: string;
};

const Code = ({ code }: Props) => {
    const { loading, error } = useDecryptRetrieveQuote({ Code: code });
    const [finishedLoading, setFinishedLoading] = useState(false);

    useEffect(() => {
        if (finishedLoading && !error) {
            navigate("/direct");
        }
    }, [finishedLoading]);

    const loadingStages = ["Validating your details", "Retrieving your quote"];
    if (!finishedLoading) return <Loading stages={loadingStages} interval={1000} isLoading={loading} setFinishedLoading={setFinishedLoading} />
    if (error) return (<ApiError />);
    return (null);
};

export default Code;