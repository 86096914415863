import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { PossessionsModel } from "../Possessions/PossessionsModel";
import Card from "./Card";
import MaterialButton from "./MaterialButton";
import YesNoQuestion from "./YesNoQuestion";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        possessionsListContent: {
            padding: theme.spacing(1.5, 2, 0, 2),
        },
        possessionsListContentItem: {
            padding: theme.spacing(0.5, 0),
            '&:not(:last-child)': {
                borderBottom: '1px solid #e5e5e5',
            }
        },
        possessionsListContentTitle: {
            fontFamily: "Spartan MB SemiBold",
            fontSize: '1rem',
            paddingBottom: '4px',
        },
        possessionsListContentValue: {
            fontFamily: "Spartan MB",
            fontSize: '1rem',
        },
        possessionsListChangeButton: {
            fontFamily: "Spartan MB SemiBold",
            float: 'right',
            fontSize: '0.75rem',
            padding: '3px 8px',
            backgroundColor: 'transparent',
            borderWidth: '2px',
            top: '16px',
        },
        possessionsListAddButton: {
            fontFamily: "Spartan MB SemiBold",
            fontSize: '0.875rem',
            color: theme.buttons.secondary,
            width: '100%',
            backgroundColor: 'transparent',
            marginTop: '8px',
            marginBottom: '16px',
            height: '38px',
            borderWidth: '2px',
        },
    })
);

type Props = {
    title: string;
    testId?: string;
    icon: JSX.Element;
    helpText: string | JSX.Element;
    helpTitle: string;
    noPossessionsText: string;
    possessions: PossessionsModel[];
    addChangeHandle: (editItem?: PossessionsModel) => void;
};

const PossessionsList = ({
    title,
    testId,
    icon,
    helpText,
    helpTitle,
    noPossessionsText,
    possessions,
    addChangeHandle,
}: Props) => {
    const classes = useStyles();

    const handleYesNo = (answer: boolean) => {
        if (answer)
            addChangeHandle();
    };

    return (
        <Card
            title={title}
            icon={icon}
            helpText={helpText}
            helpTitle={helpTitle}
            addHeaderSeparator={true}
            testId={testId}
        >
            <div className={classes.possessionsListContent}>
                {
                    possessions.length === 0 ?
                        <React.Fragment>
                            <YesNoQuestion
                                questionText={noPossessionsText}
                                onClick={handleYesNo}
                                initialAnswer={false}
                            />
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <div>
                                {
                                    possessions.map((x, index) => {
                                        return (
                                            <div key={`possession-${title}-${index}`} className={classes.possessionsListContentItem}>
                                                <MaterialButton testId="changeButton" className={classes.possessionsListChangeButton} onClick={() => addChangeHandle(x)}>Change</MaterialButton>
                                                <Typography id="high-value-item-description" className={classes.possessionsListContentTitle}>{x.ItemDescription}</Typography>
                                                <Typography id="item-value" className={classes.possessionsListContentValue}>£{(parseInt(x.ItemValue.toString())).toLocaleString('en')}</Typography>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <MaterialButton testId="addAnother" className={classes.possessionsListAddButton} onClick={() => addChangeHandle()}>Add another</MaterialButton>
                        </React.Fragment>
                }
            </div>
        </Card>
    );
};

export default PossessionsList;