import { Box, Grid, useTheme } from '@material-ui/core';
import { navigate } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { useStateValue } from '../../hooks/StateContext';
import useEligibilityQuestionsAPI from '../../hooks/useEligibilityQuestionsAPI';
import ApiError from '../ApiError';
import BackButton from '../Controls/BackButton';
import ContactModal from '../Controls/ContactModal';
import ContinueButton from '../Controls/ContinueButton';
import EligibilityQuestionsCard from '../Controls/EligibilityQuestionsCard';
import Loading from '../Controls/Loading';
import {
    setAgreeDeclarations,
    setAgreeDeclarationsForSection,
    setShowReferModal,
} from '../Eligibility/EligibilityQuestionsActions';

const Eligibility = () => {
    const { loading, error } = useEligibilityQuestionsAPI();
    const { state, dispatch } = useStateValue();
    const [finishedLoading, setFinishedLoading] = useState(false);
    const theme = useTheme();

    useEffect(() => {
        if (
            state.EligibilityGrouping.some((eligibilityGroup) => eligibilityGroup.agreed !== true)
        ) {
            dispatch(setAgreeDeclarations(false));
        } else {
            dispatch(setAgreeDeclarations(true));
        }
    }, [state.EligibilityGrouping]);

    const handleAnswer = (sectionId: number) => (answer: boolean) => {
        if (!answer) {
            dispatch(setShowReferModal(true));
        }

        dispatch(setAgreeDeclarationsForSection(sectionId, answer));
    };

    const handleClose = () => {
        dispatch(setShowReferModal(false));
    };

    const handleNext = () => {
        if (state.AgreeDeclarations) {
            if (state && state.EndorsementQuestions && state.EndorsementQuestions.length > 0) {
                navigate('/direct/endorsements');
            } else {
                navigate('/direct/policyinformation');
            }
        } else {
            dispatch(setAgreeDeclarations(false));
            console.log('Disagreed on at least one section of the declaration');
        }
    };

    const loadingStages = [
        'Tailoring your quote',
        'Confirming your cheapest quote',
        'Loading eligibility questions',
    ];
    if (!finishedLoading)
        return (
            <Loading
                stages={loadingStages}
                interval={1000}
                isLoading={loading}
                setFinishedLoading={setFinishedLoading}
            />
        );
    return (
        <React.Fragment>
            {error && <ApiError />}
            {state.EligibilityQuestions && (
                <>
                    <Box m={1}>
                        <p>
                            Please take care to answer all questions honestly and to the best of
                            your knowledge.
                        </p>
                        <p>
                            If you don’t, your policy may be cancelled or your claim rejected. When
                            entering your details we strongly recommend you read the help text and
                            important information we’ve provided.
                        </p>
                    </Box>
                    {state.EligibilityGrouping.map((eligibilityGroup, index) => (
                        <Grid key={index} item xs={12}>
                            <EligibilityQuestionsCard
                                title={eligibilityGroup.name}
                                questionGroup={eligibilityGroup}
                                handleClick={handleAnswer(eligibilityGroup.id)}
                            />
                        </Grid>
                    ))}
                    <div style={{ display: 'flex' }}>
                        <BackButton>Back</BackButton>
                        <ContinueButton
                            testId='eligibilityNext'
                            disabled={!state.AgreeDeclarations}
                            onClick={handleNext}
                        >
                            Next
                        </ContinueButton>
                    </div>
                </>
            )}
            <ContactModal
                open={state.ShowReferModal}
                title='Sorry.'
                subTitle='You must be able to agree to all of our eligibility questions in order to proceed with your application'
                content={`Please call us on ${theme.metadata.phoneNumber}.`}
                onClose={handleClose}
                showCloseButton={true}
                closeButtonLabel='Take me back to the question'
            />
        </React.Fragment>
    );
};

export default Eligibility;
