import React from "react";
import { Box } from "@material-ui/core";

type Props = {
    children: JSX.Element | JSX.Element[];
    value: any;
    onClick: (newValue: any) => void;
}

const CustomRadioGroup = ({ children, value, onClick }: Props) => {
    const clonedChildren = React.Children.map(children, child => {
        return React.cloneElement(child, {
            onClick: onClick,
            currentValue: value
        });
    });

    return (
        <Box display="flex" justifyContent="center">
            {clonedChildren}
        </Box>
    );
};

export default CustomRadioGroup;