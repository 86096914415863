import { createStyles, InputAdornment, makeStyles, TextField, Theme } from "@material-ui/core";
import { FieldProps } from "formik";
import React from 'react';
import MaterialButton from "./MaterialButton";
import useStyles from "./Styles/MaterialTextFieldUseStyles";

const useButtonStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            fontFamily: "Spartan MB SemiBold",
            fontSize: "0.75rem",
            border: 0,
            width: '140px',
            height: '40px',
            padding: theme.spacing(1, 0),
            marginBottom: 20,
        },
        buttonEnabled: {
            backgroundColor: theme.buttons.secondary,
            color: theme.palette.secondary.contrastText,
            '&:focus, &:hover': {
                backgroundColor: theme.buttons.secondary,
            },
        },
    })
);

type Props = {
    children: string;
    testId?: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    disabled?: boolean;
};

const InlineButton = ({ children, onClick, disabled, testId }: Props) => {
    const classes = useButtonStyles();
    const classList = [];
    if (!disabled) classList.push(classes.buttonEnabled);

    return (
        <MaterialButton
            className={`${classes.button} ${classList.join(" ")}`}
            testId={testId}
            disabled={disabled}
            onClick={onClick!}
        >
            {children}
        </MaterialButton>
    );
};

type ButtonProps = {
    disableInlineButton?: boolean;
    onInlineClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const MaterialTextFieldAndButton = <P extends object>({
    onInlineClick,
    disableInlineButton,
    field,
    form: {
        touched,
        errors,
    },
    ...props
}: FieldProps<P> & ButtonProps) => {
    const classes = useStyles();
    const get = (o: any, path: string) => path
        .split('.')
        .reduce((o: any = {}, key: string) => o[key], o);

    const touchedField = get(touched, field.name);
    const errorsField = get(errors, field.name);

    return (
        <TextField
            className={classes.textField}
            fullWidth
            margin="normal"
            helperText={touched && touchedField ? errors && errorsField : ""}
            error={touched && touchedField && errors && Boolean(errorsField)}
            inputProps={{
                maxLength: 100
            }}
            InputProps={{
                endAdornment:
                    <InputAdornment position="end">
                        <InlineButton testId="findAddress" disabled={disableInlineButton} onClick={onInlineClick}>Find Address</InlineButton>
                    </InputAdornment>
            }}
            InputLabelProps={{
                shrink: true,
                classes: {
                    root: classes.labelRoot
                }
            }}
            {...props}
            {...field}
        />
    );
};

export default MaterialTextFieldAndButton;