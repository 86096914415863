import { createStyles, Grid, makeStyles, Theme, Hidden, MenuItem } from "@material-ui/core";
import { Field, Form, Formik, FormikProps, FieldProps } from "formik";
import React, { useState } from "react";
import { useStateValue } from "../../../../hooks/StateContext";
import MaterialButton from "../../../Controls/MaterialButton";
import MaterialTextField from "../../../Controls/MaterialTextField";
import PossessionsHomeAwaySummary from "../../../Controls/PossessionsHomeAwaySummary";
import SelectOverlayFormik from "../../../Controls/SelectOverlayFormik";
import { PossessionsModel } from "../../../Possessions/PossessionsModel";
import { ListOfHighValueItems, HighValueItems, Include } from "../../../Possessions/PossessionsValuesModel";
import MaterialSelect from "../../../Controls/MaterialSelect";
import MaterialFormattedNumericField from "../../../Controls/MaterialFormattedNumericField";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonsGroup: {
            padding: theme.spacing(1.5, 0),
        },
        button: {
            width: '100%',
            fontSize: '0.875rem',
            height: '40px',
            padding: '0',
            '&.addButton, &.saveUseButton': {
                borderColor: 'transparent',
                backgroundColor: theme.palette.primary.main,
                color: '#ffffff',
            },
            '&.cancelButton': {
                color: theme.palette.secondary.main,
            },
            '&.removeButton': {
                borderWidth: '1px',
                borderColor: '#fc6849',
                color: '#fc6849',
            },
        },
        removeButtonGroup: {
            margin: theme.spacing(2.5, 0),
        },
    })
);

interface FormProps {
    ItemDescription: string;
    ItemType: string;
    ItemValue: number;
    IncludeAtHome: boolean;
    IncludeAwayFromHome: boolean;
    IsEditing: boolean;
    onSelectOverlay?: (currentItem: PossessionsModel) => void;
    onSaveChanges: (item: PossessionsModel) => void;
    onRemove: (item: PossessionsModel) => void;
    onCancel?: () => void;
};

const PossessionsHighValueItemForm = ({ onSelectOverlay, onSaveChanges, onRemove, onCancel, ItemDescription, ItemType, ItemValue, IncludeAtHome, IncludeAwayFromHome, IsEditing }: FormProps) => {
    const classes = useStyles();
    const { state } = useStateValue();
    const [itemDetails, setItemDetails] = useState<HighValueItems | undefined>(ListOfHighValueItems.find(e => e.Id === ItemType));

    const handleSelectChange = (e: any) => {
        setItemDetails(ListOfHighValueItems.find(element => element.Id === e.target.value));
    };

    const clearFormatting = (value: string) => {
        return Number(value.replace(/,/g, ''));
    };

    return (
        <Formik
            initialValues={{
                ItemDescription: ItemDescription,
                ItemType: ItemType,
                ItemValue: ItemValue,
                IncludeAtHome: IncludeAtHome,
                IncludeAwayFromHome: IncludeAwayFromHome,
                IsEditing: IsEditing
            }}
            validate={(v) => {
                let errors: any = {};
                const numericItemValue: number = clearFormatting(v.ItemValue.toString());

                if (v.ItemType === '') {
                    errors.ItemType = 'Please select an item type.';
                }

                if (v.ItemDescription === '') {
                    errors.ItemDescription = 'Description is required.';
                }

                if (numericItemValue < 2500) {
                    errors.ItemValue = 'Cost to replace must be at least £2,500.';
                }

                const totalValueInside = (state.ContentsPersonalPossessions
                    .reduce((sum: number, item: PossessionsModel) =>
                        +sum + +item.ItemValue, 0)
                    //If we're editing, then we need to take into account the current (old) value of the item
                    //but only if the cover at home/away from home option has not been changed
                    - (v.IsEditing ? +ItemValue : 0)
                    + +numericItemValue);

                if (totalValueInside > 15000) {
                    errors.ItemValue = 'Total value of all possessions cannot exceed £15,000.';
                }

                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                onSaveChanges({
                    ...values,
                    IncludeAtHome: itemDetails!.AllowedAtHome === Include.Yes,
                    IncludeAwayFromHome: itemDetails!.AllowedAwayFromHome === Include.Optional ?
                        values.IncludeAwayFromHome :
                        itemDetails!.AllowedAwayFromHome === Include.Yes
                });
                setSubmitting(false);
            }}
        >{({ values }: FormikProps<any>) =>
            <Form noValidate>
                <Hidden smUp>
                    <Field name="ItemType">
                        {({
                            field,
                            form,
                            meta,
                        }: FieldProps) => (
                                <SelectOverlayFormik
                                    label="What type of item is it?"
                                    placeholder="e.g. Jewellery / Watches"
                                    value={values.ItemType && ListOfHighValueItems.find(element => element.Id === values.ItemType)!.Value}
                                    onClick={() => onSelectOverlay!(values)}
                                    form={form}
                                    meta={meta}
                                    field={
                                        {
                                            ...field,
                                            value: values.ItemType && ListOfHighValueItems.find(element => element.Id === values.ItemType)!.Value
                                        }
                                    }
                                    data-testid="item-type"
                                />
                            )}
                    </Field>
                </Hidden>
                <Hidden xsDown>
                    <Field name="ItemType"
                        id="itemType"
                        label="What type of item is it?"
                        component={MaterialSelect}
                        onChange={(e: React.ChangeEvent<Element>) => {
                            handleSelectChange(e);
                        }}
                        inputProps={{
                            "data-testid": 'item-type'
                        }}
                    >
                        {ListOfHighValueItems &&
                            ListOfHighValueItems.map((x, index) => {
                                return (<MenuItem key={index} value={x.Id}>{x.Value}</MenuItem>);
                            })
                        }
                    </Field>
                </Hidden>
                <Field id="itemDescription"
                    name="ItemDescription"
                    label="Describe the item"
                    component={MaterialTextField}
                    placeholder="e.g. rolex watch"
                />
                <Field id="itemValue"
                    name="ItemValue"
                    label="Cost to replace it"
                    component={MaterialFormattedNumericField}
                />

                <PossessionsHomeAwaySummary
                    itemDetails={itemDetails}
                    values={values}
                    initialAnswer={values.IncludeAwayFromHome}
                />
                <Grid container spacing={2} className={classes.buttonsGroup}>
                    <Grid item xs={6}>
                        <MaterialButton
                            testId="highvalue-cancel"
                            className={`${classes.button} cancelButton`}
                            type="reset"
                            onClick={() => {
                                if (typeof (onCancel) !== 'undefined') {
                                    onCancel();
                                } else {
                                    history.back();
                                }
                            }}>
                            Cancel
                        </MaterialButton>
                    </Grid>
                    <Grid item xs={6}>
                        {
                            values.IsEditing ?
                                <MaterialButton
                                    testId="highvalue-saveuse"
                                    className={`${classes.button} saveUseButton`}
                                    onClick={() => onSaveChanges}>
                                    Save &amp; use
                                </MaterialButton>
                                :
                                <MaterialButton
                                    testId="highvalue-add"
                                    className={`${classes.button} addButton`}
                                    onClick={() => onSaveChanges}>
                                    Add
                                </MaterialButton>
                        }
                    </Grid>
                    <Grid item xs={12} className={classes.removeButtonGroup}>
                        {
                            values.IsEditing &&
                            <MaterialButton
                                testId="highvalue-remove"
                                className={`${classes.button} removeButton`}
                                onClick={() => onRemove(values)}
                                type="reset">
                                Remove
                            </MaterialButton>
                        }
                    </Grid>
                </Grid>
            </Form>
            }
        </Formik>
    );
};

export default PossessionsHighValueItemForm;