import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { navigate } from "@reach/router";
import React, { useEffect, useState } from "react";
import { useStateValue } from "../../hooks/StateContext";
import useRetrieveDecryptCode from "../../hooks/useQuoteReferenceDecryptor";
import useRetrieveQuote from "../../hooks/useRetrieveQuote";
import ApiError from "../ApiError";
import Loading from "../Controls/Loading";
import SectionTitle from "../Controls/SectionTitle";
import { setIllustrationComplete } from "../PolicyInformation/PolicyInformationActions";
import RetrieveQuoteDetailsForm from "../RetrieveQuote/RetrieveQuoteDetailsForm";
import { InitialRetrieveQuoteDetails, RetrieveQuoteRequest } from "../RetrieveQuote/RetrieveQuoteRequest";
import { RetrieveQuoteStatusEnum } from "../RetrieveQuote/RetrieveQuoteStatusEnum";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        quoteReference: {
            marginTop: theme.spacing(1),
            fontFamily: "Spartan MB",
            '& label': {
                fontFamily: "Spartan MB SemiBold",
            },
            '& p': {
                fontSize: "0.875rem",
                margin: theme.spacing(1, 0),
            }
        },
    })
);
interface Props {
    code: string;
};

const RetrieveQuote = ({ code }: Props) => {
    const { state } = useStateValue();
    const classes = useStyles();
    const [quoteDetails, setQuoteDetails] = useState<RetrieveQuoteRequest>(InitialRetrieveQuoteDetails);
    const { results, loading, error } = useRetrieveDecryptCode({ Code: code });
    const { loading: validating, error: failedValidation } = useRetrieveQuote({ RetrieveQuoteDetails: quoteDetails });
    const [finishedLoading, setFinishedLoading] = useState(true);
    const [isRedirecting, setIsRedirecting] = useState(false);

    const handleSubmit = async (values: RetrieveQuoteRequest) => {
        setFinishedLoading(false);
        setQuoteDetails(values);
    };

    const redirectToPage = (page: string) => {
        setIsRedirecting(true);
        navigate(page);
    };

    useEffect(() => {
        if (!failedValidation && finishedLoading && !isRedirecting) {
            switch (state.QuoteStatus) {
                case RetrieveQuoteStatusEnum.Quote:
                    redirectToPage("/direct");
                    break;
                case RetrieveQuoteStatusEnum.Illustration:
                    setIllustrationComplete();
                    redirectToPage("/direct/eligibility");
                    break;
                case RetrieveQuoteStatusEnum.ApplicationCompleted:
                    redirectToPage("/direct/retrievequotecomplete");
                    break;
            }
        }
    }, [state, finishedLoading]);

    if (loading) return (<>Loading...</>);
    if (error) return (<ApiError />);
    const loadingStages = ["Validating your details", "Retrieving your quote"];
    if (!finishedLoading) return (<Loading stages={loadingStages} interval={1000} isLoading={validating} setFinishedLoading={setFinishedLoading} />);
    return (
        <>
            <SectionTitle title="Retrieve a quote" />
            <div className={classes.quoteReference}>
                <label>Quote Reference</label>
                <Typography variant="body1">{results && results.quoteReference}</Typography>
            </div>
            <RetrieveQuoteDetailsForm
                Code={code}
                Surname={quoteDetails.Surname}
                DateOfBirth={quoteDetails.DateOfBirth}
                Postcode={quoteDetails.Postcode}
                FailedValidation={failedValidation}
                SubmitHandler={handleSubmit}
            />
        </>
    )
};

export default RetrieveQuote;