import { useFormikContext } from "formik";
import { useEffect, useState } from "react";

const useDisableContinue = () => {
    const { dirty, isValid, errors, initialValues } = useFormikContext();
    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        const flattened = Object.assign(
            {},
            ...function _flatten(o: any): any {
                return [].concat(...Object.keys(o)
                    .map(k =>
                        typeof o[k] === 'object' ?
                            _flatten(o[k]) :
                            ({ [k]: o[k] })
                    )
                );
            }(initialValues)
        );

        // If any the initial values are populated, then the form must have been submitted succesfully
        // at some point, and therefore must contain valid values.
        const populatedInitialValues = Object.values(flattened as any).filter((value: any) => {
            return (value && value.length > 0) ? true : false;
        });

        if ((dirty || populatedInitialValues.length > 0) && isValid && Object.values(errors).length == 0) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }

    }, [dirty, isValid, errors]);

    return { isDisabled };
};

export default useDisableContinue;