import * as Yup from "yup";
import { postcodeRegex } from "../Address/AddressDetailsValidation";
import { dobRegex, nameRegex } from "../Applicant/ApplicantDetailsModel";
import { calculateAge } from "../utils";

export interface RetrieveQuoteRequest {
    Code: string;
    Surname: string;
    DateOfBirth: string;
    Postcode: string;
};

export const InitialRetrieveQuoteDetails: RetrieveQuoteRequest = {
    Code: '',
    Surname: '',
    DateOfBirth: '',
    Postcode: '',
};

export const RetrieveQuoteValidationSchema = Yup.object({
    Surname: Yup.string()
        .matches(nameRegex, "Please enter a valid surname")
        .required("Surname is required"),
    DateOfBirth: Yup.string()
        .matches(dobRegex, "Please enter a valid date of birth")
        .test("test-minimum-age", "Must be at least the age of 18", function (value) {
            return calculateAge(value) >= 18;
        })
        .test("test-maximum-age", "Applicant is too old", function (value) {
            return calculateAge(value) < 110;
        })
        .required("Date of birth is required"),
    Postcode: Yup.string().required("Postcode is required").matches(postcodeRegex, "Enter a valid postcode"),
});