import { Container, createStyles, makeStyles, Theme, Box } from '@material-ui/core';
import React from 'react';
import CookieBar from '../Controls/CookieBar';
import Footer from '../Footer';
import Header from '../Header';
import QuoteHeader from '../QuoteResults/QuoteHeader';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        outerContainer: {
            marginLeft: 0,
            marginRight: 0,
            maxWidth: '100%',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: theme.desktopMarginColour,
            [theme.breakpoints.down('sm')]: {
                padding: 0,
            }
        },
        container: {
            minHeight: '100vh',
            padding: '1rem 1rem',
            paddingBottom: '0',
            backgroundColor: theme.palette.background.default,
            [theme.breakpoints.only('sm')]: {
                width: '100%',
            },
            [theme.breakpoints.up('md')]: {
                width: '700px',
            },
        },
    })
);

const DirectRoute = ({ component: Component, showQuoteHeader, hideBackButton, path, ...rest }: any) => {
    const classes = useStyles();

    return (
        <>
            <Header hideBackButton={hideBackButton} />
            {showQuoteHeader &&
                <QuoteHeader />
            }
            <Container className={classes.outerContainer}>
                <Box maxWidth='lg' className={classes.container}>
                    <Container maxWidth='sm'>
                        <Component {...rest} />
                    </Container>
                </Box>
            </Container>
            <Footer />
            <CookieBar />
        </>
    );
};

export default DirectRoute;