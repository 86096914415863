import { createStyles, makeStyles, Theme } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import React from 'react';
import MaterialModal from "../Controls/MaterialModal";
import ModalContent from "../Controls/ModalContent";
import ModalHeader from "../Controls/ModalHeader";
import ModalTitle from "../Controls/ModalTitle";
import { SingleItem } from '../Controls/OverlayItemList';
import { ListOfPersonalPossessionsValues } from './PossessionsValuesModel';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonList: {
            listStyle: 'none',
            padding: 0,
            margin: 0,
            '& li:not(:last-child)': {
                borderBottom: '2px solid #e5e5e5',
            },
        },
        buttonDiv: {
            display: 'flex',
            width: '100%',
            height: '50px',
            alignItems: 'center',
        },
        button: {
            flexGrow: 1,
            fontFamily: "Spartan MB",
            fontSize: "14px",
            cursor: "pointer",
            background: "none",
            border: "none",
            margin: theme.spacing(1, 0),
            padding: "0",
            textAlign: 'left',
            '&:active, &:focus': {
                "outline": "none",
            },
        },
        tick: {
            color: theme.palette.secondary.main,
        },
    })
);

interface Props {
    open: boolean;
    onCancel: () => void;
    handleSelectItem: (selectedItem: SingleItem) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    item: string;
};

const PossessionsPersonalBelongingsModal = ({ open, onCancel, handleSelectItem, item }: Props) => {
    const classes = useStyles();
    return (
        <MaterialModal
            open={open}
            onClose={onCancel}
        >
            <>
                <ModalHeader onClick={onCancel}>
                    <ModalTitle id="high-value-item-modal">
                        <>Personal possessions</>
                    </ModalTitle>
                </ModalHeader>
                <ModalContent>
                    <ul data-testid="personal-belongings-list" className={classes.buttonList}>
                        {ListOfPersonalPossessionsValues &&
                            ListOfPersonalPossessionsValues.map((x, index) => {
                                return (
                                    <li key={index}>
                                        <div className={classes.buttonDiv}>
                                            <button className={classes.button} onClick={handleSelectItem(x)}>
                                                {index > 0 ? '£' + x.Value : x.Value}
                                            </button>
                                            {
                                                item === x.Id &&
                                                <CheckIcon className={classes.tick} />
                                            }
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </ModalContent>
            </>
        </MaterialModal>
    );
};

export default PossessionsPersonalBelongingsModal;