import { RouteComponentProps } from "@reach/router";
import React from "react";
import { useStateValue } from "../../../../hooks/StateContext";
import Overlay from "../../../Controls/Overlay";
import { setRemovingSpecificPossession, upsertPersonalPossession } from "../../../Possessions/PossessionsActions";
import { PossessionsBicycleModelInitial, PossessionsModel } from "../../../Possessions/PossessionsModel";
import { refreshQuotes } from "../../../QuoteResults/QuoteResultsActions";
import PossessionsBicycleForm from "./PossessionsBicycleForm";

interface Props extends RouteComponentProps { }

const PossessionsBicycleOverlay = ({ }: Props) => {
    const { state, dispatch } = useStateValue();

    const saveChanges = (values: PossessionsModel) => {
        dispatch(refreshQuotes());
        dispatch(upsertPersonalPossession(values));
        history.back();
    }

    const handleRemove = (values: PossessionsModel) => {
        dispatch(setRemovingSpecificPossession(values));
        history.back();
    }

    const getLoadItem = (): PossessionsModel => {
        const item = state.ContentsPersonalPossessions.filter(x => x.IsEditing);
        if (item.length > 0) {
            return item[0];
        } else {
            return { ...PossessionsBicycleModelInitial };
        }
    };

    const item = getLoadItem();

    return (
        <Overlay title="Bicycle">
            <PossessionsBicycleForm
                ItemDescription={item.ItemDescription}
                ItemType={item.ItemType}
                ItemValue={item.ItemValue}
                IncludeAtHome={item.IncludeAtHome}
                IncludeAwayFromHome={item.IncludeAwayFromHome}
                IsEditing={item.IsEditing}
                onSaveChanges={saveChanges}
                onRemove={handleRemove}
            />
        </Overlay>
    );
};

export default PossessionsBicycleOverlay;