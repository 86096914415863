import { Action } from "../../models/Action";
import { ActionType } from "../../models/ActionType";
import { ExcessTypeEnum } from "./ExcessTypeEnum";

export const setEditingExcess = (value: ExcessTypeEnum): Action => {
    return {
        type: ActionType.SetEditingExcess,
        value,
    };
};

export const setBuildingsExcess = (value: number): Action => {
    return {
        type: ActionType.SetBuildingsExcess,
        value,
    };
};

export const setContentsExcess = (value: number): Action => {
    return {
        type: ActionType.SetContentsExcess,
        value,
    };
};