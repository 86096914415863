import { Action } from "../../models/Action";
import { ActionType } from "../../models/ActionType";
import { PossessionsModel } from "./PossessionsModel";

export const upsertPersonalPossession = (value: PossessionsModel): Action => {
    return {
        type: ActionType.UpsertPersonalPossession,
        value,
    };
}

export const setEditingSpecificPossession = (value: PossessionsModel): Action => {
    return {
        type: ActionType.SetEditingPersonalPossession,
        value,
    };
};

export const setRemovingSpecificPossession = (value: PossessionsModel): Action => {
    return {
        type: ActionType.SetRemovingPersonalPossession,
        value,
    };
};

export const setResetIsEditingPossessions = (): Action => {
    return {
        type: ActionType.SetResetIsEditingPossessions,
    } as Action;
}

export const setHighValueItemEdit = (value: PossessionsModel): Action => {
    return {
        type: ActionType.SetHighValueItemEdit,
        value,
    }
}

export const setPersonalPossessionValue = (value: string): Action => {
    return {
        type: ActionType.SetPersonalPossessionValue,
        value,
    };
};