import { FormControl, FormHelperText, Input, InputLabel } from '@material-ui/core';
import { FieldProps } from 'formik';
import React from 'react';
import MaskedInput from 'react-text-mask';
import { useStyles } from './Styles/MaterialTextFieldUseStyles';

interface TextMaskCustomProps {
    inputRef: (ref: HTMLInputElement | null) => void;
};

const TextMaskCustom = (props: TextMaskCustomProps) => {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref: any) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, ' ', '/', ' ', /\d/, /\d/, ' ', '/', ' ', /\d/, /\d/, /\d/, /\d/]}
            guide={false}
            showMask
            placeholder={"dd / mm / yyyy"}
        />
    );
};

interface Props {
    label: string;
};

const MaterialDateInput = <P extends object>({
    label,
    field,
    form: {
        touched,
        errors,
    },
    ...props
}: FieldProps<P> & Props) => {
    const classes = useStyles({ boldLabel: false, reducedMargin: true })();
    const get = (o: any, path: string) => path
        .split('.')
        .reduce((o: any = {}, key: string) => o[key], o);

    const touchedField = get(touched, field.name);
    const errorsField = get(errors, field.name);

    return (
        <FormControl fullWidth className={classes.textField}>
            <InputLabel shrink htmlFor={`${field.name}`}>{label}</InputLabel>
            <Input
                name={field.name}
                error={touched && touchedField && errors && Boolean(errorsField)}
                {...props}
                {...field}
                inputComponent={TextMaskCustom as any}
                id={`${field.name}`}
            />
            {touchedField && errorsField && <FormHelperText error={true}>{errorsField}</FormHelperText>}
        </FormControl>
    );
};

export default MaterialDateInput;