import { createStyles, FormControl, FormHelperText, InputLabel, makeStyles, Select, Theme } from "@material-ui/core";
import { FieldProps } from "formik";
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        select: {
            fontFamily: "Spartan MB",
            fontSize: "0.875rem",
            '& .MuiInput-input': {
                paddingBottom: 14,
                paddingTop: 12
            },
            '& .MuiInput-underline:before': {
                borderBottom: '2px solid #e5e5e5',
            },
            marginTop: 14
        },
        labelRoot: {
            color: theme.palette.text.primary,
            fontFamily: "Spartan MB SemiBold",
            fontSize: "1rem",
            transform: "scale(1)",
            marginTop: 4
        },
    })
);

type Props = {
    onChange?: (e: any) => void;
}

const MaterialSelect = <P extends object>({
    field,
    form: {
        touched,
        errors,
        setFieldValue,
        setFieldTouched,
    },
    onChange,
    ...props
}: FieldProps<P> & Props) => {
    const classes = useStyles();
    const get = (o: any, path: string) => path
        .split('.')
        .reduce((o: any = {}, key: string) => o[key], o);

    const touchedField = get(touched, field.name);
    const errorsField = get(errors, field.name);

    return (
        <FormControl fullWidth margin="normal" className={classes.select}>
            <InputLabel
                htmlFor={field.name}
                shrink={true}
                classes={{
                    root: classes.labelRoot
                }}>{(props as any).label}
            </InputLabel>
            <Select
                fullWidth
                className={classes.select}
                error={touched && touchedField && errors && Boolean(errorsField)}
                {...props}
                {...field}
                onChange={(e: any) => {
                    setFieldValue(field.name as any, e.target.value!.toString());
                    setFieldTouched(field.name as any, true);
                    if (onChange) {
                        onChange(e);
                    }
                }}
            >
                {(props as any).children}
            </Select>
            {
                touched && touchedField && errors && Boolean(errorsField) &&
                <FormHelperText error={true}>{touched && touchedField ? errors && errorsField : ""}</FormHelperText>
            }
        </FormControl >
    );
};

export default MaterialSelect;