import { createStyles, FormControl, FormHelperText, InputLabel, makeStyles, Select, Theme } from "@material-ui/core";
import { FieldProps } from "formik";
import React from 'react';
import HelpButton from "./HelpButton";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        select: {
            fontFamily: "Spartan MB",
            fontSize: "1rem",
            '& .MuiInput-input': {
                paddingBottom: 14,
                paddingTop: 12
            },
            '& .MuiInput-underline:before': {
                borderBottom: '2px solid #e5e5e5',
            },
            marginTop: theme.spacing(2.5)
        },
        labelRoot: {
            color: theme.palette.text.primary,
            fontFamily: "Spartan MB Bold",
            fontSize: "1.25rem",
            transform: "scale(1)",
            marginTop: 0,
        },
        '& formControl': {
            marignTop: '30px',
        },
        helpIcon: {
            position: 'absolute',
            right: '4px',
            zIndex: 1,
            '& svg': {
                fontSize: '1.375rem',
            },
        },
    })
);

interface Props {
    label: string;
    helpTitle?: string;
    helpText?: string | JSX.Element;
    children: JSX.Element | JSX.Element[];
};

const MaterialSelectAndHelp = <P extends object>({
    label,
    helpTitle,
    helpText,
    field,
    form: {
        touched,
        errors,
    },
    ...props
}: FieldProps<P> & Props) => {
    const classes = useStyles();
    const get = (o: any, path: string) => path
        .split('.')
        .reduce((o: any = {}, key: string) => o[key], o);

    const touchedField = get(touched, field.name);
    const errorsField = get(errors, field.name);

    return (
        <FormControl fullWidth margin="normal" className={classes.select}>
            <InputLabel
                htmlFor={field.name}
                shrink={true}
                classes={{
                    root: classes.labelRoot
                }}>{label}
            </InputLabel>

            {helpTitle && helpText &&
                <div data-testid={`${field.name}-help-button`}>
                    <HelpButton helpText={helpText} helpTitle={helpTitle} className={classes.helpIcon} />
                </div>
            }

            <Select
                fullWidth
                className={classes.select}
                error={touched && touchedField && errors && Boolean(errorsField)}
                {...props}
                {...field}
            >
                {(props as any).children}
            </Select>
            {
                touched && touchedField && errors && Boolean(errorsField) &&
                <FormHelperText>{touched && touchedField ? errors && errorsField : ""}</FormHelperText>
            }
        </FormControl >
    );
};

export default MaterialSelectAndHelp;