import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { EligibilityQuestionGroup } from '../Eligibility/EligibilityQuestionsModel';
import Card from './Card';
import YesNoQuestion from './YesNoQuestion';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        eligibilityQuestionsContainer: {
            padding: theme.spacing(1),
            '& ul': {
                listStyle: 'none',
                padding: '0px 0px 0px 8px',
                margin: '0px 0px 0px 0.75em',
                '& li': {
                    textIndent: '-0.75em',
                    lineHeight: '1.125rem',
                    paddingBottom: theme.spacing(1),
                    '&::before': {
                        content: "'\\2022'",
                        fontSize: '40px',
                        lineHeight: '1rem',
                        color: theme.palette.secondary.main,
                        display: 'inline-block',
                        paddingRight: theme.spacing(1),
                        verticalAlign: 'top',
                    },
                    '& p': {
                        display: 'inline',
                        fontSize: '0.825rem',
                    },
                },
            },
        },
        eligibilityQuestionsAnswers: {
            margin: theme.spacing(3, 0, 0),
        },
    }),
);

type Props = {
    title: string;
    questionGroup: EligibilityQuestionGroup;
    handleClick: (answer: boolean) => void;
};

const EligibilityQuestionsCard = ({ title, questionGroup, handleClick }: Props) => {
    const classes = useStyles();

    return (
        <Card title={title}>
            <div className={classes.eligibilityQuestionsContainer}>
                <ul>
                    {questionGroup.questions.map((x, index) => {
                        return (
                            <li key={`${title.replace(' ', '')}-${index}`}>
                                <Typography variant='body1'>{x.Question}</Typography>
                            </li>
                        );
                    })}
                </ul>
                <div className={classes.eligibilityQuestionsAnswers}>
                    <YesNoQuestion
                        questionText=''
                        onClick={handleClick}
                        justifyButtons={'center'}
                        noText='Disagree'
                        initialAnswer={undefined}
                        yesText='Agree'
                    />
                </div>
            </div>
        </Card>
    );
};

export default EligibilityQuestionsCard;
