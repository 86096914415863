const YearsList = [
    { Id: 1, Value: "None" },
    { Id: 2, Value: "Not purchased before" },
    { Id: 3, Value: "1 year" },
    { Id: 4, Value: "2 years" },
    { Id: 5, Value: "3 years" },
    { Id: 6, Value: "4 years" },
    { Id: 7, Value: "5 years" },
    { Id: 8, Value: "More than 5 years" },
];

export default YearsList;