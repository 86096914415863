import { Form, FormikBag, withFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import useDisableContinue from '../../hooks/useDisableContinue';
import ApplicantContactDetails from '../Applicant/ApplicantContactDetails';
import {
    ApplicantContactDetailsModel,
    contactDetailsValidationSchema,
} from '../Applicant/ApplicantDetailsModel';
import BackButton from '../Controls/BackButton';
import ContinueButton from '../Controls/ContinueButton';
import Section from '../Controls/Section';

const CoverContactDetails = () => {
    const { isDisabled } = useDisableContinue();

    return (
        <Form noValidate>
            <Section sectionTitle={'So we can send you a quote'}>
                <ApplicantContactDetails />
            </Section>
            <div style={{ display: 'flex' }}>
                <BackButton testId="backButton">Back</BackButton>
                <ContinueButton testId="nextButton" disabled={isDisabled}>
                    Next
                </ContinueButton>
            </div>
        </Form>
    );
};

interface FormProps {
    SubmitHandler: (values: ApplicantContactDetailsModel) => void;
}

const CoverContactDetailsForm = withFormik<
    ApplicantContactDetailsModel & FormProps,
    ApplicantContactDetailsModel
>({
    mapPropsToValues: (props: ApplicantContactDetailsModel & FormProps) => {
        return {
            EmailAddress: props.EmailAddress,
            TelephoneNumber: props.TelephoneNumber,
        };
    },
    handleSubmit: (
        values,
        {
            props,
        }: FormikBag<ApplicantContactDetailsModel & FormProps, ApplicantContactDetailsModel>,
    ) => {
        props.SubmitHandler(values);
    },
    validationSchema: Yup.object(contactDetailsValidationSchema),
    displayName: 'CoverContactDetails',
    validateOnMount: true,
})(CoverContactDetails);

export default CoverContactDetailsForm;
