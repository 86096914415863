import { createStyles, Grid, makeStyles, Theme, Box } from '@material-ui/core';
import { Field, Form, FormikBag, FormikProps, withFormik } from 'formik';
import React from 'react';
import useDisableContinue from '../../hooks/useDisableContinue';
import useWindowSize from '../../hooks/useWindowSize';
import { AddressDetailsModel } from '../Address/AddressDetailsModel';
import AddressEditModal from '../Address/AddressEditModal';
import AddressFinderModal from '../Address/AddressFinderModal';
import ApplicantDetails from '../Applicant/ApplicantDetails';
import MaterialButton from '../Controls/MaterialButton';
import MaterialTextFieldAndButton from '../Controls/MaterialTextFieldAndButton';
import { AllDetailsModel, getDetailsValidationSchema } from './DetailsModel';
import ApplicantContactDetails from '../Applicant/ApplicantContactDetails';
import Section from '../Controls/Section';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        editAddress: {
            background: 'none',
            fontFamily: 'Spartan MB SemiBold',
            fontSize: '0.8125rem',
            padding: theme.spacing(0.25, 1),
            borderWidth: '2px',
        },
        modalList: {
            margin: '0',
            padding: '0',
            fontFamily: 'Spartan MB',
            fontSize: '17px',
            listStyleType: 'none',
            '& li': {
                padding: theme.spacing(2),
                borderBottom: '2px solid #e5e5e5',
            },
            '& li:last-child': {
                borderBottom: 'none',
            },
        },
    }),
);

interface FormProps {
    showContactQuestionsBeforePremium: boolean;
    SubmitHandler: (values: AllDetailsModel) => void;
    onManualEdit: any;
    onSelectAddress: any;
    onManualAddressSave: any;
}

const DetailsForm = ({
    showContactQuestionsBeforePremium,
    onManualEdit,
    values,
    handleSubmit,
    onSelectAddress,
    onManualAddressSave,
}: FormikProps<AllDetailsModel> & FormProps) => {
    const classes = useStyles();
    const { isDisabled } = useDisableContinue();
    const { isMobile } = useWindowSize();
    const [postcode, setPostcode] = React.useState('');
    const [openFindAddress, setOpenFindAddress] = React.useState(false);
    const [openManualAddress, setOpenManualAddress] = React.useState(false);

    const handleFindAddressClose = () => {
        setOpenFindAddress(false);
    };

    const handleFindAddress = (value: string) => (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        if (!isMobile) {
            event.preventDefault();
            setPostcode(value);
            setOpenFindAddress(true);
        }
    };

    const handleSelectAddress = (address: any) => (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (address) {
            let addressParts = address.split(',').map((a: any) => a.trim());
            //The last address part should be the postcode
            addressParts.pop();

            setOpenFindAddress(false);
            handleSubmit();
            onSelectAddress(values, addressParts);
        } else {
            setOpenFindAddress(false);
        }
    };

    const handleCancelManualAddress = () => {
        setOpenManualAddress(false);
    };

    const handleSaveManualAddress = (value: AddressDetailsModel) => {
        setOpenManualAddress(false);
        handleSubmit();
        onManualAddressSave(value);
    };

    const handleManualEdit = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (isMobile) {
            onManualEdit();
        } else {
            event.preventDefault();
            setOpenManualAddress(true);
        }
    };

    return (
        <>
            <Form noValidate>
                <ApplicantDetails />
                {showContactQuestionsBeforePremium && (
                    <Section sectionTitle="So we can send you a quote">
                        <ApplicantContactDetails />
                    </Section>
                )}
                <Field name="RiskAddress.Postcode">
                    {(props: any) => (
                        <MaterialTextFieldAndButton
                            id="RiskAddress.Postcode"
                            label="Postcode"
                            placeholder="SK7 3AG"
                            onInlineClick={handleFindAddress(props.field.value)}
                            disableInlineButton={isDisabled}
                            inputProps={{
                                maxLength: 8,
                            }}
                            {...props}
                        />
                    )}
                </Field>
                <Box pb={4}>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <MaterialButton
                                testId="enterAddress"
                                onClick={handleManualEdit}
                                disabled={isDisabled}
                                className={classes.editAddress}
                            >
                                Enter your address manually?
                            </MaterialButton>
                        </Grid>
                    </Grid>
                </Box>
                <AddressFinderModal
                    postcode={postcode}
                    open={openFindAddress}
                    handleClose={handleFindAddressClose}
                    handleSelectAddress={handleSelectAddress}
                />
                <AddressEditModal
                    onCancel={handleCancelManualAddress}
                    onSave={handleSaveManualAddress}
                    open={openManualAddress}
                    address={values.RiskAddress}
                />
            </Form>
        </>
    );
};

export default withFormik<AllDetailsModel & FormProps, AllDetailsModel>({
    mapPropsToValues: (props: AllDetailsModel & FormProps) => {
        return {
            Title: props.Title,
            Forenames: props.Forenames,
            Surname: props.Surname,
            DateOfBirth: props.DateOfBirth,
            TelephoneNumber: props.TelephoneNumber,
            EmailAddress: props.EmailAddress,
            RiskAddress: props.RiskAddress,
        };
    },
    handleSubmit: (values, { props }: FormikBag<AllDetailsModel & FormProps, AllDetailsModel>) => {
        props.SubmitHandler(values);
    },
    validationSchema: ({ showContactQuestionsBeforePremium }: FormProps) =>
        getDetailsValidationSchema(showContactQuestionsBeforePremium),
    displayName: 'Details',
})(DetailsForm);
