import { RouteComponentProps } from "@reach/router";
import React from "react";
import { useStateValue } from "../../../../hooks/StateContext";
import OverlayItemList, { SingleItem } from "../../../Controls/OverlayItemList";
import { setNumberOfBedrooms } from "../../../Property/PropertyDetailActions";
import { ListOfNumberOfBedrooms } from "../../../Property/PropertyDetailsModel";
import { refreshQuotes } from "../../../QuoteResults/QuoteResultsActions";

interface Props extends RouteComponentProps { }

const PropertyNumberOfBedroomsOverlay = ({ }: Props) => {
    const { state, dispatch } = useStateValue();

    const handleSelectItem = (selectedItem: SingleItem) => (_: any) => {
        dispatch(refreshQuotes());
        dispatch(setNumberOfBedrooms(selectedItem.Id));
        history.back();
    };

    return (
        <OverlayItemList
            title="Number of bedrooms"
            listItems={ListOfNumberOfBedrooms}
            selectedItem={state.NumberOfBedrooms}
            handleSelectItem={handleSelectItem}
        />
    );
};

export default PropertyNumberOfBedroomsOverlay;