import React, { useState, useEffect } from 'react';
import { useStateValue } from '../../hooks/StateContext';
import useApply from '../../hooks/useApply';
import ApiError from '../ApiError';
import Loading from '../Controls/Loading';
import { CoverTypeOption } from '../CoverType/CoverTypeOptionEnum';
import { getFormattedDate } from '../utils';
import ApplicationSuccess from './ApplicationSuccess';
import { setApplicationComplete } from './ApplicationResultActions';

const ApplicationResultSection = () => {
    const { state, dispatch } = useStateValue();
    const { loading, error } = useApply({
        Apply: {
            ...state.Apply,
            Client2: {
                ...state.Client2!,
            },
        },
        SelectedQuote: state.SelectedQuote,
        AgreeDeclarations: state.AgreeDeclarations,
    });
    const [finishedLoading, setFinishedLoading] = useState(false);

    const coverTypeLabel =
        state.SelectedCoverType == CoverTypeOption.BuildingsAndContentsCover
            ? 'buildings & contents'
            : state.SelectedCoverType == CoverTypeOption.BuildingsOnlyCover
            ? 'buildings'
            : 'contents';

    useEffect(() => {
        if (finishedLoading) {
            dispatch(setApplicationComplete(true));
        }
    }, [finishedLoading]);

    const getFirstPaymentDate = () => {
        const soldDate = new Date();
        let firstPaymentDate = new Date();
        switch (soldDate.getDate()) {
            case 6:
                firstPaymentDate.setDate(soldDate.getDate() + 10);
                break;
            case 0:
                firstPaymentDate.setDate(soldDate.getDate() + 9);
                break;
            default:
                firstPaymentDate.setDate(soldDate.getDate() + 8);
                break;
        }

        return getFormattedDate(firstPaymentDate);
    };

    const getPaymentAmount = () => {
        if (state.Apply.PaidYearly) {
            if (
                state.SelectedQuote.PremiumData &&
                state.SelectedQuote.PremiumData.TotalAnnualPremium
            ) {
                return state.SelectedQuote.PremiumData.TotalAnnualPremium!.toString();
            }
        } else {
            if (
                state.SelectedQuote.PremiumData &&
                state.SelectedQuote.PremiumData.TotalMonthlyPremium
            ) {
                return state.SelectedQuote.PremiumData.TotalMonthlyPremium!.toString();
            }
        }
        return '';
    };

    const loadingStages = [
        'Confirming your details',
        'Purchasing your policy',
        'Policy Purchased!',
    ];
    if (!finishedLoading)
        return (
            <Loading
                stages={loadingStages}
                interval={500}
                isLoading={loading}
                setFinishedLoading={setFinishedLoading}
            />
        );
    return error ? (
        <ApiError />
    ) : (
        <ApplicationSuccess
            CoverType={coverTypeLabel}
            PolicyStartDate={state.Apply.PolicyStartDate}
            IsAnnualPayment={state.Apply.PaidYearly}
            PaymentAmount={getPaymentAmount()}
            FirstPaymentDate={getFirstPaymentDate()}
            PolicyDocumentsByEmail={state.Apply.ReceiveInsuranceDocumentsEmail!}
        />
    );
};

export default ApplicationResultSection;
