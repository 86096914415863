import React from "react";
import ApplicationResultSection from '../ApplicationResult/ApplicationResultSection';

const ApplicationResult = () => {
    return (
        <React.Fragment>
            <ApplicationResultSection />
        </React.Fragment>
    );
};

export default ApplicationResult;