import { createStyles, makeStyles, Theme, Box } from "@material-ui/core";
import React, { useState } from "react";
import { useStateValue } from "../../hooks/StateContext";
import useQuoteAPI from "../../hooks/useQuoteAPI";
import ApiError from "../ApiError";
import Loading from "../Controls/Loading";
import QuoteResultPreview from "../QuoteResults/QuoteResultPreview";
import QuoteSuccess from "../QuoteResults/QuoteSuccess";
import { PremiumListItem } from "../QuoteResults/SelectPremiumModel";
import SectionTitle from "../Controls/SectionTitle";
import ContinueButton from "../Controls/ContinueButton";
import { navigate } from "@reach/router";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        quoteResults: {
            marginTop: theme.spacing(1),
            [theme.breakpoints.only('xs')]: {
                margin: '0 -2rem',
            },
            [theme.breakpoints.only('sm')]: {
                margin: '0 -105px',
            },
            [theme.breakpoints.up('md')]: {
                margin: '0 -82px'
            },
        },
        gridWrap: {
            display: 'contents',
            justifyContent: 'center',
            background: 'linear-gradient(180deg, rgba(239, 240, 241, 1) 0%, rgba(246, 246, 250, 1) 35%, rgba(248, 248, 250, 1) 100%)',
            '&:first-child': {
                background: theme.palette.background.default,
            },
        },
    })
);

const Quote = () => {
    const classes = useStyles();
    const { state } = useStateValue();
    const results = state.QuoteResults;
    const { loading, error } = useQuoteAPI();
    const [finishedLoading, setFinishedLoading] = useState(false);

    const goBack = () => {
        navigate('/direct');
    };

    const loadingStages = ["Processing your information", "Searching our panel of Insurers", "Finding your cheapest quote"];
    if (!finishedLoading) return <Loading stages={loadingStages} interval={1000} isLoading={loading} setFinishedLoading={setFinishedLoading} />
    return (
        <>
            {error && !loading &&
                <ApiError />
            }
            {!loading && results && results.PremiumListItems && results.PremiumListItems.length > 0 &&
                <QuoteSuccess forenames={state.Client1.Forenames} premiumListItem={results.PremiumListItems[0]} quoteReference={results.QuoteReference}>
                    <Box className={classes.gridWrap}>
                        <Box className={classes.quoteResults}>
                            {results && results.PremiumListItems && results.PremiumListItems.map((p: PremiumListItem) => (
                                <QuoteResultPreview key={p.PremiumId} provider={p.Provider} totalAnnualPremium={p.TotalAnnualPremium!} totalMonthlyPremium={p.TotalMonthlyPremium!} />
                            ))}
                        </Box>
                    </Box>
                </QuoteSuccess>
            }
            {
                !loading && results && results.PremiumListItems && results.PremiumListItems.length === 0 &&
                <>
                    <SectionTitle testId="noQuotes" title={`Unfortunately we are unable to provide a quote for your property.`} />
                    <ContinueButton testId="changeDetails" onClick={goBack}>New Quote</ContinueButton>
                </>
            }
        </>
    );
};

export default Quote;