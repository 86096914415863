import { Action } from '../../models/Action';
import { ActionType } from '../../models/ActionType';
import { EligibilityAgreementModel } from './EligibilityQuestionsModel';

export const setAgreeDeclarations = (value: boolean): Action => {
    return {
        type: ActionType.UpdateAgreeDeclarations,
        value,
    };
};

export const setAgreeDeclarationsForSection = (sectionId: number, value: boolean): Action => {
    return {
        type: ActionType.UpdateAgreeDeclarationsGroup,
        value: {
            Id: sectionId,
            Value: value,
        } as EligibilityAgreementModel,
    };
};

export const setEligibilityQuestions = (value: any): Action => {
    return {
        type: ActionType.SetEligibilityQuestions,
        value,
    };
};

export const setShowReferModal = (value: boolean): Action => {
    return {
        type: ActionType.SetShowReferModal,
        value,
    };
};
