import { Action } from "../../models/Action";
import { ActionType } from "../../models/ActionType";

export const setBuildingsAccidentalCover = (value: boolean): Action => {
    return {
        type: ActionType.SetBuildingsAccidentalCover,
        value,
    };
};

export const setContentsAccidentalCover = (value: boolean): Action => {
    return {
        type: ActionType.SetContentsAccidentalCover,
        value,
    };
};