import { RouteComponentProps } from "@reach/router";
import React from "react";
import { useStateValue } from "../../../../hooks/StateContext";
import OverlayItemList, { SingleItem } from "../../../Controls/OverlayItemList";
import { setPersonalPossessionValue } from "../../../Possessions/PossessionsActions";
import { ListOfPersonalPossessionsValues } from "../../../Possessions/PossessionsValuesModel";
import { refreshQuotes } from "../../../QuoteResults/QuoteResultsActions";

interface Props extends RouteComponentProps { }

const PossessionsPersonalOverlay = ({ }: Props) => {
    const { state, dispatch } = useStateValue();

    const handleSelectItem = (selectedItem: SingleItem) => (_: any) => {
        dispatch(refreshQuotes());
        dispatch(setPersonalPossessionValue(selectedItem.Id));
        history.back();
    }

    return (
        <OverlayItemList
            title="Personal possessions"
            listItems={ListOfPersonalPossessionsValues}
            selectedItem={state.ContentsPersonalUnspecifiedValue}
            handleSelectItem={handleSelectItem}
        />
    );
};

export default PossessionsPersonalOverlay;