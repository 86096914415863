import { createStyles, makeStyles, Theme } from "@material-ui/core";
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import WatchIcon from '@material-ui/icons/Watch';
import { navigate } from "@reach/router";
import React, { useState } from "react";
import { useStateValue } from "../../hooks/StateContext";
import useWindowSize from "../../hooks/useWindowSize";
import BackButton from "../Controls/BackButton";
import ContinueButton from "../Controls/ContinueButton";
import { SingleItem } from "../Controls/OverlayItemList";
import PersonalPossessionsSingleItem from "../Controls/PersonalPossessionsSingleItem";
import PossessionsList from "../Controls/PossessionsList";
import SectionTitle from "../Controls/SectionTitle";
import { setEditingSpecificPossession, setPersonalPossessionValue, setRemovingSpecificPossession, setResetIsEditingPossessions, upsertPersonalPossession } from "../Possessions/PossessionsActions";
import PossessionsBicycleModal from "../Possessions/PossessionsBicycleModal";
import PossessionsHighValueItemModal from "../Possessions/PossessionsHighValueItemModal";
import { PossessionsBicycleModelInitial, PossessionsModel, PossessionsModelInitial } from "../Possessions/PossessionsModel";
import PossessionsPersonalBelongingsModal from "../Possessions/PossessionsPersonalBelongingsModal";
import { PossessionsTypeEnum } from "../Possessions/PossessionsTypeEnum";
import { refreshQuotes } from "../QuoteResults/QuoteResultsActions";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            paddingLeft: theme.spacing(2)
        },
    })
);

const Possessions = () => {
    const classes = useStyles();
    const { state, dispatch } = useStateValue();
    const { isMobile } = useWindowSize();
    const [openBicycleModal, setOpenBicycleModal] = useState(false);
    const [editBicycle, setEditBicycle] = useState<PossessionsModel>(PossessionsBicycleModelInitial);
    const [openHighValueItemsModal, setOpenHighValueItemsModal] = useState(false);
    const [editHighValueItem, setEditHighValueItem] = useState<PossessionsModel>(PossessionsModelInitial);
    const [openPersonalBelongingsModal, setOpenPersonalBelongingsModal] = useState(false);

    const handleAddChangePossession = (possession: PossessionsTypeEnum) => (editItem?: PossessionsModel) => {
        if (editItem) {
            dispatch(setEditingSpecificPossession(editItem));
        } else {
            dispatch(setResetIsEditingPossessions());
        }
        if (isMobile) {
            let overlayEndpoint = "";
            switch (possession) {
                case PossessionsTypeEnum.Bicycle:
                    overlayEndpoint = 'bicycle';
                    break;
                case PossessionsTypeEnum.HighValueItem:
                    overlayEndpoint = 'highvalueitem';
                    break;
            }
            navigate(`/direct/possessions/overlay/${overlayEndpoint}`);
        } else {
            switch (possession) {
                case PossessionsTypeEnum.Bicycle:
                    if (editItem) {
                        editItem.IsEditing = true;
                        setEditBicycle(editItem);
                    } else {
                        setEditBicycle(PossessionsBicycleModelInitial);
                    }
                    setOpenBicycleModal(true);
                    break;
                case PossessionsTypeEnum.HighValueItem:
                    if (editItem) {
                        editItem.IsEditing = true;
                        setEditHighValueItem(editItem);
                    } else {
                        setEditHighValueItem(PossessionsModelInitial);
                    }
                    setOpenHighValueItemsModal(true);
                    break;
            }

        }
    };

    const handleAddChangePersonalPossession = () => {
        if (isMobile) {
            navigate('/direct/possessions/overlay/personal');
        } else {
            setOpenPersonalBelongingsModal(true);
        }
    };

    const handleContinue = () => {
        dispatch(refreshQuotes());
        navigate("/direct/voluntaryexcess");
    };

    const handleBicycleModalCancel = () => {
        setOpenBicycleModal(false);
    };

    const handleBicycleModalSave = (item: PossessionsModel) => {
        dispatch(upsertPersonalPossession(item));
        setOpenBicycleModal(false);
        dispatch(refreshQuotes());
    };

    const handleBicycleModalRemove = (item: PossessionsModel) => {
        dispatch(setRemovingSpecificPossession(item));
        setOpenBicycleModal(false);
        dispatch(refreshQuotes());
    };

    const handleHighValueItemModalCancel = () => {
        setOpenHighValueItemsModal(false);
    };

    const handleHighValueItemModalSave = (item: PossessionsModel) => {
        dispatch(upsertPersonalPossession(item));
        setOpenHighValueItemsModal(false);
        dispatch(refreshQuotes());
    };

    const handleHighValueItemModalRemove = (item: PossessionsModel) => {
        dispatch(setRemovingSpecificPossession(item));
        setOpenHighValueItemsModal(false);
        dispatch(refreshQuotes());
    };

    const handlePersonalBelongingsModalCancel = () => {
        setOpenPersonalBelongingsModal(false);
    };

    const handlePersonalBelongingsModalSelect = (selectedItem: SingleItem) => (_: any) => {
        dispatch(setPersonalPossessionValue(selectedItem.Id));
        setOpenPersonalBelongingsModal(false);
        dispatch(refreshQuotes());
    };

    return (
        <React.Fragment>
            <div className={classes.title}>
                <SectionTitle title="Possessions." />
            </div>
            <PossessionsList
                icon={<DirectionsBikeIcon />}
                title="Bicycle cover"
                helpTitle="Bicycle cover"
                helpText="Pedal cycles individually valued over £1,000 need to be specified on your policy for them to be covered."
                noPossessionsText="Would you like cover for any bicycles worth more than £1,000?"
                possessions={state.ContentsPersonalPossessions.filter(x => x.ItemType === "PedalCyclesOver1000")}
                addChangeHandle={handleAddChangePossession(PossessionsTypeEnum.Bicycle)}
                testId="bicycleCover"
            />
            <PossessionsList
                icon={<WatchIcon />}
                title="High value items"
                helpTitle="High value items"
                helpText={<>
                    <p>Personal Possessions and high risks items individually valued at £2,500 or higher need to be specified on your policy for them to be covered.</p>
                    <p>Personal Possessions are those items you wear or carry about your person when outside .</p>
                    <p>High-risk items includes things like jewellery and articles made from gold, silver and other precious metals, clocks and watches, photographic equipment, musical instruments, works of art, guns, collections of stamps, coins or medals.</p>
                    <p>Carpets, furniture and curtains are not classed as high-risk property.</p>
                </>}
                noPossessionsText="Do you have any items which are individually valued over £2,500?"
                possessions={state.ContentsPersonalPossessions.filter(x => x.ItemType !== "PedalCyclesOver1000")}
                addChangeHandle={handleAddChangePossession(PossessionsTypeEnum.HighValueItem)}
                testId="highValueItems"
            />
            <PersonalPossessionsSingleItem
                icon={<SmartphoneIcon />}
                title="Personal belongings"
                helpTitle="Personal belongings"
                helpText={<>
                    <p>Items you take outside your home worth less than £2,500 individually are not covered automatically for loss, theft or damage by contents insurance.</p>
                    <p>If you want to insure these items, for example jewellery or mobile phones, you’ll need to add personal belongings cover.</p>
                    <p>There is a limit per item of £2,500 and individual limits also apply for mobile phones (£500), personal money (£750) and credit cards (£1,000).</p>
                </>}
                noPossessionsText="Would you like cover for any other items that you use or wear away from home that are worth less than £2,500?"
                personalPossessionsValue={state.ContentsPersonalUnspecifiedValue}
                addChangeHandle={handleAddChangePersonalPossession}
            />
            <div style={{ display: 'flex' }}>
                <BackButton testId="backButton">Back</BackButton>
                <ContinueButton testId="possessionsNext"
                    onClick={handleContinue}
                    disabled={state.RefreshingQuote || state.QuoteError || Object.entries(state.SelectedQuote.PremiumData).length === 0}
                >
                    Next
                </ContinueButton>
            </div>
            <PossessionsBicycleModal
                open={openBicycleModal}
                onCancel={handleBicycleModalCancel}
                item={editBicycle}
                onSave={handleBicycleModalSave}
                onRemove={handleBicycleModalRemove}
            />
            <PossessionsHighValueItemModal
                open={openHighValueItemsModal}
                onCancel={handleHighValueItemModalCancel}
                item={editHighValueItem}
                onSave={handleHighValueItemModalSave}
                onRemove={handleHighValueItemModalRemove}
            />
            <PossessionsPersonalBelongingsModal
                open={openPersonalBelongingsModal}
                handleSelectItem={handlePersonalBelongingsModalSelect}
                onCancel={handlePersonalBelongingsModalCancel}
                item={state.ContentsPersonalUnspecifiedValue}

            />
        </React.Fragment>
    );
};

export default Possessions;