import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import HelpButton from "./HelpButton";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        possessionsListSpacing: {
            padding: theme.spacing(2, 2, 1, 2),
            display: 'flex',
        },
        headerSeparator: {
            borderBottom: '1px solid #e5e5e5',
        },
        headerIcon: {
            color: '#b1b1b1',
            padding: theme.spacing(0, 2, 0, 0),
        },
        possessionsListHeader: {
            fontFamily: "Spartan MB Bold",
            fontSize: '1.25rem',
            lineHeight: '1.875rem',
            color: theme.palette.text.secondary,
            flexGrow: 1,
        },
        helpIcon: {
            display: 'inline-block',
            paddingTop: '2px',
            '& svg': {
                height: '1.375rem',
                width: '1.375rem',
            }
        },
    })
);

type Props = {
    title: string;
    icon?: JSX.Element;
    testId?: string;
    helpText: string | JSX.Element;
    helpTitle: string;
    addSeparator?: boolean;
};

const CardHeader = ({ title, icon, helpText, helpTitle, addSeparator, testId }: Props) => {
    const classes = useStyles();
    let classNames = classes.possessionsListSpacing;

    if (addSeparator)
        classNames += ` ${classes.headerSeparator}`;

    return (
        <div className={classNames}>
            {icon && <div className={classes.headerIcon} data-testid="possessions-icon">{icon}</div>}
            <Typography variant="body1" className={classes.possessionsListHeader}>{title}</Typography>
            {helpTitle && helpText &&
                <HelpButton data-testid={testId || 'helpButton'} helpText={helpText} helpTitle={helpTitle} className={classes.helpIcon} />
            }
        </div>
    );
};

export default CardHeader;