import axios from 'axios';
import { useEffect, useState } from "react";
import { getDateFromString } from '../components/utils';
import { RetrieveQuoteRequest } from '../components/RetrieveQuote/RetrieveQuoteRequest';
import { useStateValue } from './StateContext';
import { RetrieveQuoteResponse } from '../components/RetrieveQuote/RetrieveQuoteResponse';
import { setRetrievedQuote } from '../components/RetrieveQuote/RetrieveQuoteAction';

export type Props = {
    RetrieveQuoteDetails: RetrieveQuoteRequest;
};

const useRetrieveQuote = ({ RetrieveQuoteDetails }: Props) => {
    const { dispatch } = useStateValue();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    useEffect(() => {
        async function getQuote() {
            try {
                setError(false);
                setLoading(true);
                const url = `${process.env.FUNCTION_BASE_URL_RETRIEVE}api/RetrieveQuote`;

                const response = await axios.post(url, {
                    Code: RetrieveQuoteDetails.Code,
                    Surname: RetrieveQuoteDetails.Surname,
                    DateOfBirth: getDateFromString(RetrieveQuoteDetails.DateOfBirth),
                    Postcode: RetrieveQuoteDetails.Postcode,
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                dispatch(setRetrievedQuote(response.data as RetrieveQuoteResponse));
            }
            catch (error) {
                if (error.message != "Operation canceled by the user.") {
                    setLoading(false);
                }
                setError(true);
            } finally {
                setLoading(false);
            }
        };

        if (RetrieveQuoteDetails.Code && RetrieveQuoteDetails.Surname && RetrieveQuoteDetails.DateOfBirth && RetrieveQuoteDetails.Postcode) {
            getQuote();
        }

        return function cleanup() {
            source.cancel('Operation canceled by the user.');
            setLoading(false);
        };
    }, [RetrieveQuoteDetails]);

    return { loading, error };
};

export default useRetrieveQuote;