import { Box, createStyles, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        radioButtonBorderUnselected: {
            transition: theme.transitions.create(
                `border-color`,
                { duration: 200 }
            ),
            borderColor: '#e5e5e5',
        },
        radioButtonBorderSelected: {
            transition: theme.transitions.create(
                `border-color`,
                { duration: 200 }
            ),
            borderColor: theme.palette.secondary.main,
        },
        radioButtonBorderBase: {
            [theme.breakpoints.down(374)]: {
                paddingTop: '8px',
            },
            paddingBottom: '8px',
            backgroundColor: theme.palette.background.paper,
            borderRadius: 4,
            alignContent: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            minWidth: '60px',
            maxWidth: '120px',
            width: 'auto',
            minHeight: '75px',
            height: 'auto',
            display: 'block',
            cursor: 'pointer',
            margin: '0 5px',
        },
        styledRadio: {
            display: 'block',
            cursor: 'pointer',
            width: '100%',
        },
        radioUnchecked: {
            color: '#b1b1b1',
        },
        radioChecked: {
            color: theme.palette.secondary.main,
        },
    })
);

type Props = {
    currentValue?: string;
    radioValue: string;
    children: JSX.Element;
    onClick?: (newValue: any) => void;
};

const CustomRadio = ({ radioValue, currentValue, children, onClick }: Props) => {
    const defaultProps = {
        m: 0,
        border: 2,
    };

    const classes = useStyles();

    return (
        <Box
            {...defaultProps}
            className={`${classes.radioButtonBorderBase}
                ${radioValue === currentValue ?
                    classes.radioButtonBorderSelected :
                    classes.radioButtonBorderUnselected}`
            }
            boxShadow={radioValue === currentValue ? 5 : 0}
            onClick={() => onClick!(radioValue)}>
            {children}
            {
                currentValue === radioValue ?
                    <RadioButtonCheckedIcon fontSize='small'
                        className={classes.radioChecked}
                    /> :
                    <RadioButtonUncheckedIcon fontSize='small'
                        className={classes.radioUnchecked} />
            }
        </Box>
    );
};

export default CustomRadio;