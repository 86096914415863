export interface PossessionsModel {
    ItemType: string;
    ItemDescription: string;
    ItemValue: number;
    IncludeAtHome: boolean;
    IncludeAwayFromHome: boolean;
    IsEditing: boolean;
};

export const PossessionsModelInitial: PossessionsModel = {
    ItemType: '',
    ItemDescription: '',
    ItemValue: 0,
    IncludeAtHome: true,
    IncludeAwayFromHome: false,
    IsEditing: false,
};

export const PossessionsBicycleModelInitial: PossessionsModel = {
    ItemType: 'PedalCyclesOver1000',
    ItemDescription: '',
    ItemValue: 0,
    IncludeAtHome: false,
    IncludeAwayFromHome: true,
    IsEditing: false,
};