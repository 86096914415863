import { createStyles, LinearProgress, makeStyles, Theme } from "@material-ui/core";
import React, { Fragment } from 'react';
import useAddressAPI from "../../hooks/useAddressAPI";
import MaterialModal from "../Controls/MaterialModal";
import ModalContent from "../Controls/ModalContent";
import ModalHeader from "../Controls/ModalHeader";
import ModalTitle from "../Controls/ModalTitle";
import { formatPostcode } from "../utils";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        selectAddress: {
            '&:active, &:focus': {
                "outline": "none",
            },
            fontFamily: "Spartan MB",
            fontSize: "17px",
            cursor: "pointer",
            background: "none",
            border: "none",
            margin: theme.spacing(1, 0),
            padding: "0",
        },
        modalList: {
            margin: "0",
            padding: "0",
            fontFamily: "Spartan MB",
            fontSize: "17px",
            listStyleType: "none",
            '& li': {
                padding: theme.spacing(0.5, 2),
                borderBottom: "2px solid #e5e5e5"
            },
            '& li:last-child': {
                borderBottom: "none"
            },
        },
    })
);

interface AddressFinderProps {
    postcode: string;
    open: boolean;
    handleClose?: any;
    handleSelectAddress?: any;
};

const AddressFinderModal = ({ postcode, open, handleClose, handleSelectAddress }: AddressFinderProps) => {
    const { results, loading } = useAddressAPI(postcode);
    const classes = useStyles();

    return (
        <MaterialModal
            open={open}
            onClose={handleClose}
        >
            <ModalHeader onClick={handleClose} showClose={true}>
                <ModalTitle id="simple-modal-title">
                    <Fragment>Properties for {formatPostcode(postcode)}</Fragment>
                </ModalTitle>
            </ModalHeader>
            <ModalContent>
                {loading ?
                    <LinearProgress />
                    : results &&
                    <div style={{ height: "60vh", overflowY: "scroll", overflowX: "hidden" }}>
                        <ul id="findAddressProperty" className={classes.modalList}>
                            {results.Items.map((a: any) => (
                                <li key={a.Id}>
                                    <button onClick={handleSelectAddress(`${a.Text}, ${a.Description}`)} type="button" className={classes.selectAddress}>{a.Text}</button>
                                </li>
                            ))}
                        </ul>
                    </div>
                }
            </ModalContent>
        </MaterialModal>
    );
};

export default AddressFinderModal;