import { Action } from "../../models/Action";
import { ActionType } from "../../models/ActionType";

export const setHomeEmergencyCover = (value: boolean): Action => {
    return {
        type: ActionType.SetHomeEmergencyCover,
        value,
    };
};

export const setLegalProtectionCover = (value: boolean): Action => {
    return {
        type: ActionType.SetLegalProtectionCover,
        value,
    };
}; 