import React from "react";
import Section from "../Controls/Section";
import SectionTitle from "../Controls/SectionTitle";
import YesNoQuestion from "../Controls/YesNoQuestion";
import ContinueButton from "../Controls/ContinueButton";
import { navigate } from "@reach/router";
import { setBuildingsAccidentalCover, setContentsAccidentalCover } from "../AccidentalCover/AccidentalCoverActions";
import { useStateValue } from "../../hooks/StateContext";
import { refreshQuotes } from "../QuoteResults/QuoteResultsActions";
import BackButton from "../Controls/BackButton";

const AccidentalDamage = () => {
    const { state, dispatch } = useStateValue();

    const handleContinue = () => {
        dispatch(refreshQuotes());
        if (state.IncludeContents) {
            navigate("/direct/possessions");
        }
        else {
            navigate("/direct/voluntaryexcess");
        }
    };

    const handleBuildingsAccidentalCover = (value: boolean) => {
        dispatch(setBuildingsAccidentalCover(value));
    };

    const handleContentsAccidentalCover = (value: boolean) => {
        dispatch(setContentsAccidentalCover(value));
    };

    return (
        <React.Fragment>
            <SectionTitle title="Accidental Damage." />
            {state.IncludeBuildings &&
                <Section
                    sectionTitle={"Buildings"}
                    helpText={<>
                        <p>Included as standard within buildings insurance is cover for accidental breakage to fixed glass, ceramic hobs in built in cookers and sanitaryware in your home.</p>
                        <p>You can choose to add Buildings Accidental Damage to your policy to cover other items against accidental loss or damage. This covers unexpected and unintended damage such as, for example, putting your foot through a loft ceiling or drilling through a pipe.</p>
                    </>}
                    helpTitle={"Buildings Accidental Damage"}
                    testId="buildingsAD">
                    <YesNoQuestion
                        questionText={"Would you like to include accidental damage on your buildings insurance?"}
                        testId="buildingsADQuestion"
                        onClick={(value) => handleBuildingsAccidentalCover(value)}
                        initialAnswer={state.BuildingsAccidentalCover} />
                </Section>
            }
            {state.IncludeContents &&
                <Section
                    sectionTitle={"Contents"}
                    helpTitle={"Contents Accidental Damage"}
                    helpText={<>
                        <p>Included as standard within contents insurance is cover for accidental breakage to TV’s , games consoles, desktop PC’s and a number of other electronic devices within your home (but not phones or portable computing devices) . Cover is also provided for fixed glass in furniture and ceramic hobs in free-standing cookers.</p>
                        <p>You can choose to add Contents  Accidental Damage to your policy to cover other items against accidental loss or damage. This covers unexpected and unintended damage such as, for example, spilling wine on a carpet or accidentally knocking a TV off its stand.</p>
                    </>}
                    testId="contentsAD">
                    <YesNoQuestion
                        questionText={"Would you like to include accidental damage on your contents insurance?"}
                        testId="contentsADQuestion"
                        onClick={(value) => handleContentsAccidentalCover(value)}
                        initialAnswer={state.ContentsAccidentalCover} />
                </Section>
            }
            <div style={{ display: 'flex' }}>
                <BackButton testId="backButton">Back</BackButton>
                <ContinueButton testId="adNextButton"
                    disabled={state.RefreshingQuote || state.QuoteError || Object.entries(state.SelectedQuote.PremiumData).length === 0}
                    onClick={handleContinue}
                >
                    Next
                </ContinueButton>
            </div>
        </React.Fragment>
    );
};

export default AccidentalDamage;