import { useTheme } from "@material-ui/core";
import axios from "axios";
import { useEffect, useState } from "react";
import { PossessionsModel } from "../components/Possessions/PossessionsModel";
import { Include, ListOfHighValueItems } from "../components/Possessions/PossessionsValuesModel";
import { setCachedResult, setQuoteResults, setShowNoQuotesModal } from "../components/QuoteResults/QuoteResultsActions";
import { QuoteResultModel } from "../components/QuoteResults/SelectPremiumModel";
import { RetrieveQuoteStatusEnum } from "../components/RetrieveQuote/RetrieveQuoteStatusEnum";
import { getDateFromString } from "../components/utils";
import { ApplicationState } from "../models/ApplicationState";
import { useStateValue } from "./StateContext";

const getQuoteURL = `${process.env.FUNCTION_BASE_URL}api/GetQuote`;

const buildPostData = (state: ApplicationState, contentsSpecifiedItems: any[], siteConfiguration: any) => {
    const postData = {
        Client1: {
            Title: state.Client1.Title,
            Forenames: state.Client1.Forenames,
            Surname: state.Client1.Surname,
            DateOfBirth: getDateFromString(state.Client1.DateOfBirth),
            TelephoneNumber: state.Client1.TelephoneNumber,
            EmailAddress: state.Client1.EmailAddress,
        },
        RiskAddress: {
            AddressLine1: state.RiskAddress.AddressLine1,
            AddressLine2: state.RiskAddress.AddressLine2,
            AddressLine3: state.RiskAddress.AddressLine3,
            AddressLine4: state.RiskAddress.AddressLine4,
            Postcode: state.RiskAddress.Postcode,
        },
        PropertyDetailsValidated: true,
        PropertyType: state.PropertyTypeId,
        NumberOfBedrooms: state.NumberOfBedrooms,
        YearBuiltRange: state.YearBuiltRange,

        IncludeBuildings: siteConfiguration.coverOptions.enableBuildingsCover && state.IncludeBuildings,
        IncludeBuildingsAccidentalCover: state.BuildingsAccidentalCover,
        BuildingsNoClaimsDiscountId: state.BuildingsNoClaimsDiscountId,
        BuildingsExcessId: state.BuildingsExcessId,

        IncludeContents: siteConfiguration.coverOptions.enableContentsCover && state.IncludeContents,
        IncludeContentsAccidentalCover: state.ContentsAccidentalCover,

        IncludeContentsPersonalPossessions: (contentsSpecifiedItems.length !== 0 && state.ContentsPersonalUnspecifiedValue != '') ? true : false,
        ContentsSpecifiedItems: contentsSpecifiedItems,

        ContentsPersonalUnspecifiedValue: state.ContentsPersonalUnspecifiedValue,

        ContentsNoClaimsDiscountId: state.ContentsNoClaimsDiscountId,
        ContentsExcessId: state.ContentsExcessId,

        IncludeHomeEmergencyCover: state.HomeEmergencyCover,
        IncludeFamilyLegalProtection: state.LegalProtectionCover,
    };

    return { postData };
};

const mapPersonalPossessions = (possessions: PossessionsModel[]) => {
    const contentsSpecifiedItems = possessions.map((item: PossessionsModel) => {
        const filteredList = ListOfHighValueItems.filter(i => i.Id == item.ItemType);
        if (filteredList.length === 1) {
            const highValueItem = filteredList[0];
            // Only At Home
            if (highValueItem.AllowedAtHome == Include.Yes && highValueItem.AllowedAwayFromHome === Include.No) {
                return {
                    ItemType: item.ItemType,
                    ItemValue: item.ItemValue,
                    ItemDescription: item.ItemDescription,
                    IncludeAtHome: true,
                    IncludeAwayFromHome: false,
                }
            }
            // Only Away From Home
            else if (highValueItem.AllowedAtHome == Include.No && highValueItem.AllowedAwayFromHome === Include.Yes) {
                return {
                    ItemType: item.ItemType,
                    ItemValue: item.ItemValue,
                    ItemDescription: item.ItemDescription,
                    IncludeAtHome: false, // we have to set this to false for the API
                    IncludeAwayFromHome: true,
                }
            }
            // A choice of away from home
            else if (highValueItem.AllowedAwayFromHome === Include.Optional) {
                return {
                    ItemType: item.ItemType,
                    ItemValue: item.ItemValue,
                    ItemDescription: item.ItemDescription,
                    IncludeAtHome: !item.IncludeAwayFromHome,  //  Always opposite of IncludeAwayFromHome
                    IncludeAwayFromHome: item.IncludeAwayFromHome
                }
            }
        } else if (item.ItemType === "PedalCyclesOver1000") {
            return {
                ItemType: "PedalCyclesOver1000",
                ItemValue: item.ItemValue,
                ItemDescription: item.ItemDescription,
                IncludeAtHome: false,
                IncludeAwayFromHome: true
            }
        }
    });

    return contentsSpecifiedItems;
};

const useQuoteAPI = () => {
    const { state, dispatch } = useStateValue();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const contentsSpecifiedItems = mapPersonalPossessions(state.ContentsPersonalPossessions);

    const timeout = (milliseconds: number) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    };

    const theme = useTheme();

    useEffect(() => {
        let cancel: any;
        async function refreshQuote() {
            try {
                setLoading(true);

                const { postData } = buildPostData(state, contentsSpecifiedItems, theme.siteConfiguration);
                const stringKey = btoa(JSON.stringify(postData))
                const found = state.QuoteHistory.filter((q: any) => q.key == stringKey);

                if (found.length === 1) {
                    dispatch(setQuoteResults(found[0].value as QuoteResultModel));
                    await timeout(300);
                } else {
                    const response = await axios.post(getQuoteURL, postData, {
                        cancelToken: new axios.CancelToken((c) => {
                            cancel = c;
                        })
                    });

                    if (response.status == 200) {
                        dispatch(setQuoteResults(response.data as QuoteResultModel));
                        dispatch(setCachedResult({ key: stringKey, value: response.data }));
                    }
                }

                setLoading(false);
                setError(false);

            } catch (error) {
                if (!axios.isCancel(error)) {
                    dispatch(setShowNoQuotesModal(true));
                    setLoading(false);
                    setError(true);
                }
            }
        }

        if (state.QuoteStatus !== RetrieveQuoteStatusEnum.Illustration && state.QuoteStatus !== RetrieveQuoteStatusEnum.ApplicationCompleted) {
            refreshQuote();
        }

        return function cleanup() {
            if (cancel) {
                cancel();
            }
        };
    }, [state.RefreshQuotes]);

    return { loading, error };
};

export default useQuoteAPI;