import axios from 'axios';
import { useEffect, useState } from "react";
import { SelectPremiumModel } from "../components/QuoteResults/SelectPremiumModel";
import { useStateValue } from './StateContext';
import { setIllustrationComplete } from '../components/PolicyInformation/PolicyInformationActions';

export type IllustrationProps = {
    SelectedQuote: SelectPremiumModel;
    AgreeDeclarations?: boolean;
};

const useIllustration = ({ SelectedQuote, AgreeDeclarations }: IllustrationProps) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const { state, dispatch } = useStateValue();
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    useEffect(() => {
        async function postData() {
            try {
                setLoading(true);
                const url = `${process.env.FUNCTION_BASE_URL}api/illustration`;

                const response = await axios.post(url, {
                    SelectedQuote,
                    AgreeDeclarations,
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (response.status == 200) {
                    dispatch(setIllustrationComplete());
                }

                return await response;
            }
            catch (error) {
                if (error.message != "Operation canceled by the user.") {
                    setLoading(false);
                }
                setError(true);
            } finally {
                setLoading(false);
            }
        };

        if (!state.IsIllustrationComplete) {
            postData();
        }

        return function cleanup() {
            source.cancel('Operation canceled by the user.');
            setLoading(false);
        };
    }, []);

    return { loading, error };
};

export default useIllustration;