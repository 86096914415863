import * as Yup from "yup";

const addressLineRegex = new RegExp(/^[-'\w\s,()/.&]*$/, 'i');
export const postcodeRegex = new RegExp(/^[A-Z]{1,2}[0-9R][0-9A-Z]?\s?[0-9][ABD-HJLNP-UW-Z]{2}$/, 'i');

export const AddressDetailsValidationSchema = {
    AddressLine1: Yup.string().required("Address Line 1 is required").matches(addressLineRegex, "Address Line 1 should only contain alphanumeric characters"),
    AddressLine2: Yup.string().matches(addressLineRegex, "Address Line 2 should only contain alphanumeric characters"),
    AddressLine3: Yup.string().matches(addressLineRegex, "Address Line 3 should only contain alphanumeric characters"),
    AddressLine4: Yup.string().matches(addressLineRegex, "Address Line 4 should only contain alphanumeric characters"),
    Postcode: Yup.string().required("Postcode is required").matches(postcodeRegex, "Enter a valid postcode"),
};

export const RiskAddressDetailsValidationSchema = Yup.object({
    RiskAddress: Yup.object({ ...AddressDetailsValidationSchema }),
});

export const AllAddressDetailsValidationSchema = Yup.object({
    RiskAddress: Yup.object({ ...AddressDetailsValidationSchema }),
    CorrespondenceAddress: Yup.object({ ...AddressDetailsValidationSchema }),
});

export const PostcodeValidationSchema = {
    Postcode: Yup.string().required("Postcode is required").matches(postcodeRegex, "Enter a valid postcode"),
};