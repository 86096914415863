import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { navigate } from "@reach/router";
import React from "react";
import { useStateValue } from "../../hooks/StateContext";
import SectionTitle from "../Controls/SectionTitle";
import { setPaymentDetails } from "../Payment/PaymentActions";
import { PaymentDetailsModel } from "../Payment/PaymentDetailsModel";
import PaymentForm from "../Payment/PaymentForm";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        payQuestion: {
            padding: theme.spacing(1, 0),
            fontSize: '1rem',
            fontFamily: 'Spartan MB SemiBold',
            color: theme.palette.text.primary,
        },
    }),
);

const Apply = () => {
    const classes = useStyles();
    const { state, dispatch } = useStateValue();

    const handleSubmit = (values: PaymentDetailsModel) => {
        dispatch(
            setPaymentDetails(values)
        );
        navigate('/direct/applicationresult');
    };

    return (
        <React.Fragment>
            <SectionTitle title={`Payment.`} />
            <Typography variant="body1" className={classes.payQuestion}>Payment for your policy will be collected via Direct Debit. <br />Would you like to make a one off annually payment or spread your payments over 12 months?</Typography>
            <PaymentForm
                MonthlyPremium={state.SelectedQuote.PremiumData.TotalMonthlyPremium!}
                AnnualPremium={state.SelectedQuote.PremiumData.TotalAnnualPremium!}
                InitialValues={state.Apply!}
                SubmitHandler={handleSubmit} />
        </React.Fragment>
    );
};

export default Apply;