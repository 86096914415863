import * as Yup from "yup";
import { BuildingsAndContentsPropertyType, PropertyType, YearBuiltRange } from "./PropertyDetailsEnum";

export interface PropertyDetailsModel {
    NumberOfBedrooms: string;
    PropertyTypeId: string;
    YearBuiltRange: string;
};

export const PropertyDetailsInitial: PropertyDetailsModel = {
    NumberOfBedrooms: '',
    PropertyTypeId: '',
    YearBuiltRange: '',
};

export const PropertyDetailsValidation = Yup.object({
    NumberOfBedrooms: Yup.string().required("Number of bedrooms is required"),
    PropertyTypeId: Yup.string().required("Property type is required"),
    YearBuiltRange: Yup.string().required("Year built range is required"),
});

export const ListOfNumberOfBedrooms = [
    { Id: "1", Value: "1 Bedroom" },
    { Id: "2", Value: "2 Bedrooms" },
    { Id: "3", Value: "3 Bedrooms" },
    { Id: "4", Value: "4 Bedrooms" },
    { Id: "5", Value: "5 Bedrooms" },
    { Id: "6", Value: "6 Bedrooms" },
];

export const ListOfTypeOfProperty = [
    { Id: PropertyType.TerracedHouse.toString(), Value: BuildingsAndContentsPropertyType.TerracedHouse },
    { Id: PropertyType.DetachedHouse.toString(), Value: BuildingsAndContentsPropertyType.DetachedHouse },
    { Id: PropertyType.SemiDetachedHouse.toString(), Value: BuildingsAndContentsPropertyType.SemiDetachedHouse },
    { Id: PropertyType.TerracedBungalow.toString(), Value: BuildingsAndContentsPropertyType.TerracedBungalow },
    { Id: PropertyType.DetachedBungalow.toString(), Value: BuildingsAndContentsPropertyType.DetachedBungalow },
    { Id: PropertyType.SemiDetachedBungalow.toString(), Value: BuildingsAndContentsPropertyType.SemiDetachedBungalow },
    { Id: PropertyType.PurposeBuiltFlat.toString(), Value: BuildingsAndContentsPropertyType.PurposeBuiltFlat },
    { Id: PropertyType.CoachHouse.toString(), Value: BuildingsAndContentsPropertyType.CoachHouse }
];

export const ListOfYearOfConstruction = [
    { Id: "Pre1750", Value: YearBuiltRange.Pre1750 },
    { Id: "_1750to1869", Value: YearBuiltRange._1750to1869 },
    { Id: "_1870to1899", Value: YearBuiltRange._1870to1899 },
    { Id: "_1900to1919", Value: YearBuiltRange._1900to1919 },
    { Id: "_1920to1945", Value: YearBuiltRange._1920to1945 },
    { Id: "_1946to1959", Value: YearBuiltRange._1946to1959 },
    { Id: "_1960to1979", Value: YearBuiltRange._1960to1979 },
    { Id: "_1980to1989", Value: YearBuiltRange._1980to1989 },
    { Id: "_1990to1999", Value: YearBuiltRange._1990to1999 },
    { Id: "_2000to2009", Value: YearBuiltRange._2000to2009 },
    { Id: "_2010to2019", Value: YearBuiltRange._2010to2019 },
    { Id: "_2020onwards", Value: YearBuiltRange._2020onwards }
];