import { createStyles, InputAdornment, makeStyles, TextField, Theme, Box } from "@material-ui/core";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { FieldProps } from "formik";
import React from 'react';
import { useStyles } from "./Styles/MaterialTextFieldUseStyles";
import HelpButton from "./HelpButton";

const useLocalStyles = makeStyles((theme: Theme) =>
    createStyles({
        arrow: {
            fontSize: '1.5rem',
        },
        box: {
            position: 'relative',
        },
        helpIcon: {
            top: theme.spacing(2),
            position: 'absolute',
            right: '2px',
            zIndex: 1,
            '& svg': {
                fontSize: '1.375rem',
            },
        },
    })
);

type Props = {
    label: string;
    value: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    boldLabel?: boolean;
    id?: string;
    testId?: string;
    placeholder?: string;
    helpTitle?: string;
    helpText?: string | JSX.Element;
    reducedMargin?: boolean;
};

const SelectOverlayFormik = <P extends object>({
    label,
    value,
    onClick,
    boldLabel,
    id,
    testId,
    placeholder,
    helpTitle,
    helpText,
    reducedMargin,
    field,
    form: {
        touched,
        errors,
    },
    ...props
}: FieldProps<P> & Props) => {
    const classes = useStyles({ boldLabel: !!boldLabel, reducedMargin: !!reducedMargin })();
    const localClasses = useLocalStyles();
    const get = (o: any, path: string) => path
        .split('.')
        .reduce((o: any = {}, key: string) => o[key], o);

    const touchedField = get(touched, field.name);
    const errorsField = get(errors, field.name);

    return (
        <>
            <Box className={localClasses.box} >
                {helpTitle && helpText &&
                    <div data-testid="modal-help-button">
                        <HelpButton helpText={helpText} helpTitle={helpTitle} className={localClasses.helpIcon} />
                    </div>
                }
                <TextField
                    id={id || "select-overlay"}
                    data-testid={testId || "helpButton"}
                    className={classes.textField}
                    fullWidth
                    label={label}
                    margin="normal"
                    placeholder={placeholder}
                    error={touched && touchedField && errors && Boolean(errorsField)}
                    helperText={touched && touchedField ? errors && errorsField : ""}
                    value={value}
                    InputProps={{
                        readOnly: true,
                        onClick: onClick as any,
                        endAdornment:
                            <InputAdornment position="end" id="high-value-item-list">
                                <NavigateNextIcon className={localClasses.arrow} />
                            </InputAdornment>
                    }}
                    InputLabelProps={{
                        shrink: true,
                        classes: {
                            root: classes.labelRoot
                        }
                    }}
                    {...field}
                    {...props}
                >

                </TextField>
            </Box>
        </>
    );
};

export default SelectOverlayFormik;