import React from 'react';
import { ApplicantDetailsModel } from '../Applicant/ApplicantDetailsModel';
import MaterialModal from "../Controls/MaterialModal";
import ModalContent from "../Controls/ModalContent";
import ModalHeader from "../Controls/ModalHeader";
import ModalTitle from "../Controls/ModalTitle";
import PolicyHolderForm from "./PolicyHolderForm";

interface Props {
    open: boolean;
    onCancel: () => void;
    onSave: (values: ApplicantDetailsModel) => void;
    onRemoveApplicant: () => void;
};

const PolicyHolderModal = ({ open, onCancel, onSave, onRemoveApplicant }: Props) => {
    return (
        <MaterialModal
            open={open}
            onClose={onCancel}
        >
            <>
                <ModalHeader onClick={onCancel}>
                    <ModalTitle id="policy-holder-modal">
                        <>Joint policy holder</>
                    </ModalTitle>
                </ModalHeader>
                <ModalContent>
                    <PolicyHolderForm
                        onSave={onSave}
                        onCancel={onCancel}
                        onRemoveApplicant={onRemoveApplicant}
                    />
                </ModalContent>
            </>
        </MaterialModal>
    );
};

export default PolicyHolderModal;