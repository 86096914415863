import axios from 'axios';
import { useEffect, useState } from "react";

export type Props = {
    Code: string
};

const useQuoteReferenceDecryptor = ({ Code }: Props) => {
    const [results, setResults] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    useEffect(() => {
        async function getQuoteReference() {
            try {
                setLoading(true);
                const url = `${process.env.FUNCTION_BASE_URL_RETRIEVE}api/DecryptCode?code=${Code}`;

                const response = await axios.get(url);

                setResults(response.data);
            }
            catch (error) {
                if (error.message != "Operation canceled by the user.") {
                    setLoading(false);
                }
                setError(true);
            } finally {
                setLoading(false);
            }
        };

        getQuoteReference();

        return function cleanup() {
            source.cancel('Operation canceled by the user.');
            setLoading(false);
        };
    }, []);

    return { results, loading, error };
};

export default useQuoteReferenceDecryptor;