import { Action } from "../../models/Action";
import { ActionType } from "../../models/ActionType";
import { PropertyDetailsModel } from "./PropertyDetailsModel";

export const setAllPropertyDetails = (value: PropertyDetailsModel): Action => {
    return {
        type: ActionType.UpdateFullPropertyDetails,
        value,
    };
};

export const setNumberOfBedrooms = (value: string): Action => {
    return {
        type: ActionType.UpdateNumberOfBedrooms,
        value,
    };
};

export const setTypeOfProperty = (value: string): Action => {
    return {
        type: ActionType.UpdateTypeOfProperty,
        value,
    };
};

export const setYearOfConstruction = (value: string): Action => {
    return {
        type: ActionType.UpdateYearOfConstruction,
        value,
    };
};

export const setRiskAddressConfirmed = (value: boolean): Action => {
    return {
        type: ActionType.SetRiskAddressConfirmed,
        value,
    };
};