import axios from "axios";
import { useEffect, useState } from "react";
import { useStateValue } from "./StateContext";
import { setAllPropertyDetails } from "../components/Property/PropertyDetailActions";

const usePropertyDetails = (addressLine1: string, postcode: string) => {
    const { state, dispatch } = useStateValue();
    const [loading, setLoading] = useState(false);
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    useEffect(() => {
        async function fetchData() {
            try {
                setLoading(true);

                const response = await axios.get(`${process.env.FUNCTION_BASE_URL}api/GetPropertyDetails?addressLine1=${addressLine1}&postcode=${postcode}`, {
                    cancelToken: source.token,
                });

                if (response.status == 200) {
                    dispatch(
                        setAllPropertyDetails({
                            NumberOfBedrooms: response.data.NumberOfBedrooms.toString(),
                            PropertyTypeId: response.data.BuildingsAndContentsPropertyTypeId == 0 ? '' : response.data.BuildingsAndContentsPropertyTypeId.toString(),
                            YearBuiltRange: response.data.YearBuiltRange.toString(),
                        })
                    );
                }

                setLoading(false);

            } catch (error) {
                if (error.message != "Operation canceled by the user.") {
                    setLoading(false);
                }
            }
        };

        if (state.ReloadAddress && addressLine1 !== '' && postcode !== '') {
            fetchData();
        }

        return function cleanup() {
            source.cancel('Operation canceled by the user.');
            setLoading(false);
        };

    }, [addressLine1, postcode]);

    return [loading];
};

export default usePropertyDetails;