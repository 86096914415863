import { Action } from "../../models/Action";
import { ActionType } from "../../models/ActionType";
import { NoClaimsDiscountTypeEnum } from "./NoClaimsDiscountTypeEnum";

export const setEditingNCD = (value: NoClaimsDiscountTypeEnum): Action => {
    return {
        type: ActionType.SetEditingNoClaimsDiscount,
        value,
    };
};

export const setBuildingsNCD = (value: number): Action => {
    return {
        type: ActionType.SetBuildingsNoClaimsDiscount,
        value,
    };
};

export const setContentsNCD = (value: number): Action => {
    return {
        type: ActionType.SetContentsNoClaimsDiscount,
        value,
    };
};