import { Router } from "@reach/router";
import React from 'react';
import AccidentalDamage from "../components/Direct/AccidentalDamage";
import AdditionalCover from "../components/Direct/AdditionalCover";
import ApplicationResult from "../components/Direct/ApplicationResult";
import Code from "../components/Direct/Code";
import CoverType from "../components/Direct/CoverType";
import Details from "../components/Direct/Details";
import DirectRoute from "../components/Direct/DirectRoute";
import Eligibility from "../components/Direct/Eligibility";
import Endorsements from "../components/Direct/Endorsements";
import NoClaimsDiscount from "../components/Direct/NoClaimsDiscount";
import AddressCorrespondenceOverlay from "../components/Direct/Overlay/AddressEdit/AddressCorrespondenceOverlay";
import AddressEditOverlay from "../components/Direct/Overlay/AddressEdit/AddressEditOverlay";
import AddressManualOverlay from "../components/Direct/Overlay/AddressEdit/AddressManualOverlay";
import AddressSelectOverlay from "../components/Direct/Overlay/AddressSelect/AddressSelectOverlay";
import NoClaimsDiscountOverlay from "../components/Direct/Overlay/NoClaimsDiscount/NoClaimsDiscountOverlay";
import PolicyHolderOverlay from "../components/Direct/Overlay/PolicyHolder/PolicyHolderOverlay";
import PossessionsBicycleOverlay from "../components/Direct/Overlay/PossessionsBicycle/PossessionsBicycleOverlay";
import PossessionsHighValueItemListOverlay from "../components/Direct/Overlay/PossessionsHighValueItem/PossessionsHighValueItemListOverlay";
import PossessionsHighValueItemOverlay from "../components/Direct/Overlay/PossessionsHighValueItem/PossessionsHighValueItemOverlay";
import PossessionsPersonalOverlay from "../components/Direct/Overlay/PossessionsPersonal/PossessionsPersonalOverlay";
import PropertyNumberOfBedroomsOverlay from "../components/Direct/Overlay/PropertyNumberOfBedrooms/PropertyNumberOfBedroomsOverlay";
import PropertyTypeOverlay from "../components/Direct/Overlay/PropertyType/PropertyTypeOverlay";
import PropertyYearOfConstructionOverlay from "../components/Direct/Overlay/PropertyYearOfConstruction/PropertyYearOfConstructionOverlay";
import VoluntaryExcessOverlay from "../components/Direct/Overlay/VoluntaryExcess/VoluntaryExcessOverlay";
import Payment from "../components/Direct/Payment";
import PolicyInformation from "../components/Direct/PolicyInformation";
import Possessions from "../components/Direct/Possessions";
import Property from "../components/Direct/Property";
import Quote from "../components/Direct/Quote";
import RetrieveQuote from "../components/Direct/RetrieveQuote";
import RetrieveQuoteApplicationComplete from "../components/Direct/RetrieveQuoteApplicationComplete";
import VoluntaryExcess from "../components/Direct/VoluntaryExcess";

const Direct = () => {
    return (
        <Router>
            <DirectRoute path="/direct" component={Details} />
            <DirectRoute path="/direct/retrieve/:code" component={RetrieveQuote} />
            <DirectRoute path="/direct/retrievequotecomplete" component={RetrieveQuoteApplicationComplete} />
            <DirectRoute path="/direct/code/:code" component={Code} />
            <DirectRoute path="/direct/details" component={Details} />
            <AddressEditOverlay path="/direct/details/overlay/editaddress" />
            <AddressManualOverlay path="/direct/details/overlay/manualaddress" />
            <AddressSelectOverlay path="/direct/details/overlay/selectaddress" />
            <DirectRoute path="/direct/property" component={Property} />
            <PropertyNumberOfBedroomsOverlay path="/direct/property/overlay/bedrooms" />
            <PropertyTypeOverlay path="/direct/property/overlay/propertytype" />
            <PropertyYearOfConstructionOverlay path="/direct/property/overlay/yearofconstruction" />
            <DirectRoute path="/direct/quote" component={Quote} />
            <DirectRoute path="/direct/covertype" component={CoverType} showQuoteHeader={true} />
            <DirectRoute path="/direct/accidentaldamage" component={AccidentalDamage} showQuoteHeader={true} />
            <DirectRoute path="/direct/possessions" component={Possessions} showQuoteHeader={true} />
            <PossessionsBicycleOverlay path="/direct/possessions/overlay/bicycle" />
            <PossessionsBicycleOverlay path="/direct/possessions/overlay/bicycle/:index" />
            <PossessionsHighValueItemOverlay path="/direct/possessions/overlay/highvalueitem" />
            <PossessionsHighValueItemOverlay path="/direct/possessions/overlay/highvalueitem/:index" />
            <PossessionsHighValueItemListOverlay path="/direct/possessions/overlay/highvalueitemlist" />
            <PossessionsPersonalOverlay path="/direct/possessions/overlay/personal" />
            <DirectRoute path="/direct/voluntaryexcess" component={VoluntaryExcess} showQuoteHeader={true} />
            <VoluntaryExcessOverlay path="/direct/voluntaryexcess/overlay/excess" />
            <DirectRoute path="/direct/noclaimsdiscount" component={NoClaimsDiscount} showQuoteHeader={true} />
            <NoClaimsDiscountOverlay path="/direct/noclaimsdiscount/overlay/noclaims" />
            <DirectRoute path="/direct/additionalcover" component={AdditionalCover} showQuoteHeader={true} />
            <DirectRoute path="/direct/eligibility" component={Eligibility} showQuoteHeader={true} />
            <DirectRoute path="/direct/endorsements" component={Endorsements} showQuoteHeader={true} />
            <DirectRoute path="/direct/policyinformation" component={PolicyInformation} hideBackButton={true} showQuoteHeader={true} />
            <PolicyHolderOverlay path="/direct/policyinformation/policyholder" />
            <AddressCorrespondenceOverlay path="/direct/policyinformation/correspondence" />
            <DirectRoute path="/direct/payment" component={Payment} />
            <DirectRoute path="/direct/applicationresult" component={ApplicationResult} hideBackButton={true} />
        </Router>
    );
};

export default Direct;