import { Action } from "../../models/Action";
import { ActionType } from "../../models/ActionType";
import { AllAddressDetails, AddressDetailsModel } from "./AddressDetailsModel";

export const setCorrespondenceAddress = (value: AddressDetailsModel): Action => {
    return {
        type: ActionType.SetCorrespondenceAddress,
        value,
    } as Action;
}

export const setAllAddressDetails = (value: AllAddressDetails): Action => {
    return {
        type: ActionType.UpdateAllAddressDetails,
        value,
    } as Action;
};