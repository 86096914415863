import { createStyles, makeStyles, MenuItem, Theme, Hidden } from "@material-ui/core";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Field, Form, FormikBag, FormikProps, withFormik } from "formik";
import { navigate } from "gatsby";
import BackButton from "../Controls/BackButton";
import ContinueButton from "../Controls/ContinueButton";
import HelpButton from "../Controls/HelpButton";
import MaterialButton from "../Controls/MaterialButton";
import MaterialCheckbox, { CheckboxLabel } from "../Controls/MaterialCheckbox";
import MaterialSelect from "../Controls/MaterialSelect";
import { SingleItem } from "../Controls/OverlayItemList";
import { BuildingsAndContentsPropertyType, PropertySetting, YearBuiltRange, PropertyType } from "./PropertyDetailsEnum";
import { ListOfNumberOfBedrooms, ListOfTypeOfProperty, ListOfYearOfConstruction, PropertyDetailsModel, PropertyDetailsValidation } from "./PropertyDetailsModel";
import React from "react";
import { useStateValue } from "../../hooks/StateContext";
import { setRiskAddressConfirmed } from "./PropertyDetailActions";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        display: {
            position: "relative",
            fontFamily: "Spartan MB",
            paddingTop: theme.spacing(2.5),
            '& label': {
                fontFamily: "Spartan MB SemiBold",
            },
            '& p': {
                fontSize: "0.875rem",
                margin: theme.spacing(1, 0),
            },
            '& p:last-child': {
                padding: theme.spacing(0, 0, 1, 0),
                borderBottom: "2px solid #e5e5e5",
            },
        },
        action: {
            position: "absolute",
            right: 0,
            bottom: theme.spacing(1.5),
        },
        help: {
            position: "absolute",
            top: '10px',
            right: theme.spacing(0.25),
            '& svg': {
                fontSize: '1.25rem',
            },
        },
        changeButton: {
            fontFamily: "Spartan MB SemiBold",
            fontSize: "0.8125rem",
            padding: theme.spacing(0.25, 1),
            borderWidth: '2px',
            background: "none",
        },
        selectDiv: {
            cursor: "pointer",
            width: "100%",
            top: 0,
            textAlign: "right",
        },
        selectButton: {
            background: "none",
            borderWidth: 0,
            padding: 0,
            margin: 0,
            '&:focus': {
                outline: 'none',
            },
            '&:hover': {
                background: "none",
            },
            '& svg': {
                position: 'absolute',
                bottom: 0,
                right: 0,
            }
        },
        selectLabel: {
            color: '#807f7f',
        },
    })
);

type PropertyFieldDisplayProps = {
    fieldName: string;
    valueTestId?: string;
    value: string;
    list: SingleItem[];
    helpTitle?: string;
    helpText?: string;
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const PropertyFieldDisplay = ({ fieldName, valueTestId, value, list, helpTitle, helpText, onClick }: PropertyFieldDisplayProps) => {
    const classes = useStyles();
    const selectedValue = list.find(x => x.Id === value);
    return (
        <div className={classes.display}>
            {selectedValue ?
                <MaterialButton
                    testId="change"
                    key={`edit${fieldName.replace(' ', '')}`}
                    className={`${classes.action} ${classes.changeButton}`}
                    onClick={onClick}
                >
                    Change
                </MaterialButton>
                :
                <button className={`${classes.action} ${classes.selectDiv} ${classes.selectButton}`}
                    onClick={onClick}>
                    <ChevronRightIcon />
                </button>
            }
            {
                helpTitle && helpText &&
                <HelpButton
                    testId="helpButton"
                    helpText={helpText}
                    helpTitle={helpTitle}
                    className={classes.help}
                />
            }
            <label>{fieldName}</label>
            <p
                data-testid={valueTestId ? valueTestId : "property-value-id"}
                className={`${selectedValue ? '' : classes.selectLabel}`}>
                {selectedValue ? selectedValue.Value : 'Please Select'}
            </p>
        </div>
    );
};

interface FormProps {
    SubmitHandler: (values: PropertyDetailsModel) => void;
};

const PropertyForm = ({ values }: FormikProps<PropertyDetailsModel> & FormProps) => {
    const { state, dispatch } = useStateValue();
    const handleConfirmRiskAddress = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        dispatch(setRiskAddressConfirmed(checked));
    };

    const isDisabled = () => {
        return (
            !ListOfNumberOfBedrooms.find(x => x.Id === values.NumberOfBedrooms) ||
            !ListOfTypeOfProperty.find(x => x.Id === values.PropertyTypeId) ||
            !ListOfYearOfConstruction.find(x => x.Id === values.YearBuiltRange));
    };

    const loadOverlay = (setting: PropertySetting) => (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        let overlayEndpoint = "";
        switch (setting) {
            case PropertySetting.NumberOfBedrooms:
                overlayEndpoint = "bedrooms";
                break;
            case PropertySetting.TypeOfProperty:
                overlayEndpoint = "propertytype";
                break;
            case PropertySetting.YearOfConstruction:
                overlayEndpoint = "yearofconstruction";
                break;
        }
        navigate(`/direct/property/overlay/${overlayEndpoint}`);
    };
    return (
        <Form id="propertyDetails">
            <Hidden smUp>
                <PropertyFieldDisplay
                    fieldName="Number of bedrooms"
                    valueTestId="property-number-of-bedrooms"
                    value={values.NumberOfBedrooms}
                    list={ListOfNumberOfBedrooms}
                    helpTitle="Number of bedrooms"
                    helpText="Please choose how many bedrooms your home has. This includes any rooms that were originally designed as bedrooms, but are now used for other purposes, e.g. study or computer room."
                    onClick={loadOverlay(PropertySetting.NumberOfBedrooms)}
                />
                <PropertyFieldDisplay
                    fieldName="Type of property"
                    valueTestId="property-type"
                    value={values.PropertyTypeId}
                    list={ListOfTypeOfProperty}
                    helpTitle="Property Type"
                    helpText="Please select the property description that closest matches the property you wish to insure. The property must have its own kitchen, bathroom and toilet and separate and lockable entry and exit."
                    onClick={loadOverlay(PropertySetting.TypeOfProperty)}
                />
                <PropertyFieldDisplay
                    fieldName="Year of construction"
                    valueTestId="property-year-of-construction"
                    value={values.YearBuiltRange}
                    list={ListOfYearOfConstruction}
                    helpTitle="Year of construction"
                    helpText="Please choose the date range that your property was built."
                    onClick={loadOverlay(PropertySetting.YearOfConstruction)}
                />
            </Hidden>
            <Hidden only={'xs'}>
                <Field name="NumberOfBedrooms"
                    id="property-number-of-bedrooms"
                    label="Number of bedrooms"
                    component={MaterialSelect}
                    inputProps={{
                        "data-testid": 'property-number-of-bedrooms'
                    }}
                >
                    <MenuItem value="1">1 Bedroom</MenuItem>
                    <MenuItem value="2">2 Bedrooms</MenuItem>
                    <MenuItem value="3">3 Bedrooms</MenuItem>
                    <MenuItem value="4">4 Bedrooms</MenuItem>
                    <MenuItem value="5">5 Bedrooms</MenuItem>
                    <MenuItem value="6">6 Bedrooms</MenuItem>
                </Field>
                <Field name="PropertyTypeId"
                    id="property-type"
                    label="Type of property"
                    component={MaterialSelect}
                    inputProps={{
                        "data-testid": 'property-type'
                    }}
                >
                    <MenuItem value={PropertyType.TerracedHouse.toString()}>{BuildingsAndContentsPropertyType.TerracedHouse}</MenuItem>
                    <MenuItem value={PropertyType.DetachedHouse.toString()}>{BuildingsAndContentsPropertyType.DetachedHouse}</MenuItem>
                    <MenuItem value={PropertyType.SemiDetachedHouse.toString()}>{BuildingsAndContentsPropertyType.SemiDetachedHouse}</MenuItem>
                    <MenuItem value={PropertyType.TerracedBungalow.toString()}>{BuildingsAndContentsPropertyType.TerracedBungalow}</MenuItem>
                    <MenuItem value={PropertyType.DetachedBungalow.toString()}>{BuildingsAndContentsPropertyType.DetachedBungalow}</MenuItem>
                    <MenuItem value={PropertyType.SemiDetachedBungalow.toString()}>{BuildingsAndContentsPropertyType.SemiDetachedBungalow}</MenuItem>
                    <MenuItem value={PropertyType.PurposeBuiltFlat.toString()}>{BuildingsAndContentsPropertyType.PurposeBuiltFlat}</MenuItem>
                    <MenuItem value={PropertyType.CoachHouse.toString()}>{BuildingsAndContentsPropertyType.CoachHouse}</MenuItem>
                </Field>
                <Field name="YearBuiltRange"
                    id="property-year-built-range"
                    label="Year of construction"
                    component={MaterialSelect}
                    inputProps={{
                        "data-testid": 'property-year-built-range'
                    }}
                >
                    <MenuItem value="Pre1750">{YearBuiltRange.Pre1750}</MenuItem>
                    <MenuItem value="_1750to1869">{YearBuiltRange._1750to1869}</MenuItem>
                    <MenuItem value="_1870to1899">{YearBuiltRange._1870to1899}</MenuItem>
                    <MenuItem value="_1900to1919">{YearBuiltRange._1900to1919}</MenuItem>
                    <MenuItem value="_1920to1945">{YearBuiltRange._1920to1945}</MenuItem>
                    <MenuItem value="_1946to1959">{YearBuiltRange._1946to1959}</MenuItem>
                    <MenuItem value="_1960to1979">{YearBuiltRange._1960to1979}</MenuItem>
                    <MenuItem value="_1980to1989">{YearBuiltRange._1980to1989}</MenuItem>
                    <MenuItem value="_1990to1999">{YearBuiltRange._1990to1999}</MenuItem>
                    <MenuItem value="_2000to2009">{YearBuiltRange._2000to2009}</MenuItem>
                    <MenuItem value="_2010to2019">{YearBuiltRange._2010to2019}</MenuItem>
                    <MenuItem value="_2020onwards">{YearBuiltRange._2020onwards}</MenuItem>
                </Field>
            </Hidden>

            <MaterialCheckbox testId="agreeCorrect" disabled={isDisabled()} defaultChecked={state.IsRiskAddressConfirmed} handleChange={handleConfirmRiskAddress}>
                <CheckboxLabel>I agree these are correct</CheckboxLabel>
            </MaterialCheckbox>
            <div style={{ display: 'flex' }}>
                <BackButton testId="backButton">Back</BackButton>
                <ContinueButton testId="getMyQuote" disabled={!state.IsRiskAddressConfirmed}>This is correct, get my quote</ContinueButton>
            </div>
        </Form >
    );
};

export default withFormik<PropertyDetailsModel & FormProps, PropertyDetailsModel>({
    mapPropsToValues: (props: PropertyDetailsModel & FormProps) => {
        return {
            NumberOfBedrooms: props.NumberOfBedrooms,
            PropertyTypeId: props.PropertyTypeId,
            YearBuiltRange: props.YearBuiltRange,
        };
    },
    handleSubmit: (values, { props }: FormikBag<PropertyDetailsModel & FormProps, PropertyDetailsModel>) => {
        props.SubmitHandler(values);
    },
    validationSchema: PropertyDetailsValidation,
    displayName: 'ApplyForm',
})(PropertyForm);