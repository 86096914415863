import { Grid, Typography, makeStyles, Theme, createStyles, Box, Container, Hidden } from "@material-ui/core";
import React from "react";
import UnderwriterLogo from "../Controls/UnderwriterLogo";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grid: {
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'space-evenly',
            paddingTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(1.5),
            opacity: 0.25,
        },
        premiumMonthlyFigure: {
            fontSize: '1.375rem',
            lineHeight: '1.1875rem',
            [theme.breakpoints.down(374)]: {
                fontSize: '1.1875rem',
                lineHeight: '1.0625rem',
            },
            fontFamily: 'Spartan MB Extrabold',
        },
        premiumYearlyFigure: {
            fontSize: '1.375rem',
            lineHeight: '1.1875rem',
            textAlign: 'right',
            [theme.breakpoints.down(374)]: {
                fontSize: '1.1875rem',
                lineHeight: '1.0625rem',
            },
            [theme.breakpoints.up('md')]: {
                textAlign: 'center',
            },
            fontFamily: 'Spartan MB Extrabold',
        },
        premiumGenericText: {
            fontFamily: 'Spartan MB',
            [theme.breakpoints.down(374)]: {
                fontSize: '0.5rem',
            },
            fontSize: '0.625rem',
            lineHeight: '0.875rem',
            textAlign: 'center',
        },
        premiumYearLabel: {
            fontFamily: 'Spartan MB Semibold',
            textAlign: 'right',
            [theme.breakpoints.down(374)]: {
                fontSize: '0.5rem',
            },
            [theme.breakpoints.up('md')]: {
                textAlign: 'center',
            },
            fontSize: '0.625rem',
        },
        premiumMonthLabel: {
            fontFamily: 'Spartan MB Semibold',
            [theme.breakpoints.down(374)]: {
                fontSize: '0.5rem',
            },
            fontSize: '0.625rem',
        },
        quoteContainer: {
            width: '100%',
            height: '90px',
            display: 'flex',
            justifyContent: 'center',
            padding: '0rem 1rem',
            background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.025) 0%, rgba(0, 0, 0, 0.01) 25%, rgba(0, 0, 0, 0.005) 100%)',
            '&:first-child': {
                background: theme.palette.background.default,
            },
        },
        resultContainer: {
            display: 'flex',
            justifyContent: 'center',
            [theme.breakpoints.down('xs')]: {
                padding: '0',
            },
        },
        gridItem: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }
    })
);

type Props = {
    provider: string;
    totalMonthlyPremium: number;
    totalAnnualPremium: number;
};

const QuoteResultPreview = ({ provider, totalMonthlyPremium, totalAnnualPremium }: Props) => {
    const roundDown = (price: number | undefined) => {
        if (price) {
            return Math.floor(price);
        }
    };

    const classes = useStyles();

    return (
        <Box className={classes.quoteContainer}>
            <Grid id="quotes" container className={classes.grid}>
                <Container maxWidth={'sm'} className={classes.resultContainer}>
                    <Grid item xs={3} className={classes.gridItem}>
                        <UnderwriterLogo provider={provider} useQuoteBarSize={false} />
                    </Grid>
                    <Grid item xs={3} className={classes.gridItem}>
                        <img id="defaqto" alt="Defacto Logo" src='/img/defaqto-logo.png' width="60px" height="36px" />
                    </Grid>
                    <Grid item xs={6} className={classes.gridItem}>
                        <Grid container>
                            <Grid item xs={2} sm={2} md={6} lg={6} >
                                <Hidden smDown>
                                    <Typography className={classes.premiumGenericText} variant="h6">From</Typography>
                                </Hidden>
                            </Grid>
                            <Grid item xs={10} sm={10} md={6} lg={6}>
                                <Typography className={classes.premiumGenericText} variant="h6">From</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography id="annualPremium" className={classes.premiumYearlyFigure} variant="h6">£{roundDown(totalAnnualPremium)}</Typography>
                                <Typography className={classes.premiumYearLabel} variant="h6">P/year</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography id="monthlyPremium" className={classes.premiumMonthlyFigure} variant="h6">£{roundDown(totalMonthlyPremium)}</Typography>
                                <Typography className={classes.premiumMonthLabel} variant="h6">P/month</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        </Box>
    );
};

export default QuoteResultPreview;