import { navigate } from "@reach/router";
import React, { Fragment, useEffect } from "react";
import { useStateValue } from "../../hooks/StateContext";
import BackButton from "../Controls/BackButton";
import ContinueButton from "../Controls/ContinueButton";
import SectionTitle from "../Controls/SectionTitle";
import { selectPremium } from "./QuoteResultsActions";
import { PremiumListItem } from "./SelectPremiumModel";
import { useTheme } from "@material-ui/core";

type Props = {
    forenames: string;
    premiumListItem: PremiumListItem;
    quoteReference: string;
    children: JSX.Element | JSX.Element[];
};

const QuoteSuccess = ({ quoteReference, premiumListItem, children, forenames }: Props) => {
    const { dispatch } = useStateValue();
    const theme = useTheme();

    const handleContinue = (_: React.MouseEvent<HTMLButtonElement, MouseEvent>) => startJourney();

    const startJourney = () => {
        dispatch(selectPremium(
            quoteReference,
            premiumListItem,
        ));
        navigate("/direct/covertype");
    };

    if (theme.siteConfiguration.skipQuoteResults) {
        useEffect(() => {
            startJourney();
        }, []);

        return (<></>);
    }

    return (
        <Fragment>
            <SectionTitle testId="greatStart" title={`Great start, ${forenames}!\nNow let's tailor your quote...`} />
            <div style={{ display: 'flex' }}>
                <BackButton testId="backButton">Back</BackButton>
                <ContinueButton testId="tailorMyQuote" onClick={handleContinue}>Tailor my quote</ContinueButton>
            </div>
            {children}
        </Fragment>
    );
};

export default QuoteSuccess;