import { createStyles, makeStyles, Theme } from "@material-ui/core";
import React, { MouseEvent } from "react";
import { AddressDetailsModel } from "./AddressDetailsModel";
import MaterialButton from "../Controls/MaterialButton";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        addressSummary: {
            position: "relative",
            fontFamily: "Spartan MB",
            '& label': {
                fontFamily: "Spartan MB SemiBold",
            },
            '& p': {
                fontSize: "0.875rem",
                margin: theme.spacing(1, 0),
            },
            '& p:last-child': {
                padding: theme.spacing(0, 0, 1, 0),
                borderBottom: "2px solid #e5e5e5",
            },
        },
        editAddress: {
            position: "absolute",
            right: 0,
            bottom: theme.spacing(1.5),
            background: "none",
            fontFamily: "Spartan MB SemiBold",
            fontSize: "0.8125rem",
            padding: theme.spacing(0.25, 1),
            borderWidth: '2px',
        },
    })
);

interface Props {
    address: AddressDetailsModel;
    onClick: (event: MouseEvent<HTMLButtonElement>) => void;
    hideLabel?: boolean;
};

const AddressSummary = ({ address, onClick, hideLabel }: Props) => {
    const classes = useStyles();
    if (!address) return (<></>);
    return (
        <div className={classes.addressSummary}>
            <MaterialButton
                testId="enterAddress"
                key="editAddress"
                className={classes.editAddress}
                onClick={onClick}
            >
                Edit address
            </MaterialButton>
            {!hideLabel && <label>Address</label>}
            {address.AddressLine1 &&
                <p id="addressLine1">{address.AddressLine1},</p>
            }
            {address.AddressLine2 &&
                <p id="addressLine2">{address.AddressLine2},</p>
            }
            {address.AddressLine3 &&
                <p id="addressLine3">{address.AddressLine3},</p>
            }
            {address.AddressLine4 &&
                <p id="addressLine4">{address.AddressLine4},</p>
            }
            <p id="addressPostcode">{address.Postcode}</p>
        </div>
    );
};

export default AddressSummary;