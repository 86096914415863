import { Action } from "../../models/Action";
import { ActionType } from "../../models/ActionType";
import { SelectPremiumModel, PremiumListItem, QuoteResultModel } from "./SelectPremiumModel";

export const selectPremium = (quoteReference: string, premium: PremiumListItem): Action => {
    return {
        type: ActionType.UpdateQuoteReference,
        value: {
            QuoteReference: quoteReference,
            PremiumData: premium,
        } as SelectPremiumModel,
    };
};

export const setQuoteResults = (value: QuoteResultModel): Action => {
    return {
        type: ActionType.SetQuoteResults,
        value,
    };
};

export const refreshQuotes = (): Action => {
    return {
        type: ActionType.RefreshQuotes,
        value: undefined,
    };
};

export const setCachedResult = (value: any): Action => {
    return {
        type: ActionType.SetCachedResult,
        value: value,
    };
};

export const setShowNoQuotesModal = (value: boolean): Action => {
    return {
        type: ActionType.SetShowNoQuoteModal,
        value
    };
};