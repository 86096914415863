import { RouteComponentProps } from "@reach/router";
import React from "react";
import { useStateValue } from "../../../../hooks/StateContext";
import { setCorrespondenceAddress } from "../../../Address/AddressDetailActions";
import Overlay from "../../../Controls/Overlay";
import EditAddressForm from "../../../Address/EditAddressForm";
import { AddressDetailsModel } from "../../../Address/AddressDetailsModel";

interface Props extends RouteComponentProps { }

const AddressEditOverlay = (_: Props) => {
    const { state, dispatch } = useStateValue();

    const handleCancel = () => {
        history.back();
    };

    const handleSave = (values: AddressDetailsModel) => {
        dispatch(setCorrespondenceAddress(values));
        history.back();
    };

    return (
        <Overlay title="Edit address">
            <EditAddressForm handleCancel={handleCancel} handleSave={handleSave} address={state.CorrespondenceAddress!} />
        </Overlay>
    );
};

export default AddressEditOverlay;