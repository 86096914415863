import { navigate } from '@reach/router';
import React from 'react';
import { useStateValue } from '../../hooks/StateContext';
import useWindowSize from '../../hooks/useWindowSize';
import { setAllAddressDetails } from '../Address/AddressDetailActions';
import { AddressDetailsModel, AllAddressDetails } from '../Address/AddressDetailsModel';
import { addFirstApplicant } from '../Applicant/ApplicantDetailActions';
import { ApplicantDetailsModel } from '../Applicant/ApplicantDetailsModel';
import SectionTitle from '../Controls/SectionTitle';
import DetailsForm from '../Details/DetailsForm';
import { AllDetailsModel } from '../Details/DetailsModel';
import { useTheme } from '@material-ui/core';

const Details = () => {
    const { state, dispatch } = useStateValue();
    const [isManualAddress, setIsManualAddress] = React.useState(false);
    const { isMobile } = useWindowSize();
    const theme = useTheme();

    const handleSubmit = (values: AllDetailsModel) => {
        const applicantDetails: ApplicantDetailsModel = {
            Title: values.Title,
            Forenames: values.Forenames,
            Surname: values.Surname,
            DateOfBirth: values.DateOfBirth,
            EmailAddress: values.EmailAddress,
            TelephoneNumber: values.TelephoneNumber,
        };
        dispatch(addFirstApplicant(applicantDetails));

        if (isMobile) {
            const addressDetails: AllAddressDetails = {
                RiskAddress: values.RiskAddress,
                CorrespondenceAddress: values.RiskAddress,
            };
            dispatch(setAllAddressDetails(addressDetails));

            if (isManualAddress) {
                navigate('/direct/details/overlay/manualaddress/');
            } else {
                navigate('/direct/details/overlay/selectaddress/');
            }
        } else {
            navigate('/direct/property');
        }
    };

    const handleManualAddress = () => {
        setIsManualAddress(true);
    };

    const handleSelectedAddress = (values: AllDetailsModel, addressParts: string[]) => {
        const addressDetails: AllAddressDetails = {
            RiskAddress: {
                AddressLine1: addressParts[0] || '',
                AddressLine2: addressParts[1] || '',
                AddressLine3: addressParts[2] || '',
                AddressLine4: addressParts[3] || '',
                Postcode: values.RiskAddress.Postcode || '',
            },
            CorrespondenceAddress: {
                AddressLine1: addressParts[0] || '',
                AddressLine2: addressParts[1] || '',
                AddressLine3: addressParts[2] || '',
                AddressLine4: addressParts[3] || '',
                Postcode: values.RiskAddress.Postcode || '',
            },
        };

        dispatch(setAllAddressDetails(addressDetails));

        navigate('/direct/property');
    };

    const handleManualAddressSave = (values: AddressDetailsModel) => {
        const addressDetails: AllAddressDetails = {
            RiskAddress: values,
            CorrespondenceAddress: values,
        };
        dispatch(setAllAddressDetails(addressDetails));

        navigate('/direct/property');
    };

    return (
        <>
            <SectionTitle
                title="Let's get your cheapest home insurance quote."
                icon={'/img/Buildings_Contents_Uinsure-small.png'}
            />
            <DetailsForm
                Title={state && state.Client1.Title}
                Forenames={state && state.Client1.Forenames}
                Surname={state && state.Client1.Surname}
                DateOfBirth={state && state.Client1.DateOfBirth}
                RiskAddress={state && state.RiskAddress}
                EmailAddress={state && state.Client1.EmailAddress}
                TelephoneNumber={state && state.Client1.TelephoneNumber}
                SubmitHandler={handleSubmit}
                showContactQuestionsBeforePremium={
                    theme.siteConfiguration.showContactQuestionsBeforePremium || false
                }
                onSelectAddress={handleSelectedAddress}
                onManualEdit={handleManualAddress}
                onManualAddressSave={handleManualAddressSave}
            />
        </>
    );
};

export default Details;
