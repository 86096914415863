import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import CardHeader from './CardHeader';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardBox: (reducedMargin: boolean) => ({
            margin: reducedMargin ? '0rem 0rem' : '1.875rem 0rem',
            borderRadius: '5px',
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #e5e5e5',
            filter: 'drop-shadow(0px 9px 8px rgba(152,184,190,0.31))',
        }),
        cardContent: {
            padding: theme.spacing(2, 2, 1, 2),
        },
        cardContentCompact: {
            padding: theme.spacing(1, 2, 2, 2),
        },
    }),
);

type Props = {
    title: string;
    testId?: string;
    icon?: JSX.Element;
    helpText?: string | JSX.Element;
    helpTitle?: string;
    addHeaderSeparator?: boolean;
    reducedMargin?: boolean;
    children: JSX.Element;
};

const Card = ({
    title,
    testId,
    icon,
    helpText,
    helpTitle,
    addHeaderSeparator,
    reducedMargin = false,
    children,
}: Props) => {
    const classes = useStyles(reducedMargin);

    return (
        <div className={classes.cardBox}>
            <CardHeader
                title={title}
                icon={icon}
                helpText={helpText!}
                helpTitle={helpTitle!}
                addSeparator={addHeaderSeparator}
            />
            <div
                className={addHeaderSeparator ? classes.cardContent : classes.cardContentCompact}
                data-testid={testId}
            >
                {children}
            </div>
        </div>
    );
};

export default Card;
