import { Grid } from "@material-ui/core";
import { Form, Formik, FormikProps } from "formik";
import React, { Fragment } from 'react';
import CancelButton from "../Controls/CancelButton";
import ContinueButton from "../Controls/ContinueButton";
import MaterialModal from "../Controls/MaterialModal";
import ModalContent from "../Controls/ModalContent";
import ModalHeader from "../Controls/ModalHeader";
import ModalTitle from "../Controls/ModalTitle";
import AddressDetails from "./AddressDetails";
import { AddressDetailsModel } from "./AddressDetailsModel";
import { AddressDetailsValidationSchema } from "./AddressDetailsValidation";
import * as Yup from "yup";

interface AddressFinderProps {
    open: boolean;
    onCancel: () => void;
    onSave: (values: AddressDetailsModel) => void;
    address: AddressDetailsModel;
};

const AddressEditModal = ({ open, onCancel, onSave, address }: AddressFinderProps) => {
    return (
        <MaterialModal
            open={open}
            onClose={onCancel}
        >
            <Fragment>
                <ModalHeader onClick={onCancel}>
                    <ModalTitle id="simple-modal-title">
                        <Fragment>Your address</Fragment>
                    </ModalTitle>
                </ModalHeader>
                <ModalContent>
                    <Formik
                        initialValues={address}
                        validationSchema={Yup.object({ ...AddressDetailsValidationSchema })}
                        onSubmit={(values) => {
                            onSave(values);
                        }}
                    >{({ handleReset }: FormikProps<any>) =>
                        <Form>
                            <Grid container>
                                <Grid item xs={12}>
                                    <AddressDetails />
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <CancelButton testId="cancel" onClick={() => { handleReset(), onCancel() }}>Cancel</CancelButton>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <ContinueButton testId="saveAndUse" disabled={false}>Save &amp; use</ContinueButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                        }
                    </Formik>
                </ModalContent>
            </Fragment>
        </MaterialModal>
    );
};

export default AddressEditModal;