import React from 'react';
import MaterialModal from "../Controls/MaterialModal";
import ModalContent from "../Controls/ModalContent";
import ModalHeader from "../Controls/ModalHeader";
import ModalTitle from "../Controls/ModalTitle";
import { PossessionsModel } from './PossessionsModel';
import PossessionsHighValueItemForm from '../Direct/Overlay/PossessionsHighValueItem/PossessionsHighValueItemForm';

interface Props {
    open: boolean;
    onCancel: () => void;
    onSave: (item: PossessionsModel) => void;
    onRemove: (item: PossessionsModel) => void;
    item: PossessionsModel;
};

const PossessionsHighValueItemModal = ({ open, onCancel, onSave, onRemove, item }: Props) => {
    return (
        <MaterialModal
            open={open}
            onClose={onCancel}
        >
            <>
                <ModalHeader onClick={onCancel}>
                    <ModalTitle id="high-value-item-modal">
                        <>High value item</>
                    </ModalTitle>
                </ModalHeader>
                <ModalContent>
                    <PossessionsHighValueItemForm
                        IncludeAtHome={item.IncludeAtHome}
                        IncludeAwayFromHome={item.IncludeAwayFromHome}
                        IsEditing={item.IsEditing}
                        ItemDescription={item.ItemDescription}
                        ItemType={item.ItemType}
                        ItemValue={item.ItemValue}
                        onRemove={onRemove}
                        onSaveChanges={onSave}
                        onCancel={onCancel}
                    />
                </ModalContent>
            </>
        </MaterialModal>
    );
};

export default PossessionsHighValueItemModal;