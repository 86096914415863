import { Box } from "@material-ui/core";
import React from "react";
import Card from "../Controls/Card";

type Props = {
    id: string;
    title: string;
    children: JSX.Element | JSX.Element[];
};

const EndorsementCard = ({ id, title, children }: Props) => {
    return (
        <Card
            title={title}
        >
            <Box id={id}>
                {children}
            </Box>
        </Card>
    );
};

export default EndorsementCard;