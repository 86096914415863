import { navigate, RouteComponentProps } from "@reach/router";
import React from "react";
import { useStateValue } from "../../../../hooks/StateContext";
import Overlay from "../../../Controls/Overlay";
import { setHighValueItemEdit, setRemovingSpecificPossession, upsertPersonalPossession } from "../../../Possessions/PossessionsActions";
import { PossessionsModel, PossessionsModelInitial } from "../../../Possessions/PossessionsModel";
import { refreshQuotes } from "../../../QuoteResults/QuoteResultsActions";
import PossessionsHighValueItemForm from "./PossessionsHighValueItemForm";

interface Props extends RouteComponentProps { }

const PossessionsHighValueItemOverlay = ({ }: Props) => {
    const { state, dispatch } = useStateValue();

    const selectOverlayHandle = (values: PossessionsModel) => {
        dispatch(setHighValueItemEdit(values));
        navigate('/direct/possessions/overlay/highvalueitemlist');
    };

    const saveChanges = (values: PossessionsModel) => {
        dispatch(refreshQuotes());
        dispatch(upsertPersonalPossession(values));
        history.back();
    }

    const handleRemove = (values: PossessionsModel) => {
        dispatch(setRemovingSpecificPossession(values));
        history.back();
    }

    const getLoadItem = (): PossessionsModel => {
        let item = state.ContentsPersonalPossessions.filter(x => x.IsEditing);
        if (state.HighValueItemEdit) {
            item = [state.HighValueItemEdit];
        }
        if (item.length > 0) {
            return { ...item[0] };
        } else {
            return { ...PossessionsModelInitial };
        }
    };

    const item = getLoadItem();

    return (
        <Overlay title="High value item">
            <PossessionsHighValueItemForm
                ItemDescription={item.ItemDescription}
                ItemType={item.ItemType}
                ItemValue={item.ItemValue}
                IncludeAtHome={item.IncludeAtHome}
                IncludeAwayFromHome={item.IncludeAwayFromHome}
                IsEditing={item.IsEditing}
                onSelectOverlay={selectOverlayHandle}
                onSaveChanges={saveChanges}
                onRemove={handleRemove}
            />
        </Overlay>
    );
};

export default PossessionsHighValueItemOverlay;