import { Field } from "formik";
import React from "react";
import MaterialDateInput from "../Controls/MaterialDateInput";
import MaterialTextField from "../Controls/MaterialTextField";
import TitleRadioGroup from "../Controls/TitleRadioGroup";

interface Props {
    titleLabel?: string;
    forenamesLabel?: string;
    surnameLabel?: string;
    dateOfBirthLabel?: string;
}

const ApplicantDetails = ({ titleLabel, forenamesLabel, surnameLabel, dateOfBirthLabel }: Props) => {
    return (
        <React.Fragment>
            <TitleRadioGroup label={titleLabel || "What's your title?"} />
            <Field name={`Forenames`}
                id={`client-forenames`}
                label={forenamesLabel || "First, what's your name?"}
                component={MaterialTextField}
                placeholder="Type your first name here"
            />
            <Field name={`Surname`}
                id={`client-surname`}
                label={surnameLabel || "Surname"}
                component={MaterialTextField}
                placeholder={"Type your surname here"}
            />
            <Field name={`DateOfBirth`}
                id={`client-date-of-birth`}
                label={dateOfBirthLabel || "Date of Birth"}
                component={MaterialDateInput}
            />
        </React.Fragment>
    );
};

export default ApplicantDetails;