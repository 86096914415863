import React from "react";
import { AddressDetailsValidationSchema } from "./AddressDetailsValidation";
import { FormikProps, Form, Formik } from "formik";
import { Grid } from "@material-ui/core";
import AddressDetails from "./AddressDetails";
import CancelButton from "../Controls/CancelButton";
import ContinueButton from "../Controls/ContinueButton";
import { AddressDetailsModel } from "./AddressDetailsModel";
import * as Yup from 'yup';

type EditAddressFormProps = {
    address: AddressDetailsModel;
    handleSave: (values: AddressDetailsModel) => void;
    handleCancel: () => void;
};

const EditAddressForm = ({ address, handleSave, handleCancel }: EditAddressFormProps) => {
    return (
        <Formik
            initialValues={{
                AddressLine1: address.AddressLine1,
                AddressLine2: address.AddressLine2,
                AddressLine3: address.AddressLine3,
                AddressLine4: address.AddressLine4,
                Postcode: address.Postcode
            }}
            validationSchema={Yup.object({ ...AddressDetailsValidationSchema })}
            onSubmit={(values, { setSubmitting }) => {
                handleSave(values);
                setSubmitting(false);
            }}
        >{({ handleReset }: FormikProps<any>) =>
            <Form>
                <Grid container>
                    <Grid item xs={12}>
                        <AddressDetails />
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <CancelButton testId="cancel" onClick={() => { handleReset(), handleCancel(); }}>Cancel</CancelButton>
                        </Grid>
                        <Grid item xs={6}>
                            <ContinueButton testId="saveAndUse" disabled={false}>Save &amp; use</ContinueButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Form>
            }
        </Formik>
    );
};

export default EditAddressForm;