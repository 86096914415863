import { navigate } from "@reach/router";
import React from "react";
import { useStateValue } from "../../hooks/StateContext";
import { setHomeEmergencyCover, setLegalProtectionCover } from "../AdditionalCover/AdditionalCoverActions";
import BackButton from "../Controls/BackButton";
import ContinueButton from "../Controls/ContinueButton";
import Section from "../Controls/Section";
import SectionTitle from "../Controls/SectionTitle";
import YesNoQuestion from "../Controls/YesNoQuestion";
import { refreshQuotes } from "../QuoteResults/QuoteResultsActions";

const AdditionalCover = () => {
    const { state, dispatch } = useStateValue();

    const handleContinue = () => {
        dispatch(refreshQuotes());
        navigate("/direct/eligibility");
    };

    const handleHomeEmergencyCover = (value: boolean) => {
        dispatch(setHomeEmergencyCover(value));
    };

    const handleLegalProtectionCover = (value: boolean) => {
        dispatch(setLegalProtectionCover(value));
    };

    return (
        <React.Fragment>
            <SectionTitle title="Additional Cover Options." />
            <Section
                testId="homeEmergencyButton"
                sectionTitle={"Home Emergency Cover"}
                helpTitle={"Home Emergency"}
                helpText={<>
                    <p>Home Emergency Cover provides up to £1,500 per incident for a number of household emergencies including the cost of a contractor’s emergency call-out, labour charges and parts and materials.</p>
                    <p>Cover is provided in the following areas:</p>
                    <ul>
                        <li>Failure of the Primary Heating system</li>
                        <li>Damage to or failure of the plumbing and drainage system where internal flooding or water damage is a likely consequence;</li>
                        <li>A gas leak;</li>
                        <li>Electricity supply failure</li>
                        <li>Water supply system failure;</li>
                        <li>Lost keys or external door/window/lock failure;</li>
                        <li>Pest infestation;</li>
                        <li>Sudden damage to the roofing causing internal damage,</li>
                        <li>A blocked toilet; blocked external drains within the boundaries of your home where this can be resolved by jetting.</li>
                    </ul>
                </>}
            >
                <YesNoQuestion
                    questionText={"Would you like to include Home Emergency Cover as part of your policy?"}
                    onClick={(value) => handleHomeEmergencyCover(value)}
                    initialAnswer={state.HomeEmergencyCover}
                    additionalInformation={[
                        'Cover for the failure of the Primary Heating system, plumbing system and utility supply',
                        'Up to £1500 per incident including parts and labour',
                        'Unlimited number of claims covered.'
                    ]}
                />
            </Section>
            <Section
                testId="legalProtectionButton"
                sectionTitle={"Legal Protection Cover"}
                helpTitle={"Legal Protection"}
                helpText={<>
                    <p>Our Family Legal Protection policy offers £150,000 of legal cover and is designed to help customers through difficult and often stressful legal situations by providing them with financial support for solicitors and barristers’ fees and a 24-hour legal advice Helpline.</p>
                    <p>A 24-hour legal advice Helpline is also included.</p>
                    <p>Cover is provided in areas such as Personal Injury, Employment Disputes, Identity Theft, Probate and infringement of Property and Consumer rights.</p>
                </>}
            >
                <YesNoQuestion
                    questionText={"Would you like to include Legal Protection as part of your policy?"}
                    onClick={(value) => handleLegalProtectionCover(value)}
                    initialAnswer={state.LegalProtectionCover}
                    additionalInformation={[
                        '£150,000 of legal expenses cover',
                        'Includes Employment disputes and personal injury claims',
                        'School Admissions disputes and Probate also covered'
                    ]} />
            </Section>
            <div style={{ display: 'flex' }}>
                <BackButton>Back</BackButton>
                <ContinueButton testId="additionalCoverNext"
                    onClick={handleContinue}
                    disabled={state.RefreshingQuote || state.QuoteError || Object.entries(state.SelectedQuote.PremiumData).length === 0}
                >
                    Next
                </ContinueButton>
            </div>
        </React.Fragment>
    );
};

export default AdditionalCover;