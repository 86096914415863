import { createStyles, InputAdornment, makeStyles, TextField, Theme, Box } from "@material-ui/core";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import React from 'react';
import { useStyles } from "./Styles/MaterialTextFieldUseStyles";
import HelpButton from "./HelpButton";

const useLocalStyles = makeStyles((theme: Theme) =>
    createStyles({
        arrow: {
            fontSize: '1.5rem',
        },
        box: {
            position: 'relative',
        },
        helpIcon: {
            top: theme.spacing(2),
            position: 'absolute',
            right: '2px',
            zIndex: 1,
            '& svg': {
                fontSize: '1.375rem',
            },
        },
    })
);

type Props = {
    label: string;
    value: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    boldLabel?: boolean;
    id?: string;
    testId?: string;
    helpTitle?: string;
    helpText?: string | JSX.Element;
    reducedMargin?: boolean;
};

const SelectOverlay = ({ label, value, onClick, boldLabel, id, testId, helpTitle, helpText, reducedMargin }: Props) => {
    const classes = useStyles({ boldLabel: !!boldLabel, reducedMargin: !!reducedMargin })();
    const localClasses = useLocalStyles();

    return (
        <>
            <Box className={localClasses.box} >
                {helpTitle && helpText &&
                    <div data-testid="modal-help-button">
                        <HelpButton helpText={helpText} helpTitle={helpTitle} className={localClasses.helpIcon} />
                    </div>
                }
                <TextField
                    id={id || "select-overlay"}
                    data-testid={testId || "helpButton"}
                    className={classes.textField}
                    fullWidth
                    label={label}
                    margin="normal"
                    value={value}
                    InputProps={{
                        readOnly: true,
                        onClick: onClick as any,
                        endAdornment:
                            <InputAdornment position="end" id="high-value-item-list">
                                <NavigateNextIcon id="changeArrow" className={localClasses.arrow} />
                            </InputAdornment>
                    }}
                    InputLabelProps={{
                        shrink: true,
                        classes: {
                            root: classes.labelRoot
                        }
                    }}
                >

                </TextField>
            </Box>
        </>
    );
};

export default SelectOverlay;