import {
    AppBar,
    createStyles,
    Grid,
    LinearProgress,
    makeStyles,
    Theme,
    Toolbar,
    Typography,
    withStyles,
} from '@material-ui/core';
import { lighten, useTheme } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import SyncIcon from '@material-ui/icons/Sync';
import React from 'react';
import { useStateValue } from '../../hooks/StateContext';
import useQuoteAPI from '../../hooks/useQuoteAPI';
import ContactModal from '../Controls/ContactModal';
import UnderwriterLogo from '../Controls/UnderwriterLogo';
import { setShowNoQuotesModal } from './QuoteResultsActions';

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        offset: theme.mixins.toolbar,
        labelBase: {
            flexGrow: 1,
            color: theme.quoteBar.textColor,
            fontSize: '0.8125rem',
            fontFamily: 'Spartan MB',
            lineHeight: '14px',
            textAlign: 'left',
        },
        cheapestQuoteLabelSmall: {
            [theme.breakpoints.up(500)]: {
                display: 'none',
            },
        },
        cheapestQuoteLabelLarge: {
            [theme.breakpoints.down(500)]: {
                display: 'none',
            },
        },
        pricePounds: {
            fontSize: '1.25rem',
            fontFamily: 'Spartan MB Extrabold',
            lineHeight: '1.25rem',
            textAlign: 'right',
            [theme.breakpoints.down(374)]: {
                fontSize: '1rem',
            },
        },
        pricePennies: {
            fontSize: '0.625rem',
            fontFamily: 'Spartan MB Extrabold',
            textAlign: 'left',
            [theme.breakpoints.down(374)]: {
                fontSize: '0.5rem',
            },
        },
        gridPennies: {
            display: 'flex',
            alignItems: 'flex-end',
        },
        gridLabel: {
            display: 'flex',
            alignItems: 'center',
        },
        paymentMethod: {
            fontSize: '0.625rem',
            fontFamily: 'Spartan MB Semibold',
            textAlign: 'center',
        },
        appbar: {
            backgroundColor: theme.quoteBar.backgroundColor,
            [theme.breakpoints.only('xs')]: {
                marginTop: '56px',
            },
            [theme.breakpoints.up('sm')]: {
                marginTop: `${theme.header.minHeight}px`,
            },
        },
        toolbar: {
            minHeight: '65px',
            borderTop: `1px solid ${theme.quoteBar.borderColor}`,
            borderBottom: `1px solid ${theme.quoteBar.borderColor}`,
            justifyContent: 'center',
        },
        quotebar: {
            maxWidth: '570px',
        },
        image: {
            textAlign: 'center',
            backgroundColor: '#fff',
            padding: theme.spacing(1),
            '& img': {
                verticalAlign: 'middle',
                maxWidth: '100%',
                width: 'auto',
                maxHeight: '40px',
                height: 'auto',
            },
        },
        divider: {
            borderRight: '1px solid #dddddd',
            height: '18px',
        },
        dividerText: {
            fontSize: '0.625rem',
            fontFamily: 'Spartan MB Semibold',
            textAlign: 'center',
            paddingBottom: '3px',
        },
        gridPremium: {
            maxWidth: '100px',
        },
        loadingIcon: {
            margin: '5px 4px',
            color: theme.quoteBar.iconColor,
        },
        loadingText: {
            fontSize: '0.75rem',
            margin: '9px 4px',
            color: theme.quoteBar.textColor,
        },
        loadingBar: {
            flexGrow: 1,
        },
        subheader: {
            [theme.breakpoints.only('xs')]: {
                fontSize: '0.6875rem',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '0.8125rem',
            },
            margin: 0,
            textAlign: 'center',
            fontFamily: 'Spartan MB',
            color: theme.quoteBar.textColor,
            borderBottom: '1px solid #e5e5e5',
            borderTop: '1px solid #e5e5e5',
            paddingBottom: '4px',
            paddingTop: '4px',
        },
    });
});

const progressStyles = ({ palette }: Theme) =>
    createStyles({
        root: {
            height: 3,
            backgroundColor: palette.background.paper,
        },
        colorPrimary: {
            backgroundColor: palette.text.secondary,
        },
        barColorPrimary: {
            backgroundColor: lighten(palette.text.secondary, 0.5),
        },
    });

const ColorLinearProgress = withStyles(progressStyles)(LinearProgress);

const QuoteHeader = () => {
    const classes = useStyles();
    const { state, dispatch } = useStateValue();
    const { loading, error } = useQuoteAPI();
    const theme = useTheme();
    const results = state.QuoteResults;

    const getPounds = (price: number) => {
        const values = ('' + price).split('.');
        return values[0];
    };

    const getPence = (price: number) => {
        const roundedValue = parseFloat('' + Math.round(price * 100) / 100).toFixed(2);
        const values = ('' + roundedValue).split('.');
        return values[1];
    };

    const handleClose = () => {
        dispatch(setShowNoQuotesModal(false));
    };

    return (
        <>
            <AppBar position="fixed" className={classes.appbar} elevation={0}>
                {loading && (
                    <>
                        <Toolbar className={classes.toolbar} data-testid="quote-loading">
                            <Grid container spacing={0}>
                                <Grid container justify="center">
                                    <SyncIcon color="disabled" className={classes.loadingIcon} />
                                    <Typography
                                        color="textPrimary"
                                        component="p"
                                        className={classes.loadingText}
                                    >
                                        Refreshing your cheapest quote
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Toolbar>
                        <Grid container spacing={0}>
                            <Grid item className={classes.loadingBar}>
                                <ColorLinearProgress />
                            </Grid>
                        </Grid>
                    </>
                )}
                {error && !loading && (
                    <Toolbar className={classes.toolbar} data-testid="quote-error">
                        <Grid container spacing={0}>
                            <Grid container justify="center">
                                <ErrorOutlineIcon
                                    color="disabled"
                                    className={classes.loadingIcon}
                                />
                                <Typography
                                    color="textPrimary"
                                    component="p"
                                    className={classes.loadingText}
                                >
                                    We couldn't retrieve a quote
                                </Typography>
                            </Grid>
                        </Grid>
                    </Toolbar>
                )}
                {!loading &&
                    results &&
                    !error &&
                    results.PremiumListItems &&
                    results.PremiumListItems.length == 0 && (
                        <Toolbar className={classes.toolbar} data-testid="quote-error">
                            <Grid container spacing={0}>
                                <Grid container justify="center">
                                    <ErrorOutlineIcon
                                        color="disabled"
                                        className={classes.loadingIcon}
                                    />
                                    <Typography
                                        color="textPrimary"
                                        component="p"
                                        className={classes.loadingText}
                                    >
                                        Sorry, we are unable to provide a quote.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    )}
                {!loading &&
                    results &&
                    !error &&
                    results.PremiumListItems &&
                    results.PremiumListItems.length > 0 && (
                        <>
                            <Toolbar className={classes.toolbar} data-testid="quote-result">
                                <Grid
                                    container
                                    alignItems="center"
                                    spacing={0}
                                    className={classes.quotebar}
                                >
                                    <Grid item xs={6} sm={7}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={5} sm={3}>
                                                <div id="logo" className={classes.image}>
                                                    <UnderwriterLogo
                                                        provider={
                                                            results.PremiumListItems[0].Provider
                                                        }
                                                        useQuoteBarSize={true}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={7} sm={9} className={classes.gridLabel}>
                                                <Typography
                                                    id="cheapestQuote"
                                                    variant="body1"
                                                    align="center"
                                                    className={`${classes.labelBase} ${classes.cheapestQuoteLabelLarge}`}
                                                >
                                                    Our cheapest quote
                                                </Typography>
                                                <Typography
                                                    variant="body1"
                                                    align="center"
                                                    className={`${classes.labelBase} ${classes.cheapestQuoteLabelSmall}`}
                                                >
                                                    Cheapest
                                                    <br />
                                                    quote
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={2} sm={2} className={classes.gridPremium}>
                                        <Grid container data-testid="monthly-price">
                                            <Grid item xs={9} sm={7}>
                                                <Typography
                                                    variant="h6"
                                                    align="center"
                                                    className={`${classes.labelBase} ${classes.pricePounds}`}
                                                >
                                                    £
                                                    {getPounds(
                                                        results.PremiumListItems[0]
                                                            .TotalMonthlyPremium!,
                                                    )}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={3}
                                                sm={5}
                                                className={classes.gridPennies}
                                            >
                                                <Typography
                                                    variant="h6"
                                                    align="center"
                                                    className={`${classes.labelBase} ${classes.pricePennies}`}
                                                >
                                                    .
                                                    {getPence(
                                                        results.PremiumListItems[0]
                                                            .TotalMonthlyPremium!,
                                                    )}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="h6"
                                                    align="center"
                                                    className={`${classes.labelBase} ${classes.paymentMethod}`}
                                                >
                                                    Monthly
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={1} sm={1}>
                                        <Grid container spacing={0}>
                                            <Grid item xs={6}>
                                                <div className={classes.divider} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="h6"
                                                    align="center"
                                                    className={`${classes.labelBase} ${classes.dividerText}`}
                                                >
                                                    or
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <div className={classes.divider} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={3} sm={2} className={classes.gridPremium}>
                                        <Grid container data-testid="annual-price">
                                            <Grid item xs={9}>
                                                <Typography
                                                    variant="h6"
                                                    align="center"
                                                    className={`${classes.labelBase} ${classes.pricePounds}`}
                                                >
                                                    £
                                                    {getPounds(
                                                        results.PremiumListItems[0]
                                                            .TotalAnnualPremium!,
                                                    )}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3} className={classes.gridPennies}>
                                                <Typography
                                                    variant="h6"
                                                    align="center"
                                                    className={`${classes.labelBase} ${classes.pricePennies}`}
                                                >
                                                    .
                                                    {getPence(
                                                        results.PremiumListItems[0]
                                                            .TotalAnnualPremium!,
                                                    )}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="h6"
                                                    align="center"
                                                    className={`${classes.labelBase} ${classes.paymentMethod}`}
                                                >
                                                    Annually
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                            {theme.quoteBar.showSubheader && (
                                <p className={classes.subheader}>{theme.quoteBar.subheaderText}</p>
                            )}
                        </>
                    )}
            </AppBar>
            <div className={classes.offset} />
            <ContactModal
                open={state.ShowNoQuotesModal}
                title="Sorry."
                subTitle="We are unable to provide a quote."
                content={`Please call us on ${theme.metadata.phoneNumber}.`}
                onClose={handleClose}
                showCloseButton={false}
            />
        </>
    );
};

export default QuoteHeader;
