import { RouteComponentProps, navigate } from "@reach/router";
import React from "react";
import useAddressAPI from "../../../../hooks/useAddressAPI";
import { AllAddressDetails } from "../../../Address/AddressDetailsModel";
import { setAllAddressDetails } from "../../../Address/AddressDetailActions";
import { useStateValue } from "../../../../hooks/StateContext";
import OverlayItemList, { SingleItem } from "../../../Controls/OverlayItemList";
import { formatPostcode } from "../../../utils";

interface Props extends RouteComponentProps { }

const AddressSelectOverlay = (_: Props) => {
    const { state, dispatch } = useStateValue();
    const { results, loading } = useAddressAPI(state.RiskAddress.Postcode);

    const handleSelectItem = (selectedItem: SingleItem) => (_: any) => {
        if (selectedItem) {
            let addressParts = selectedItem.Value.split(",").map(a => a.trim());
            addressParts.pop();

            const addressDetails: AllAddressDetails = {
                RiskAddress: {
                    AddressLine1: addressParts[0] || '',
                    AddressLine2: addressParts[1] || '',
                    AddressLine3: addressParts[2] || '',
                    AddressLine4: addressParts[3] || '',
                    Postcode: state.RiskAddress.Postcode || addressParts[4]
                },
                CorrespondenceAddress: {
                    AddressLine1: addressParts[0] || '',
                    AddressLine2: addressParts[1] || '',
                    AddressLine3: addressParts[2] || '',
                    AddressLine4: addressParts[3] || '',
                    Postcode: state.RiskAddress.Postcode || addressParts[4]
                },
            };

            dispatch(
                setAllAddressDetails(addressDetails)
            );
        }

        navigate('/direct/property', { replace: true });
    };

    return (
        <OverlayItemList
            title={`Properties for ${formatPostcode(state.RiskAddress.Postcode)}`}
            listItems={results && results.Items.map((a: any) => ({
                Id: a.Id,
                Value: `${a.Text}, ${a.Description}`,
                DisplayValue: a.Text
            } as SingleItem))}
            selectedItem=''
            handleSelectItem={handleSelectItem}
            isLoading={loading}
        />
    );
};

export default AddressSelectOverlay;