import { createStyles, makeStyles, Theme, LinearProgress } from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import React from 'react';
import Overlay from "./Overlay";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonList: {
            listStyle: 'none',
            padding: 0,
            margin: 0,
            '& li:not(:last-child)': {
                borderBottom: '2px solid #e5e5e5',
            },
        },
        buttonDiv: {
            display: 'flex',
            width: '100%',
            height: '50px',
            alignItems: 'center',
        },
        button: {
            flexGrow: 1,
            fontFamily: "Spartan MB",
            fontSize: "14px",
            cursor: "pointer",
            background: "none",
            border: "none",
            margin: theme.spacing(1, 0),
            padding: "0",
            textAlign: 'left',
            '&:active, &:focus': {
                "outline": "none",
            },
        },
        tick: {
            color: theme.palette.secondary.main,
        },
    })
);

export interface SingleItem {
    Id: string;
    Value: string;
    DisplayValue?: string;
}

type Props = {
    title: string;
    listItems: SingleItem[];
    selectedItem?: string;
    testId?: string;
    handleSelectItem: (selectedItem: SingleItem) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    isLoading: boolean;
};

const OverlayItemList = ({ title, listItems, selectedItem, testId, handleSelectItem, isLoading }: Props) => {
    const classes = useStyles();

    return (
        <Overlay title={title}>
            {isLoading ?
                <LinearProgress /> :
                <ul data-testid={testId || "overlayList"} className={classes.buttonList}>
                    {listItems &&
                        listItems.map((item, index) => {
                            const uid = `${title.replace(' ', '').toLowerCase()}_${index.toString()}`;
                            return (
                                <li id={uid} key={uid}>
                                    <div className={classes.buttonDiv}>
                                        <button className={classes.button} onClick={handleSelectItem(item)}>
                                            {item.DisplayValue || item.Value}
                                        </button>
                                        {selectedItem === item.Id &&
                                            <CheckIcon className={classes.tick} />
                                        }
                                    </div>
                                </li>
                            )
                        })
                    }
                </ul>
            }
        </Overlay>
    );
};

export default OverlayItemList;

OverlayItemList.defaultProps = { isLoading: false };