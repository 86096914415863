import { InputAdornment, TextField } from "@material-ui/core";
import { FieldProps } from "formik";
import React from 'react';
import MaskedInput from "react-text-mask";
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import useStyles from "./Styles/MaterialTextFieldUseStyles";

interface NumberFormatCustomProps {
    inputRef: (ref: HTMLInputElement | null) => void;
}

const NumberFormatCustom = (props: NumberFormatCustomProps) => {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref: any) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={createNumberMask({ prefix: '' })}
            guide={false}
            showMask
        />
    );
};

const MaterialFormattedNumericField = <P extends object>({
    field,
    form: {
        touched,
        errors,
    },
    ...props
}: FieldProps<P>) => {
    const classes = useStyles();
    const get = (o: any, path: string) => path
        .split('.')
        .reduce((o: any = {}, key: string) => o[key], o);

    const touchedField = get(touched, field.name);
    const errorsField = get(errors, field.name);

    return (
        <TextField
            className={classes.textField}
            fullWidth
            margin="normal"
            helperText={touched && touchedField ? errors && errorsField : ""}
            error={touched && touchedField && errors && Boolean(errorsField)}
            InputProps={{
                inputComponent: NumberFormatCustom as any,
                startAdornment: (<InputAdornment position="start">£</InputAdornment>),
            }}
            inputProps={{
                maxLength: 100,
            }}
            InputLabelProps={{
                shrink: true,
                classes: {
                    root: classes.labelRoot
                },
            }}
            {...props}
            {...field}
        />
    );
};

export default MaterialFormattedNumericField;