import React from 'react';

const renderLogo = (provider: string, useQuoteBarSize: boolean) => {
    switch (provider) {
        case 'SS':
            return useQuoteBarSize ? (
                <img
                    alt='Fortress Logo'
                    src='/img/avatars/fortress.png'
                    width='40px'
                    height='40px'
                />
            ) : (
                <img
                    alt='Fortress Logo'
                    src='/img/LeftCroppedLogos/fortress-logo.png'
                    width='71px'
                    height='45px'
                />
            );
        case 'AGEAS':
            return useQuoteBarSize ? (
                <img alt='Ageas Logo' src='/img/avatars/ageas.png' width='40px' height='40px' />
            ) : (
                <img
                    alt='Ageas Logo'
                    src='/img/LeftCroppedLogos/ageas-logo.png'
                    width='71px'
                    height='45px'
                />
            );
        case 'AXA':
            return useQuoteBarSize ? (
                <img alt='AXA Logo' src='/img/avatars/axa.png' width='40px' height='40px' />
            ) : (
                <img
                    alt='AXA Logo'
                    src='/img/LeftCroppedLogos/axa-logo.png'
                    width='71px'
                    height='45px'
                />
            );
        case 'RSA':
            return useQuoteBarSize ? (
                <img alt='RSA Logo' src='/img/avatars/rsa.png' width='40px' height='40px' />
            ) : (
                <img
                    alt='RSA Logo'
                    src='/img/LeftCroppedLogos/rsa-logo.png'
                    width='71px'
                    height='45px'
                />
            );
        case 'LV':
            return useQuoteBarSize ? (
                <img
                    alt='LV Logo'
                    src='/img/avatars/lv-logo-avatar.png'
                    width='45px'
                    height='45px'
                />
            ) : (
                <img
                    alt='LV Logo'
                    src='/img/LeftCroppedLogos/lv-logo.png'
                    width='71px'
                    height='45px'
                />
            );
        case 'UKG':
            return useQuoteBarSize ? (
                <img
                    alt='UK General Logo'
                    src='/img/avatars/ukgeneral-logo-avatar.png'
                    width='45px'
                    height='45px'
                />
            ) : (
                <img
                    alt='UK General Logo'
                    src='/img/LeftCroppedLogos/ukgeneral-logo.png'
                    width='71px'
                    height='45px'
                />
            );
        case 'ZURICH':
            return useQuoteBarSize ? (
                <img
                    alt='Zurich Logo'
                    src='/img/avatars/zurich-logo-avatar.png'
                    width='45px'
                    height='45px'
                />
            ) : (
                <img
                    alt='Zurich Logo'
                    src='/img/LeftCroppedLogos/zurich-logo.png'
                    width='71px'
                    height='45px'
                />
            );
    }
};

type Props = {
    provider: string;
    useQuoteBarSize?: boolean;
};

const UnderwriterLogo = ({ provider, useQuoteBarSize: useQuoteBarSize = false }: Props) => {
    return <React.Fragment>{renderLogo(provider, useQuoteBarSize)}</React.Fragment>;
};

export default UnderwriterLogo;

UnderwriterLogo.defaultProps = {
    useQuoteBarSize: false,
};
