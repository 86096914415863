import {
    FormControl,
    FormLabel,
    Theme,
    useTheme,
    makeStyles,
    createStyles,
} from '@material-ui/core';
import CustomRadioGroup from '../Controls/CustomRadioGroup';
import React, { Fragment } from 'react';
import CustomRadio from '../Controls/CustomRadio';
import { CoverTypeOption } from './CoverTypeOptionEnum';
import HelpButton from '../Controls/HelpButton';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        labelRoot: {
            color: '#000000',
            fontFamily: 'Spartan MB SemiBold',
            fontSize: '1rem',
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        radioFormControl: {
            marginTop: '8px',
            marginBottom: '8px',
        },
        radioImage: {
            maxWidth: '100%',
        },
        helpIcon: {
            display: 'inline-block',
            paddingTop: '2px',
            right: 0,
            top: '-8px',
            position: 'absolute',
            '& svg': {
                height: '1.375rem',
                width: '1.375rem',
            },
        },
        radioFormLabel: {
            fontFamily: 'Spartan MB',
            [theme.breakpoints.down(374)]: {
                fontSize: '0.6875rem',
            },
            [theme.breakpoints.up(375)]: {
                fontSize: '0.875rem',
            },
            [theme.breakpoints.up(400)]: {
                fontSize: '0.9375rem',
            },
        },
        radioContent: {
            textAlign: 'center',
            [theme.breakpoints.down(374)]: {
                lineHeight: '1rem',
                marginLeft: '5px',
                marginRight: '5px',
                marginBottom: '12px',
            },
            [theme.breakpoints.up(375)]: {
                marginLeft: '8px',
                marginRight: '8px',
                marginBottom: '16px',
                marginTop: '16px',
            },
            [theme.breakpoints.up(400)]: {
                marginLeft: '11px',
                marginRight: '11px',
                marginBottom: '20px',
            },
            marginTop: '8px',
        },
    }),
);

interface Props {
    onSelected: (value: any) => void;
    value: CoverTypeOption;
}

const CoverTypeOptions = ({ onSelected, value }: Props) => {
    const classes = useStyles();

    const handleRadioChange = (selected: any) => {
        onSelected(selected);
    };

    const theme = useTheme();

    return (
        <FormControl
            data-testid="cover-type-options"
            className={classes.radioFormControl}
            component="fieldset"
            fullWidth
        >
            <FormLabel
                classes={{
                    root: classes.labelRoot,
                }}
                component="legend"
            >
                What type of cover do you want?
            </FormLabel>
            <HelpButton
                data-testid={'helpButton'}
                helpTitle={'Cover Type'}
                helpText={
                    <>
                        <p>
                            The maximum claim limit for buildings is £1&nbsp;million. If
                            £1&nbsp;million is not sufficient to cover the cost of rebuilding your
                            property if it was completely destroyed then the policy is not suitable
                            for your needs.
                        </p>
                        <p>
                            Contents are your household goods including furniture, furnishings (such
                            as curtains and carpets) and all the personal property in your home.
                        </p>
                        <p>
                            The standard maximum claim limit for contents is £75,000. If £75,000 is
                            not sufficient to replace all of your contents as new then the policy is
                            not suitable for your needs.
                        </p>
                    </>
                }
                className={classes.helpIcon}
            />
            <CustomRadioGroup onClick={handleRadioChange} value={value}>
                {theme.siteConfiguration.coverOptions.enableBuildingsCover &&
                theme.siteConfiguration.coverOptions.enableContentsCover ? (
                    <CustomRadio radioValue={CoverTypeOption.BuildingsAndContentsCover}>
                        <div className={classes.radioContent}>
                            <img
                                className={classes.radioImage}
                                src="/img/icons/BuildingsContents.png"
                            />
                            <span
                                data-testid="buildingsAndContents"
                                className={classes.radioFormLabel}
                            >
                                Buildings &amp;&nbsp;contents
                            </span>
                        </div>
                    </CustomRadio>
                ) : (
                    <Fragment />
                )}
                {theme.siteConfiguration.coverOptions.enableBuildingsCover ? (
                    <CustomRadio radioValue={CoverTypeOption.BuildingsOnlyCover}>
                        <div className={classes.radioContent}>
                            <img
                                className={classes.radioImage}
                                src="/img/icons/BuildingsOnly.png"
                            />
                            <span data-testid="buildingsOnly" className={classes.radioFormLabel}>
                                Buildings only
                            </span>
                        </div>
                    </CustomRadio>
                ) : (
                    <Fragment />
                )}
                {theme.siteConfiguration.coverOptions.enableContentsCover ? (
                    <CustomRadio radioValue={CoverTypeOption.ContentsOnlyCover}>
                        <div className={classes.radioContent}>
                            <img className={classes.radioImage} src="/img/icons/ContentsOnly.png" />
                            <span data-testid="contentsOnly" className={classes.radioFormLabel}>
                                Contents only
                            </span>
                        </div>
                    </CustomRadio>
                ) : (
                    <Fragment />
                )}
            </CustomRadioGroup>
        </FormControl>
    );
};

export default CoverTypeOptions;
