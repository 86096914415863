import { navigate } from '@reach/router';
import React, { useEffect } from 'react';
import { useStateValue } from '../../hooks/StateContext';
import { setContactDetails } from '../Applicant/ApplicantDetailActions';
import { ApplicantContactDetailsModel } from '../Applicant/ApplicantDetailsModel';
import SectionTitle from '../Controls/SectionTitle';
import CoverContactDetailsForm from '../CoverType/CoverContactDetailsForm';
import { setCoverType } from '../CoverType/CoverTypeActions';
import { CoverTypeOption } from '../CoverType/CoverTypeOptionEnum';
import CoverTypeOptions from '../CoverType/CoverTypeOptions';
import { refreshQuotes } from '../QuoteResults/QuoteResultsActions';
import { useTheme } from '@material-ui/core';
import BackButton from '../Controls/BackButton';
import ContinueButton from '../Controls/ContinueButton';

const CoverType = () => {
    const { state, dispatch } = useStateValue();
    const theme = useTheme();

    const isBuildingsEnabled = theme.siteConfiguration.coverOptions.enableBuildingsCover;
    const isContentsEnabled = theme.siteConfiguration.coverOptions.enableContentsCover;

    useEffect(() => {
        if (isBuildingsEnabled != isContentsEnabled) {
            if (isBuildingsEnabled) {
                dispatch(setCoverType(CoverTypeOption.BuildingsOnlyCover));
            } else {
                dispatch(setCoverType(CoverTypeOption.ContentsOnlyCover));
            }
        }
    }, []);

    const handleSubmit = async (values: ApplicantContactDetailsModel) => {
        dispatch(
            setContactDetails({
                TelephoneNumber: values.TelephoneNumber!,
                EmailAddress: values.EmailAddress!,
            }),
        );
        handleNextClick();
    };

    const handleNextClick = () => {
        dispatch(refreshQuotes());
        navigate('/direct/accidentaldamage');
    };

    const handleSelectedCoverType = (value: CoverTypeOption) => {
        dispatch(setCoverType(value));
    };

    return (
        <>
            <SectionTitle title="Your cover." />
            <CoverTypeOptions
                onSelected={handleSelectedCoverType}
                value={state.SelectedCoverType}
            />
            {!theme.siteConfiguration.showContactQuestionsBeforePremium ? (
                <CoverContactDetailsForm
                    TelephoneNumber={state.Client1.TelephoneNumber}
                    EmailAddress={state.Client1.EmailAddress}
                    SubmitHandler={handleSubmit}
                />
            ) : (
                <div style={{ display: 'flex' }}>
                    <BackButton testId="backButton">Back</BackButton>
                    <ContinueButton onClick={handleNextClick} testId="nextButton">
                        Next
                    </ContinueButton>
                </div>
            )}
        </>
    );
};

export default CoverType;
