import { Grid, Hidden, MenuItem } from "@material-ui/core";
import { Field, Form, Formik, FieldProps } from "formik";
import { navigate } from "gatsby";
import React from "react";
import { useStateValue } from "../../hooks/StateContext";
import BackButton from "../Controls/BackButton";
import ContinueButton from "../Controls/ContinueButton";
import MaterialSelectAndHelp from "../Controls/MaterialSelectAndHelp";
import SectionTitle from "../Controls/SectionTitle";
import SelectOverlay from "../Controls/SelectOverlay";
import { setBuildingsExcess, setContentsExcess, setEditingExcess } from "../Excess/ExcessActions";
import ExcessList from "../Excess/ExcessList";
import { ExcessTypeEnum } from "../Excess/ExcessTypeEnum";
import { refreshQuotes } from "../QuoteResults/QuoteResultsActions";

const contentsHelpText = <>
    <p>This is the amount you would be required to pay in the event of a claim on the contents element of your policy.</p>
    <p>In the event of a claim involving both buildings and contents, only the higher of the buildings or contents excess will be payable (not both). Please note that a minimum excess of £350 applies in the event of a claim relating to escape of water.</p>
</>;

const buildingsHelpText = <>
    <p>This is the amount you would be required to pay in the event of a claim on the buildings element of your policy.</p>
    <p>In the event of a claim involving both buildings and contents, only the higher of the buildings or contents excess will be payable (not both). Please note that minimum excesses apply to certain categories of claims.</p>
</>;


const ExcessForm = () => {
    const { state, dispatch } = useStateValue();

    const handleSave = (values: any) => {
        if (state.IncludeBuildings) {
            dispatch(setBuildingsExcess(values.BuildingsExcess));
        }
        if (state.IncludeContents) {
            dispatch(setContentsExcess(values.ContentsExcess));
        }
        dispatch(refreshQuotes());
        navigate("/direct/noclaimsdiscount");
    };

    return (
        <Formik
            initialValues={{
                BuildingsExcess: state.BuildingsExcessId,
                ContentsExcess: state.ContentsExcessId
            }}
            onSubmit={(values, { setSubmitting }) => {
                handleSave(values);
                setSubmitting(false);
            }}
        >{() =>
            <Form data-testid="excessPage" noValidate>
                {state.IncludeBuildings &&
                    <Field name="BuildingsExcess" id="buildingsExcess" inputProps={{
                        "data-testid": 'buildings-exess'
                    }}>
                        {({
                            field,
                            form,
                            meta,
                        }: FieldProps) => (
                                <MaterialSelectAndHelp
                                    data-testid="buildingsExcess"
                                    label="Buildings Excess"
                                    helpTitle={"Buildings Excess"}
                                    helpText={buildingsHelpText}
                                    field={field}
                                    form={form}
                                    meta={meta}
                                >
                                    <MenuItem value="1">£100</MenuItem>
                                    <MenuItem value="2">£150</MenuItem>
                                    <MenuItem value="3">£200</MenuItem>
                                    <MenuItem value="4">£250</MenuItem>
                                    <MenuItem value="5">£300</MenuItem>
                                    <MenuItem value="6">£350</MenuItem>
                                    <MenuItem value="7">£400</MenuItem>
                                    <MenuItem value="8">£450</MenuItem>
                                    <MenuItem value="9">£500</MenuItem>
                                </MaterialSelectAndHelp>
                            )}
                    </Field>
                }
                {state.IncludeContents &&
                    <Field name="ContentsExcess" id="contentsExcess" inputProps={{
                        "data-testid": 'contents-exess'
                    }}>
                        {({
                            field,
                            form,
                            meta,
                        }: FieldProps) => (
                                <MaterialSelectAndHelp
                                    data-testid="contentsExcess"
                                    label="Contents Excess"
                                    helpTitle={"Contents Excess"}
                                    helpText={contentsHelpText}
                                    field={field}
                                    form={form}
                                    meta={meta}
                                >
                                    <MenuItem value="1">£100</MenuItem>
                                    <MenuItem value="2">£150</MenuItem>
                                    <MenuItem value="3">£200</MenuItem>
                                    <MenuItem value="4">£250</MenuItem>
                                    <MenuItem value="5">£300</MenuItem>
                                    <MenuItem value="6">£350</MenuItem>
                                    <MenuItem value="7">£400</MenuItem>
                                    <MenuItem value="8">£450</MenuItem>
                                    <MenuItem value="9">£500</MenuItem>
                                </MaterialSelectAndHelp>
                            )}
                    </Field>
                }
                <div style={{ display: 'flex' }}>
                    <BackButton>Back</BackButton>
                    <ContinueButton testId="excessNext">Next</ContinueButton>
                </div>
            </Form>
            }
        </Formik>
    );
};

const VoluntaryExcess = () => {
    const { state, dispatch } = useStateValue();

    const handleSelectExcess = (excessTypeId: ExcessTypeEnum) => {
        dispatch(setEditingExcess(excessTypeId));
        navigate("/direct/voluntaryexcess/overlay/excess");
    };

    const handleContinue = () => {
        dispatch(refreshQuotes());
        navigate("/direct/noclaimsdiscount");
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <SectionTitle
                    title={"Excess."}
                    subHeading={"Please select the buildings and contents excess."}
                    useLighterSubHeading={true}
                />
                <Hidden only={'xs'}>
                    <ExcessForm />
                </Hidden>

                <Hidden smUp>
                    {state.IncludeBuildings &&
                        <SelectOverlay
                            testId="buildingsExcessHelp"
                            id="buildingsExcess"
                            boldLabel={true}
                            label="Buildings excess"
                            value={ExcessList[state.BuildingsExcessId - 1].Value}
                            helpTitle={"Buildings Excess"}
                            helpText={buildingsHelpText}
                            onClick={() => handleSelectExcess(ExcessTypeEnum.BuildingsExcess)}
                        />
                    }
                    {state.IncludeContents &&
                        <SelectOverlay
                            testId="contentsExcessHelp"
                            id="contentsExcess"
                            boldLabel={true}
                            label="Contents excess"
                            value={ExcessList[state.ContentsExcessId - 1].Value}
                            helpTitle={"Contents Excess"}
                            helpText={contentsHelpText}
                            onClick={() => handleSelectExcess(ExcessTypeEnum.ContentsExcess)}
                        />
                    }
                    <ContinueButton testId="excessNext"
                        onClick={handleContinue}
                        disabled={state.RefreshingQuote || state.QuoteError || Object.entries(state.SelectedQuote.PremiumData).length === 0}
                    >
                        Next
                    </ContinueButton>
                </Hidden>
            </Grid>
        </Grid>
    );
};

export default VoluntaryExcess;