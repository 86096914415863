import { Action } from "../../models/Action";
import { ActionType } from "../../models/ActionType";
import { ApplicantContactDetailsModel, ApplicantDetailsModel } from "./ApplicantDetailsModel";

export const addFirstApplicant = (value: ApplicantDetailsModel): Action => {
    return {
        type: ActionType.AddFirstApplicant,
        value
    };
};

export const setContactDetails = (value: ApplicantContactDetailsModel): Action => {
    return {
        type: ActionType.SetContactDetails,
        value,
    };
};

export const addSecondApplicant = (value: ApplicantDetailsModel): Action => {
    return {
        type: ActionType.AddSecondApplicant,
        value,
    };
};

export const removeSecondApplicant = (): Action => {
    return {
        type: ActionType.RemoveSecondApplicant,
        value: undefined
    };
};

export const setNoSecondApplicant = (): Action => {
    return {
        type: ActionType.SetIncludeSecondApplicant,
        value: false,
    };
};