import axios from "axios";
import { useEffect, useState } from "react";
import { setEligibilityQuestions } from "../components/Eligibility/EligibilityQuestionsActions";
import { useStateValue } from "./StateContext";

const getQuoteURL = `${process.env.FUNCTION_BASE_URL}api/GetEligibilityQuestions`;

const useEligibilityQuestionsAPI = () => {
    const { state, dispatch } = useStateValue();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        let cancel: any;
        async function getEligibilityQuestions() {
            try {
                setLoading(true);

                const postData = {
                    quoteReference: state.SelectedQuote!.QuoteReference,
                    premiumId: state.SelectedQuote.PremiumData.PremiumId,
                };

                const response = await axios.post(getQuoteURL, postData, {
                    cancelToken: new axios.CancelToken((c) => {
                        cancel = c;
                    })
                });

                if (response.status == 200) {
                    dispatch(setEligibilityQuestions(response.data));
                }

                setLoading(false);
                setError(false);

            } catch (error) {
                if (!axios.isCancel(error)) {
                    setLoading(false);
                    setError(true);
                }
            }
        }

        if (!state.RefreshingQuote) {
            getEligibilityQuestions();
        }

        return function cleanup() {
            if (cancel) {
                cancel();
            }
        };
    }, [state.RefreshingQuote]);

    return { loading, error };
};

export default useEligibilityQuestionsAPI;