import { AppBar, createStyles, Link, makeStyles, Theme, Toolbar, Typography } from "@material-ui/core";
import React from "react";
import Icon from "../Icon";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        overlay: {
            backgroundColor: '#ffffff',
            height: '100vh',
        },
        appbar: {
            backgroundColor: theme.verticalMargins.headerBackgroundColour,
            color: '#000000',
            '&.MuiPaper-elevation4': {
                borderBottom: '1px solid #e5e5e5',
                boxShadow: 'none'
            }
        },
        iconArrow: {
            fontSize: "1.125rem",
            fontWeight: "bold",
            color: "#b1b1b1",
        },
        styledLink: {
            fontFamily: "Spartan MB Bold",
            fontSize: "0.6875rem",
            '&:hover': {
                textDecoration: 'none',
            }
        },
        header: {
            fontFamily: 'Spartan MB Bold',
            fontSize: '1.25rem',
            color: theme.header.textColor,
            position: 'relative',
            left: '50%',
            transform: 'translateX(-50%)',
            marginLeft: '-18px',
        },
        overlayContent: {
            padding: theme.spacing(1, 2, 4, 2),
            marginTop: '56px',
        },
    }),
);

interface Props {
    title: string;
    children: JSX.Element | JSX.Element[];
};

const Overlay = ({ title, children }: Props) => {
    const classes = useStyles();

    const handleClick = (event: React.SyntheticEvent) => {
        event.preventDefault();
        history.back();
    };

    return (
        <div className={classes.overlay}>
            <AppBar position="fixed" className={classes.appbar}>
                <Toolbar>
                    <Link onClick={handleClick} data-testid="back-button" className={classes.styledLink}>
                        <Icon icon="Arrow" cssProperties={classes.iconArrow} />
                    </Link>
                    <Typography variant="h3" className={classes.header}>
                        {title}
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className={classes.overlayContent}>
                {children}
            </div>
        </div>
    );
};

export default Overlay;