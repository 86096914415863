import axios from 'axios';
import { useEffect, useState } from 'react';
import { PaymentDetailsModel } from '../components/Payment/PaymentDetailsModel';
import { SelectPremiumModel } from '../components/QuoteResults/SelectPremiumModel';
import { getDateFromString } from '../components/utils';

export type ApplyProps = {
    SelectedQuote: SelectPremiumModel;
    Apply: PaymentDetailsModel;
    AgreeDeclarations: boolean;
};

const useApply = ({ SelectedQuote, Apply, AgreeDeclarations }: ApplyProps) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    useEffect(() => {
        async function postData() {
            try {
                setLoading(true);
                const url = `${process.env.FUNCTION_BASE_URL}api/apply`;

                const hasClient2 =
                    Apply.Client2?.Title != '' &&
                    Apply.Client2?.Forenames != '' &&
                    Apply.Client2?.Surname != '' &&
                    Apply.Client2?.DateOfBirth != '';

                let postData = {
                    SelectedQuote,
                    Apply: {
                        ApplyDirect: Apply.ApplyDirect,
                        Bank: {
                            ...Apply.Bank,
                        },
                        ...(hasClient2 && {
                            Client2: {
                                Title: Apply.Client2!.Title,
                                Forenames: Apply.Client2!.Forenames,
                                Surname: Apply.Client2!.Surname,
                                DateOfBirth: getDateFromString(Apply.Client2!.DateOfBirth)! as any,
                            },
                        }),
                        HasStartDateWithin60Days: Apply.HasStartDateWithin60Days,
                        MortgageProvider: Apply.MortgageProvider,
                        MortgageRollNumber: Apply.MortgageRollNumber,
                        PaidYearly: Apply.PaidYearly,
                        Payment: Apply.Payment,
                        PolicyStartDate: getDateFromString(Apply.PolicyStartDate),
                        ReceiveInsuranceDocumentsEmail: Apply.ReceiveInsuranceDocumentsEmail,
                        SolicitorEmailAddress: Apply.SolicitorEmailAddress,
                        ThirdPartyReference: Apply.ThirdPartyReference,
                    },
                    AgreeDeclarations,
                };

                const response = await axios.post(url, postData, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                return await response;
            } catch (error) {
                if (error.message != 'Operation canceled by the user.') {
                    setLoading(false);
                }
                setError(true);
            } finally {
                setLoading(false);
            }
        }

        postData();

        return function cleanup() {
            source.cancel('Operation canceled by the user.');
            setLoading(false);
        };
    }, []);

    return { loading, error };
};

export default useApply;
