export enum Include {
    No,
    Yes,
    Optional,
};

export const ListOfPersonalPossessionsValues = [
    { Id: "", Value: "£0" },
    { Id: "2000", Value: "2000" },
    { Id: "3000", Value: "3000" },
    { Id: "4000", Value: "4000" },
    { Id: "5000", Value: "5000" },
    { Id: "6000", Value: "6000" },
    { Id: "7000", Value: "7000" },
    { Id: "8000", Value: "8000" },
    { Id: "9000", Value: "9000" },
    { Id: "10000", Value: "10000" },
];

export interface HighValueItems {
    Id: string;
    Value: string;
    AllowedAtHome: Include;
    AllowedAwayFromHome: Include;
};

export const ListOfHighValueItems: HighValueItems[] = [
    { Id: "Clocks", Value: "Clocks", AllowedAtHome: Include.Yes, AllowedAwayFromHome: Include.No },
    { Id: "Clothing", Value: "Clothing", AllowedAtHome: Include.No, AllowedAwayFromHome: Include.Yes },
    { Id: "CoinCollection", Value: "Coin Collection", AllowedAtHome: Include.Yes, AllowedAwayFromHome: Include.No },
    { Id: "ContactLenses", Value: "Contact Lenses", AllowedAtHome: Include.No, AllowedAwayFromHome: Include.Yes },
    { Id: "CupsShieldsTrophiesandMasonicRegalia", Value: "Cups, Shields, Trophies, Masonic Regalia", AllowedAtHome: Include.Yes, AllowedAwayFromHome: Include.No },
    { Id: "Curios", Value: "Curios", AllowedAtHome: Include.Yes, AllowedAwayFromHome: Include.No },
    { Id: "DenturesincludingCrowns", Value: "Dentures (incl crowns)", AllowedAtHome: Include.No, AllowedAwayFromHome: Include.Yes },
    { Id: "ElectricWheelchairs", Value: "Electric Wheelchairs", AllowedAtHome: Include.No, AllowedAwayFromHome: Include.Yes },
    { Id: "Furs", Value: "Furs", AllowedAtHome: Include.Optional, AllowedAwayFromHome: Include.Optional },
    { Id: "Gokart", Value: "Go - Karts", AllowedAtHome: Include.No, AllowedAwayFromHome: Include.Yes },
    { Id: "GoldItems", Value: "Gold Items", AllowedAtHome: Include.Optional, AllowedAwayFromHome: Include.Optional },
    { Id: "GolfBuggy", Value: "Golf Buggy", AllowedAtHome: Include.No, AllowedAwayFromHome: Include.Yes },
    { Id: "Guns", Value: "Guns", AllowedAtHome: Include.Yes, AllowedAwayFromHome: Include.No },
    { Id: "HearingAids", Value: "Hearing Aids", AllowedAtHome: Include.No, AllowedAwayFromHome: Include.Yes },
    { Id: "HomeComputerEquipmentegLaptop", Value: "Home computer Equipment - e.g. laptop", AllowedAtHome: Include.No, AllowedAwayFromHome: Include.Yes },
    { Id: "JewelleryWatches", Value: "Jewellery / Watches", AllowedAtHome: Include.Optional, AllowedAwayFromHome: Include.Optional },
    { Id: "MedalCollection", Value: "Medal Collection", AllowedAtHome: Include.Yes, AllowedAwayFromHome: Include.No },
    { Id: "MedicalEquipment", Value: "Medical Equipment", AllowedAtHome: Include.No, AllowedAwayFromHome: Include.Yes },
    { Id: "ModelAircraft", Value: "Model Aircraft", AllowedAtHome: Include.No, AllowedAwayFromHome: Include.Yes },
    { Id: "MusicalInstrumentsAmateur", Value: "Musical Instruments - Amateur", AllowedAtHome: Include.Optional, AllowedAwayFromHome: Include.Optional },
    { Id: "Paintings", Value: "Paintings", AllowedAtHome: Include.Yes, AllowedAwayFromHome: Include.No },
    { Id: "Pearls", Value: "Pearls", AllowedAtHome: Include.Yes, AllowedAwayFromHome: Include.Optional },
    { Id: "PhotographicEquipmentAmateur", Value: "Photo Equipment - Amateur", AllowedAtHome: Include.Optional, AllowedAwayFromHome: Include.Optional },
    { Id: "Pianos", Value: "Pianos", AllowedAtHome: Include.Yes, AllowedAwayFromHome: Include.No },
    { Id: "Pictures", Value: "Pictures", AllowedAtHome: Include.Yes, AllowedAwayFromHome: Include.No },
    { Id: "PortableElectronicItemegiPodiPad", Value: "Portable Electonic Equipment e.g. Ipad", AllowedAtHome: Include.No, AllowedAwayFromHome: Include.Yes },
    { Id: "PramsAndPushchairs", Value: "Prams and Pushchairs", AllowedAtHome: Include.No, AllowedAwayFromHome: Include.Yes },
    { Id: "PreciousMetals", Value: "Precious Metals", AllowedAtHome: Include.Optional, AllowedAwayFromHome: Include.Optional },
    { Id: "Sculptures", Value: "Sculptures", AllowedAtHome: Include.Yes, AllowedAwayFromHome: Include.No },
    { Id: "SilverItems", Value: "Silver Items", AllowedAtHome: Include.Yes, AllowedAwayFromHome: Include.No },
    { Id: "SoundEquipment", Value: "Sound Equipment", AllowedAtHome: Include.No, AllowedAwayFromHome: Include.Yes },
    { Id: "Spectacles", Value: "Spectacles", AllowedAtHome: Include.No, AllowedAwayFromHome: Include.Yes },
    { Id: "SportingGunsandShootingEquipment", Value: "Sporting Guns and Shooting Equipment", AllowedAtHome: Include.Yes, AllowedAwayFromHome: Include.Optional },
    { Id: "SportsEquipmentExcludingWinterSportsandPedalCycles", Value: "Sports Equipment - excluding winter sports and cycles", AllowedAtHome: Include.No, AllowedAwayFromHome: Include.Yes },
    { Id: "SportsEquipmentWinterSports", Value: "Sports Equipment - winter sports", AllowedAtHome: Include.No, AllowedAwayFromHome: Include.Yes },
    { Id: "StampCollection", Value: "Stamp Collection", AllowedAtHome: Include.Yes, AllowedAwayFromHome: Include.No },
    { Id: "Statues", Value: "Statues", AllowedAtHome: Include.Yes, AllowedAwayFromHome: Include.No },
    { Id: "Tapestries", Value: "Tapestries", AllowedAtHome: Include.Yes, AllowedAwayFromHome: Include.No },
    { Id: "WheelchairsSelfPropelled", Value: "Wheelchairs - self propelled", AllowedAtHome: Include.No, AllowedAwayFromHome: Include.Yes },
    { Id: "WorksofArt", Value: "Works of Art", AllowedAtHome: Include.Yes, AllowedAwayFromHome: Include.No },
];