import { Field } from "formik";
import React from "react";
import MaterialTextField from "../Controls/MaterialTextField";

const AddressDetails = () => {
    return (
        <React.Fragment>
            <Field name="AddressLine1"
                label="Address Line 1"
                id="address-line-1"
                component={MaterialTextField}
                inputProps={{
                    maxLength: 50
                }}
            />
            <Field name="AddressLine2"
                label="Address Line 2"
                id="address-line-2"
                component={MaterialTextField}
                inputProps={{
                    maxLength: 50
                }}
            />
            <Field name="AddressLine3"
                label="Address Line 3"
                id="address-line-3"
                component={MaterialTextField}
                inputProps={{
                    maxLength: 50
                }}
            />
            <Field name="AddressLine4"
                label="Address Line 4"
                id="address-line-4"
                component={MaterialTextField}
                inputProps={{
                    maxLength: 50
                }}
            />
            <Field name="Postcode"
                label="Postcode"
                id="postcode"
                component={MaterialTextField}
                placeholder="eg: SK7345"
                inputProps={{
                    maxLength: 50
                }}
            />
        </React.Fragment>
    );
};

export default AddressDetails;