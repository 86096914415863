import { navigate } from "@reach/router";
import React, { useState } from "react";
import { useStateValue } from "../../hooks/StateContext";
import usePropertyDetails from "../../hooks/usePropertyDetails";
import { setAllAddressDetails } from "../Address/AddressDetailActions";
import { AllAddressDetails } from "../Address/AddressDetailsModel";
import AddressEditModal from "../Address/AddressEditModal";
import AddressSummary from "../Address/AddressSummary";
import Loading from "../Controls/Loading";
import SectionTitle from "../Controls/SectionTitle";
import { setAllPropertyDetails } from "../Property/PropertyDetailActions";
import { PropertyDetailsModel } from "../Property/PropertyDetailsModel";
import PropertyForm from "../Property/PropertyForm";
import useWindowSize from "../../hooks/useWindowSize";

const Property = () => {
    const { state, dispatch } = useStateValue();
    const [open, setOpen] = React.useState(false);
    const [loading] = usePropertyDetails(state.RiskAddress.AddressLine1, state.RiskAddress.Postcode);
    const [finishedLoading, setFinishedLoading] = useState<boolean>(!state.ReloadAddress);
    const { isMobile } = useWindowSize();

    const handleClick = () => {
        if (isMobile) {
            navigate('/direct/details/overlay/manualaddress');
        } else {
            setOpen(true);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = (values: any) => {
        handleClose();

        const addressDetails: AllAddressDetails = {
            RiskAddress: values,
            CorrespondenceAddress: values,
        };

        dispatch(
            setAllAddressDetails(addressDetails)
        );
    };

    const handleSubmit = async (values: PropertyDetailsModel) => {
        dispatch(
            setAllPropertyDetails(values)
        );

        navigate('/direct/quote');
    };

    const loadingStages = ["Uploading your details", "Finding your home", "Checking property details", "Loading your details"];
    if (!finishedLoading) return <Loading stages={loadingStages} interval={400} isLoading={loading} setFinishedLoading={setFinishedLoading} />
    return (
        <React.Fragment>
            {finishedLoading &&
                <>
                    <SectionTitle title="Success!" subHeading="Please confirm your property details." />
                    <AddressSummary address={state.RiskAddress} onClick={handleClick} />
                    <PropertyForm
                        NumberOfBedrooms={state.NumberOfBedrooms}
                        PropertyTypeId={state.PropertyTypeId}
                        YearBuiltRange={state.YearBuiltRange}
                        SubmitHandler={handleSubmit}
                    />
                    <AddressEditModal
                        onCancel={handleClose}
                        onSave={handleSave}
                        open={open}
                        address={state.RiskAddress}
                    />
                </>
            }
        </React.Fragment>
    );
};

export default Property;