import { Grid } from "@material-ui/core";
import { navigate } from "@reach/router";
import React, { useState } from "react";
import { useStateValue } from "../../hooks/StateContext";
import useIllustration from "../../hooks/useIllustration";
import ApiError from "../ApiError";
import Loading from "../Controls/Loading";
import SectionTitle from "../Controls/SectionTitle";
import { setPolicyStartDate } from "../PolicyInformation/PolicyInformationActions";
import PolicyInformationForm from "../PolicyInformation/PolicyInformationForm";
import { PolicyFormModel } from "../PolicyInformation/PolicyInformationModel";

const PolicyInformation = () => {
    const { state, dispatch } = useStateValue();
    const [finishedLoading, setFinishedLoading] = useState(state.IsIllustrationComplete);
    const { loading, error } = useIllustration({ SelectedQuote: state.SelectedQuote, AgreeDeclarations: state.AgreeDeclarations });

    const handleSubmit = async (values: PolicyFormModel) => {
        dispatch(setPolicyStartDate(values.PolicyStartDate!));
        navigate('/direct/payment');
    };

    const loadingStages = ["Finalising your quote"];
    if (!finishedLoading) return <Loading stages={loadingStages} interval={1500} isLoading={loading} setFinishedLoading={setFinishedLoading} />
    return (
        <React.Fragment>
            {
                error ?
                    <ApiError />
                    :
                    <Grid container>
                        <Grid item xs={12}>
                            <SectionTitle
                                title={"Policy information."}
                            />
                            <PolicyInformationForm PolicyStartDate={state.Apply.PolicyStartDate} SubmitHandler={handleSubmit} />
                        </Grid>
                    </Grid>
            }
        </React.Fragment>
    );
};

export default PolicyInformation;