import { Action } from "../../models/Action";
import { ActionType } from "../../models/ActionType";

export const setPolicyDocumentsByEmail = (value: boolean): Action => {
    return {
        type: ActionType.SetPolicyDocumentsByEmail,
        value,
    };
};

export const setPolicyStartDate = (value: string): Action => {
    return {
        type: ActionType.SetPolicyStartDate,
        value,
    };
};

export const setIllustrationComplete = (): Action => {
    return {
        type: ActionType.SetIllustrationComplete,
        value: true,
    };
};