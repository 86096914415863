import axios from "axios";
import { useState, useEffect } from "react";

const useBankAPI = (accountNumber: string, sortCode: string) => {
    const [results, setResults] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        async function fetchData() {
            try {
                setLoading(true);
                const url =
                    'https://api.addressy.com/BankAccountValidation/Interactive/Validate/v2.00/json3.ws' +
                    '?Key=' + encodeURIComponent('TH48-AB78-RT24-UN29') +
                    '&AccountNumber=' + encodeURIComponent(accountNumber) +
                    '&SortCode=' + encodeURIComponent(sortCode);

                const response = await axios.post(url, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                });

                const json = await response.data;
                setResults(json.Items[0]);
            } catch {
                setError(true);
            } finally {
                setLoading(false);
            }
        }

        if (accountNumber !== '' && accountNumber.length == 8 && sortCode !== '' && sortCode.length == 6) {
            fetchData();
        }
    }, [accountNumber, sortCode]);

    return { results, loading, error };
};

export default useBankAPI;