import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import React from "react";
import MaterialButton from './MaterialButton';
import { GridJustification } from '@material-ui/core/Grid';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            width: "100%",
            padding: "4px 0",
        },
        questionText: {
            fontSize: '1rem',
            fontFamily: 'Spartan MB SemiBold',
            lineHeight: '1.25rem',
            marginBottom: '14px',
        },
        container: {
            flexWrap: "wrap",
        },
        itemLeft: {
            margin: "0 30px 16px 0",
            flexGrow: 1,
            maxWidth: "200px",
        },
        itemRight: {
            flexGrow: 1,
            maxWidth: "200px",
        },
        additionalInformationList: {
            listStyle: 'none',
            paddingInlineStart: '0px',
            marginBlockStart: '2px',
            marginBlockEnd: '2px',
            '& li': {
                marginBottom: '0px',
            },
            '& li:last-child': {
                marginBottom: '1.25rem',
            },
        },
        additionalInformationText: {
            fontSize: '0.8125rem',
            fontFamily: 'Spartan MB',
            lineHeight: '1.6875rem',
            display: 'inline',
        },
        additionalInformationDiv: {
            display: 'flex',
            alignItems: 'center',
        },
        tick: {
            color: theme.palette.primary.main,
            fontSize: '1.25rem',
            marginRight: '0.5rem',
        },
    })
);

interface Props {
    questionText: string;
    onClick: (answer: boolean) => void;
    noText?: string;
    yesText?: string;
    initialAnswer?: boolean | undefined;
    additionalInformation?: string[];
    justifyButtons?: GridJustification;
    testId?: string;
};

const YesNoQuestion = ({ questionText, onClick, noText, yesText, initialAnswer, additionalInformation, justifyButtons, testId }: Props) => {
    const classes = useStyles();
    const [answer, setAnswer] = React.useState<boolean | undefined>(initialAnswer);

    const handleAnswer = (newAnswer: boolean) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        if (answer != newAnswer) {
            setAnswer(newAnswer);
            onClick(newAnswer);
        }
    };

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Typography data-testid={testId || "questionText"} className={classes.questionText}>{questionText}</Typography>
                    {additionalInformation &&
                        <ul className={classes.additionalInformationList}>
                            {additionalInformation.map((a: any) => (
                                <li key={a}>
                                    <div className={classes.additionalInformationDiv}>
                                        <CheckIcon className={classes.tick} data-testid="additional-information-tick" />
                                        <Typography className={classes.additionalInformationText}>{a}</Typography>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    }
                </Grid>
            </Grid>
            <Grid container justify={justifyButtons || "flex-start"} className={classes.container}>
                <Grid item className={classes.itemLeft}>
                    <MaterialButton className={classes.button} testId="button-no" selected={answer !== undefined && !answer} onClick={handleAnswer(false)}>
                        {noText && noText !== "" ? noText : "No"}
                    </MaterialButton>
                </Grid>
                <Grid item className={classes.itemRight}>
                    <MaterialButton className={classes.button} testId="button-yes" selected={answer} onClick={handleAnswer(true)}>
                        {yesText && yesText !== "" ? yesText : "Yes"}
                    </MaterialButton>
                </Grid>
            </Grid>
        </>
    );
};

export default YesNoQuestion;