import { Grid, Hidden, MenuItem } from "@material-ui/core";
import { navigate } from "@reach/router";
import { Field, FieldProps, Form, Formik } from "formik";
import React from "react";
import { useStateValue } from "../../hooks/StateContext";
import BackButton from "../Controls/BackButton";
import ContinueButton from "../Controls/ContinueButton";
import MaterialSelectAndHelp from "../Controls/MaterialSelectAndHelp";
import SectionTitle from "../Controls/SectionTitle";
import SelectOverlay from "../Controls/SelectOverlay";
import { setBuildingsNCD, setContentsNCD, setEditingNCD } from "../NoClaimsDiscount/NoClaimsDiscountActions";
import { NoClaimsDiscountTypeEnum } from "../NoClaimsDiscount/NoClaimsDiscountTypeEnum";
import YearsList from "../NoClaimsDiscount/YearsList";
import { refreshQuotes } from "../QuoteResults/QuoteResultsActions";

const NoClaimsDiscountForm = () => {
    const { state, dispatch } = useStateValue();

    const handleSave = (values: any) => {
        if (state.IncludeBuildings) {
            dispatch(setBuildingsNCD(values.BuildingsNCD));
        }
        if (state.IncludeContents) {
            dispatch(setContentsNCD(values.ContentsNCD));
        }
        dispatch(refreshQuotes());
        navigate("/direct/additionalcover");
    };

    return (
        <Formik
            initialValues={{
                BuildingsNCD: state.BuildingsNoClaimsDiscountId,
                ContentsNCD: state.ContentsNoClaimsDiscountId
            }}
            onSubmit={(values, { setSubmitting }) => {
                handleSave(values);
                setSubmitting(false);
            }}
        >{() =>
            <Form noValidate>
                {state.IncludeBuildings &&
                    <Field
                        name="BuildingsNCD"
                        id="buildingsNCD"
                        inputProps={{
                            "data-testid": 'buildings-ncd'
                        }}>
                        {({
                            field,
                            form,
                            meta,
                        }: FieldProps) => (
                                <MaterialSelectAndHelp
                                    data-testid="buildingsNoClaims"
                                    label="Buildings no claims"
                                    field={field}
                                    form={form}
                                    meta={meta}
                                >
                                    <MenuItem value="1">None</MenuItem>
                                    <MenuItem value="2">Not purchased before</MenuItem>
                                    <MenuItem value="3">1 year</MenuItem>
                                    <MenuItem value="4">2 years</MenuItem>
                                    <MenuItem value="5">3 years</MenuItem>
                                    <MenuItem value="6">4 years</MenuItem>
                                    <MenuItem value="7">5 years</MenuItem>
                                    <MenuItem value="8">More than 5 years</MenuItem>
                                </MaterialSelectAndHelp>
                            )}
                    </Field>
                }
                {state.IncludeContents &&
                    <Field
                        name="ContentsNCD"
                        id="contentsNCD"
                        inputProps={{
                            "data-testid": 'contents-ncd'
                        }}>
                        {({
                            field,
                            form,
                            meta,
                        }: FieldProps) => (
                                <MaterialSelectAndHelp
                                    data-testid="contentsNoClaims"
                                    label="Contents no claims"
                                    field={field}
                                    form={form}
                                    meta={meta}
                                >
                                    <MenuItem value="1">None</MenuItem>
                                    <MenuItem value="2">Not purchased before</MenuItem>
                                    <MenuItem value="3">1 year</MenuItem>
                                    <MenuItem value="4">2 years</MenuItem>
                                    <MenuItem value="5">3 years</MenuItem>
                                    <MenuItem value="6">4 years</MenuItem>
                                    <MenuItem value="7">5 years</MenuItem>
                                    <MenuItem value="8">More than 5 years</MenuItem>
                                </MaterialSelectAndHelp>
                            )}
                    </Field>
                }
                <div style={{ display: 'flex' }}>
                    <BackButton>Back</BackButton>
                    <ContinueButton testId="ncdNext">Next</ContinueButton>
                </div>
            </Form>
            }
        </Formik>
    );
};


const NoClaimsDiscount = () => {
    const { state, dispatch } = useStateValue();

    const handleSelectNCD = (noClaimsDiscountType: NoClaimsDiscountTypeEnum) => {
        dispatch(setEditingNCD(noClaimsDiscountType));
        navigate("/direct/noclaimsdiscount/overlay/noclaims");
    };

    const handleContinue = () => {
        dispatch(refreshQuotes());
        navigate("/direct/additionalcover");
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <SectionTitle
                    title={"No claims discount."}
                    subHeading={"How many consecutive years have you held insurance without claiming?"}
                    useLighterSubHeading={true}
                />

                <Hidden only={'xs'}>
                    <NoClaimsDiscountForm />
                </Hidden>

                <Hidden smUp>
                    {state.IncludeBuildings &&
                        <SelectOverlay
                            id="buildingsNCD"
                            testId="buildingsNCDHelp"
                            boldLabel={true}
                            label="Buildings no claims"
                            value={YearsList[state.BuildingsNoClaimsDiscountId - 1].Value}
                            onClick={() => handleSelectNCD(NoClaimsDiscountTypeEnum.BuildingsNCD)}
                        />
                    }
                    {state.IncludeContents &&
                        <SelectOverlay
                            id="contentsNCD"
                            testId="contentsNCDHelp"
                            boldLabel={true}
                            label="Contents no claims"
                            value={YearsList[state.ContentsNoClaimsDiscountId - 1].Value}
                            onClick={() => handleSelectNCD(NoClaimsDiscountTypeEnum.ContentsNCD)}
                        />
                    }
                    <ContinueButton testId="ncdNext"
                        onClick={handleContinue}
                        disabled={state.RefreshingQuote || state.QuoteError || Object.entries(state.SelectedQuote.PremiumData).length === 0}
                    >
                        Next
                    </ContinueButton>
                </Hidden>
            </Grid>
        </Grid>
    );
};

export default NoClaimsDiscount;