import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { useFormikContext } from "formik";
import React, { MouseEvent, useState } from 'react';
import { useStateValue } from "../../hooks/StateContext";
import useDocument from "../../hooks/useDocument";
import MaterialButton from "./MaterialButton";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        monthlyDirectDebitText: {
            fontSize: '0.8125rem',
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1, 0),
        },
        monthlyDirectDebitButtonDiv: {
            textAlign: 'center',
        },
        monthlyDirectDebitButton: {
            fontSize: '0.8125rem',
            margin: theme.spacing(2, 0, 0),
        },
    }),
);

type Props = {
    monthlyPrice: number;
};

const MonthlyDirectDebitDisplay = ({ monthlyPrice }: Props) => {
    const { values } = useFormikContext();
    const { state } = useStateValue();
    const classes = useStyles();
    const [documentType, setDocumentType] = useState("");
    useDocument({ QuoteReference: state.SelectedQuote.QuoteReference, Client1: state.Client1, RiskAddress: state.RiskAddress, DocumentType: documentType });

    const handleClick = (documentType: string) => (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setDocumentType(documentType);
    };

    return (
        <div>
            {
                values.Payment === "Monthly" &&
                <React.Fragment>
                    <Typography id="monthlyPayments" className={classes.monthlyDirectDebitText}>
                        If you choose the monthly option your policy will be subject to a 12 monthly instalment plan
                        which is subject to a charge for credit at a flat rate of 12% (APR 21.41%).
                        This is provided by Close Brothers Limited, trading as Close Brothers Premium Finance,
                        and involves a credit agreement directly with them.
                        If you choose this option you will make 12 payments of £{monthlyPrice},
                        and if payments are made on time and in accordance with your agreement,
                        you will repay a total of £{(monthlyPrice * 12).toFixed(2)} comprising of the amount you borrowed and the credit charge.
                    </Typography>
                    <div className={classes.monthlyDirectDebitButtonDiv}>
                        <MaterialButton testId="costInfo" className={classes.monthlyDirectDebitButton} onClick={handleClick('premiumfinance')}>
                            Click here for more information
                        </MaterialButton>
                    </div>
                </React.Fragment>
            }
        </div>
    );
};

export default MonthlyDirectDebitDisplay;