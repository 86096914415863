import * as Yup from "yup";
import { getDateFromString } from "../utils";

export const PolicyInformationValidationSchema =
    Yup.object({
        PolicyStartDate: Yup.string()
            .required("Policy start date is required")
            .test("test-minimum-date", "Policy start date must not be in the past", function (value) {
                const dateValue = getDateFromString(value);
                if (dateValue) {
                    const today = new Date().setHours(0, 0, 0, 0);
                    return dateValue.getTime() >= today;
                }
                return false;
            })
            .test("test-maximum-date", "Policy start date must be no more than 60 days in the future", function (value) {
                const dateValue = getDateFromString(value);
                if (dateValue) {
                    const sixtyDaysInFuture = new Date().setDate(new Date().getDate() + (60));
                    return dateValue.getTime() <= sixtyDaysInFuture;
                }
                return false;
            }),
    });

export interface PolicyFormModel {
    PolicyStartDate: string;
};