import { createStyles, makeStyles, Theme, Button } from "@material-ui/core";
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            fontFamily: "Spartan MB Bold",
            border: "1px solid #c2c3c3",
            width: '100%',
            height: '50px',
            padding: theme.spacing(1, 0),
            margin: theme.spacing(2, 0),
            backgroundColor: '#fff',
            '&:hover': {
                backgroundColor: '#fff',
            },
            textTransform: 'none',
        },
    })
);


type Props = {
    children: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    testId?: string;
};

const CancelButton = ({ children, onClick, testId }: Props) => {
    const classes = useStyles();

    return (
        <Button
            data-testid={testId || 'material-button'}
            className={classes.button}
            onClick={onClick}
            type="button"
            color="secondary"
        >
            {children}
        </Button>
    );
};

export default CancelButton;