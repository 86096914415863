import { createStyles, makeStyles, Theme, Button } from "@material-ui/core";
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            fontFamily: "Spartan MB SemiBold",
            border: `1px solid ${theme.palette.error.main}`,
            width: '100%',
            height: '50px',
            padding: theme.spacing(1, 0),
            margin: theme.spacing(2, 0),
            backgroundColor: '#fff',
            color: theme.palette.error.main,
            '&:hover': {
                backgroundColor: '#fff',
            },
            textTransform: 'none',
        },
    })
);

type Props = {
    children: string;
    testId?: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const RemoveButton = ({ children, testId, onClick }: Props) => {
    const classes = useStyles();

    return (
        <Button
            className={classes.button}
            data-testid={testId || 'remove-button'}
            onClick={onClick}
            type="button"

        >
            {children}
        </Button>
    );
};

export default RemoveButton;