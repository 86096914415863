import { Field } from 'formik';
import React from 'react';
import MaterialTextField from '../Controls/MaterialTextField';

const ApplicantContactDetails = () => {
    return (
        <React.Fragment>
            <Field
                id="emailAddress"
                name={`EmailAddress`}
                label="Email Address"
                placeholder="example@email.com"
                component={MaterialTextField}
                inputProps={{
                    maxLength: 255,
                }}
            />
            <Field
                id="telephoneNumber"
                name={`TelephoneNumber`}
                label="Telephone Number"
                placeholder="07"
                component={MaterialTextField}
                inputProps={{
                    maxLength: 20,
                }}
            />
        </React.Fragment>
    );
};

export default ApplicantContactDetails;
