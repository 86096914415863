import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { PossessionsModel } from "../Possessions/PossessionsModel";
import Card from "./Card";
import MaterialButton from "./MaterialButton";
import YesNoQuestion from "./YesNoQuestion";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        possessionsListContent: {
            padding: theme.spacing(1.5, 2, 1.5, 2),
        },
        possessionsListContentItem: {
            padding: theme.spacing(0.5, 0),
            display: 'flex',
            '&:not(:last-child)': {
                borderBottom: '1px solid #e5e5e5',
            }
        },
        possessionsListContentTitle: {
            fontFamily: "Spartan MB SemiBold",
            fontSize: '1rem',
            paddingBottom: '4px',
            marginRight: theme.spacing(0.5),
        },
        possessionsListContentValue: {
            fontFamily: "Spartan MB",
            fontSize: '1rem',
            flexGrow: 1,
        },
        possessionsListChangeButton: {
            fontFamily: "Spartan MB SemiBold",
            float: 'right',
            fontSize: '0.75rem',
            padding: '3px 8px',
            backgroundColor: 'transparent',
            borderWidth: '2px',
        },
    })
);

type Props = {
    title: string;
    icon: JSX.Element;
    helpText: string | JSX.Element;
    helpTitle: string;
    noPossessionsText: string;
    personalPossessionsValue: string;
    addChangeHandle: (editItem?: PossessionsModel) => void;
};

const PersonalPossessionsSingleItem = ({
    title,
    icon,
    helpText,
    helpTitle,
    noPossessionsText,
    personalPossessionsValue,
    addChangeHandle,
}: Props) => {
    const classes = useStyles();

    const handleYesNo = (answer: boolean) => {
        if (answer)
            addChangeHandle();
    };

    return (
        <Card
            title={title}
            icon={icon}
            helpText={helpText}
            helpTitle={helpTitle}
            addHeaderSeparator={true}
            testId={"personalPossessions"}
        >
            <div className={classes.possessionsListContent}>
                {
                    personalPossessionsValue === "" ?
                        <React.Fragment>
                            <YesNoQuestion

                                questionText={noPossessionsText}
                                onClick={handleYesNo}
                                initialAnswer={false}
                            />
                        </React.Fragment>
                        :
                        <React.Fragment>
                            {
                                <div key={`possession-personal`} className={classes.possessionsListContentItem}>
                                    <Typography className={classes.possessionsListContentTitle}>Up To</Typography>
                                    <Typography id="personal-cover-value" className={classes.possessionsListContentValue}>£{personalPossessionsValue}</Typography>
                                    <MaterialButton testId="change-personal-cover" className={classes.possessionsListChangeButton} onClick={() => addChangeHandle()}>Change</MaterialButton>
                                </div>
                            }
                        </React.Fragment>
                }
            </div>
        </Card>
    );
};

export default PersonalPossessionsSingleItem;