import axios from "axios";
import { useEffect, useState } from "react";

/*
    https://api.addressy.com/Capture/Interactive/Find/v1.1/json3.ws?
    Key=TH48-AB78-RT24-UN29
    Text=m15%204eg
    IsMiddleware=false
    Container=GB|RM|ENG|4EG-M15
    Origin=
    Countries=
    Limit=
    Language=en
*/

const useAddressAPI = (postcode: string) => {
    const [results, setResults] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    useEffect(() => {
        async function fetchData(container?: string) {
            try {
                setLoading(true);

                const url =
                    'https://api.addressy.com/Capture/Interactive/Find/v1.1/json3.ws' +
                    '?Key=' + encodeURIComponent('TH48-AB78-RT24-UN29') +
                    '&Text=' + encodeURIComponent(postcode) +
                    '&IsMiddleware=false' +
                    '&Container=' + encodeURIComponent(container || '') + //GB|RM|ENG|4EG-M15' +
                    '&Origin=' +
                    '&Countries=' +
                    '&Limit=' +
                    '&Language=en';

                const response = await axios.get(url, {
                    cancelToken: source.token,
                });

                if (response.status == 200) {
                    if (response.data.Items.length == 1) {
                        // Need to re-request to get the actual list of addresses
                        // by passing this ID as the Container for the next request
                        const actual = await fetchData(response.data.Items[0].Id);
                        setResults(actual!.data);
                    } else {
                        setResults(response.data);
                    }
                }
                setLoading(false);

                return response;
            } catch (error) {
                if (error.message != "Operation canceled by the user.") {
                    setLoading(false);
                }
                setError(true);
            }
        }

        if (postcode !== '') {
            fetchData();
        }

        return function cleanup() {
            source.cancel('Operation canceled by the user.');
            setLoading(false);
        };

    }, [postcode]);

    return { results, loading, error };
};

export default useAddressAPI;