import { createStyles, makeStyles, Theme } from "@material-ui/core";

export interface TextFieldStyleOptions {
    boldLabel?: boolean;
    reducedMargin?: boolean;
};

const useStyles = (options: TextFieldStyleOptions) => makeStyles((theme: Theme) => {
    return createStyles({
        textField: {
            margin: options.reducedMargin ? theme.spacing(0, 0, 2, 0) : theme.spacing(2.5, 0),
            '& label.MuiFormLabel-root': {
                color: options.boldLabel ? theme.palette.text.secondary : theme.palette.text.primary,
                fontFamily: options.boldLabel ? "Spartan MB Bold" : "Spartan MB SemiBold",
                fontSize: options.boldLabel ? "1.25rem" : "1rem",
                transform: "scale(1)",
                lineHeight: '1.25rem',
                marginTop: '0rem',
                marginRight: '48px',
                position: 'relative',
            },
            '& .MuiInput-formControl': {
                marginTop: '0px',
            },
            '& .MuiInput-input': {
                fontFamily: "Spartan MB ",
                fontSize: "1rem",
                paddingBottom: 10,
                paddingTop: 12,
            },
            '& .MuiInput-underline:before': {
                borderBottom: '2px solid #e5e5e5',
            },
            '& .MuiInput-underline': {
                '&:after, &:hover:before': {
                    borderBottomColor: theme.palette.secondary.main,
                }
            },
            "&::placeholder": {
                fontSize: "1rem",
            },
            '& .MuiFormHelperText-root': {
                lineHeight: '1.25rem',
            },
        },
        labelRoot: {
            color: theme.palette.text.primary,
        },
    })
}
);

export default useStyles({ boldLabel: false, reducedMargin: false });
export { useStyles }