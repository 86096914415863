import axios from 'axios';
import { useEffect, useState } from "react";
import { setRetrievedQuote } from '../components/RetrieveQuote/RetrieveQuoteAction';
import { RetrieveQuoteResponse } from '../components/RetrieveQuote/RetrieveQuoteResponse';
import { useStateValue } from './StateContext';

export type Props = {
    Code: string;
};

const useDecryptRetrieveQuote = ({ Code }: Props) => {
    const { dispatch } = useStateValue();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    useEffect(() => {
        async function getQuote() {
            try {
                setError(false);
                setLoading(true);
                const url = `${process.env.FUNCTION_BASE_URL_RETRIEVE}api/DecryptRetrieveQuote?code=${Code}`;

                const response = await axios.get(url, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                dispatch(setRetrievedQuote(response.data as RetrieveQuoteResponse));
            }
            catch (error) {
                if (error.message != "Operation canceled by the user.") {
                    setLoading(false);
                }
                setError(true);
            } finally {
                setLoading(false);
            }
        };

        if (Code) {
            getQuote();
        }

        return function cleanup() {
            source.cancel('Operation canceled by the user.');
            setLoading(false);
        };
    }, []);

    return { loading, error };
};

export default useDecryptRetrieveQuote;