import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import React from "react";
import { PossessionsModel } from "../Possessions/PossessionsModel";
import { Include, ListOfHighValueItems, HighValueItems } from "../Possessions/PossessionsValuesModel";
import YesNoQuestion from "./YesNoQuestion";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        checkSummary: {
            display: 'flex',
            marginBottom: theme.spacing(1),
            '& p': {
                fontFamily: "Spartan MB Bold",
                fontSize: '14px',
                alignSelf: 'center',
            },
            '& svg': {
                color: '#5f2654',
                marginRight: theme.spacing(1.5),
            },
        },
    })
);

type Props = {
    itemDetails: HighValueItems | undefined;
    values: PossessionsModel;
    initialAnswer: boolean;
};

const PossessionsHomeAwaySummary = ({ itemDetails, values, initialAnswer }: Props) => {
    const classes = useStyles();

    const coverAwayFromHome = (values: PossessionsModel) => (selectedOption: boolean) => {
        values.IncludeAwayFromHome = selectedOption;
    };

    const allowedAwayFromHomeOption = () => {
        const selectedItem = ListOfHighValueItems.filter(x => x.Id === (itemDetails ? itemDetails.Id : values.ItemType));
        if (selectedItem.length > 0) {
            return selectedItem[0].AllowedAwayFromHome;
        };
        return undefined;
    };

    return (
        <React.Fragment>
            {
                allowedAwayFromHomeOption() === Include.Optional &&
                <YesNoQuestion
                    questionText="Would you like cover away from home?"
                    onClick={coverAwayFromHome(values)}
                    initialAnswer={initialAnswer}
                />
            }
            {
                allowedAwayFromHomeOption() === Include.Yes &&
                <div className={classes.checkSummary}><CheckIcon /><Typography>Away from home cover included</Typography></div>
            }
            {
                allowedAwayFromHomeOption() === Include.No &&
                <div className={classes.checkSummary}><ClearIcon /><Typography>Away from home cover not available</Typography></div>
            }
            {
                allowedAwayFromHomeOption() !== undefined &&
                <div className={classes.checkSummary}><CheckIcon /><Typography>In home cover included</Typography></div>
            }
            {
                values.ItemType === "PedalCyclesOver1000" &&
                <>
                    <div className={classes.checkSummary}><CheckIcon /><Typography>Away from home cover included</Typography></div>
                    <div className={classes.checkSummary}><CheckIcon /><Typography>In home cover included</Typography></div>
                </>
            }
        </React.Fragment>
    )
};

export default PossessionsHomeAwaySummary;