import { TextField } from "@material-ui/core";
import { FieldProps } from "formik";
import React from 'react';
import useStyles from "./Styles/MaterialTextFieldUseStyles";

const MaterialTextField = <P extends object>({
    field,
    form: {
        touched,
        errors,
    },
    ...props
}: FieldProps<P>) => {
    const classes = useStyles();
    const get = (o: any, path: string) => path
        .split('.')
        .reduce((o: any = {}, key: string) => o[key], o);

    const touchedField = get(touched, field.name);
    const errorsField = get(errors, field.name);

    return (
        <TextField
            className={classes.textField}
            fullWidth
            margin="normal"
            helperText={touched && touchedField ? errors && errorsField : ""}
            error={touched && touchedField && errors && Boolean(errorsField)}
            inputProps={{
                maxLength: 100
            }}
            InputLabelProps={{
                shrink: true,
                classes: {
                    root: classes.labelRoot
                }
            }}
            {...props}
            {...field}
        />
    );
};

export default MaterialTextField;