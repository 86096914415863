import { RouteComponentProps, navigate } from "@reach/router";
import React from "react";
import { useStateValue } from "../../../../hooks/StateContext";
import { setAllAddressDetails } from "../../../Address/AddressDetailActions";
import Overlay from "../../../Controls/Overlay";
import EditAddressForm from "../../../Address/EditAddressForm";
import { AddressDetailsModel, AllAddressDetails } from "../../../Address/AddressDetailsModel";

interface Props extends RouteComponentProps { }

const AddressManualOverlay = (_: Props) => {
    const { state, dispatch } = useStateValue();

    const handleCancel = () => {
        history.back();
    };

    const handleSave = (values: AddressDetailsModel) => {
        const addressDetails: AllAddressDetails = {
            RiskAddress: {
                AddressLine1: values.AddressLine1,
                AddressLine2: values.AddressLine2,
                AddressLine3: values.AddressLine3,
                AddressLine4: values.AddressLine4,
                Postcode: values.Postcode,
            },
            CorrespondenceAddress: {
                AddressLine1: values.AddressLine1,
                AddressLine2: values.AddressLine2,
                AddressLine3: values.AddressLine3,
                AddressLine4: values.AddressLine4,
                Postcode: values.Postcode,
            },
        };

        dispatch(setAllAddressDetails(addressDetails));
        navigate('/direct/property', { replace: true });
    };

    return (
        <Overlay title="Edit address">
            <EditAddressForm handleCancel={handleCancel} handleSave={handleSave} address={state.RiskAddress} />
        </Overlay>
    );
};

export default AddressManualOverlay;