import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import { RouteComponentProps } from "@reach/router";
import { Form, Formik, FormikProps } from "formik";
import React from "react";
import * as Yup from "yup";
import { useStateValue } from "../../hooks/StateContext";
import ApplicantDetails from "../Applicant/ApplicantDetails";
import { ApplicantDetailsModel, ApplicantDetailsValidationSchema } from "../Applicant/ApplicantDetailsModel";
import CancelButton from "../Controls/CancelButton";
import ContinueButton from "../Controls/ContinueButton";
import RemoveButton from "../Controls/RemoveButton";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        form: {
            margin: theme.spacing(2, 1)
        },
        buttonsGroup: {
            padding: theme.spacing(1.5, 0),
        },
    })
);

interface Props extends RouteComponentProps {
    onCancel: () => void;
    onSave: (values: ApplicantDetailsModel) => void;
    onRemoveApplicant: () => void;
};

const PolicyHolderForm = ({ onCancel, onSave, onRemoveApplicant }: Props) => {
    const classes = useStyles();
    const { state } = useStateValue();

    return (
        <Formik
            initialValues={{
                Title: state.Client2!.Title,
                Forenames: state.Client2!.Forenames,
                Surname: state.Client2!.Surname,
                DateOfBirth: state.Client2!.DateOfBirth
            }}
            validationSchema={Yup.object({ ...ApplicantDetailsValidationSchema })}
            onSubmit={(values, { setSubmitting }) => {
                onSave(values);
                setSubmitting(false);
            }}
        >{({ handleReset }: FormikProps<any>) =>
            <Form noValidate className={classes.form}>
                <ApplicantDetails
                    titleLabel={"What's their title?"}
                    forenamesLabel={"What are their forenames?"}
                    surnameLabel={"What is their surname?"}
                    dateOfBirthLabel={"What is their date of birth?"}
                />
                <Grid container spacing={2} className={classes.buttonsGroup}>
                    <Grid item xs={6}>
                        <CancelButton testId="cancel" onClick={() => { handleReset(), onCancel() }}>Cancel</CancelButton>
                    </Grid>
                    <Grid item xs={6}>
                        <ContinueButton testId="save" disabled={false}>Save &amp; use</ContinueButton>
                    </Grid>
                    {state.IncludeSecondApplicant &&
                        <Grid item xs={12}>
                            <RemoveButton testId="remove" onClick={onRemoveApplicant}>Remove applicant</RemoveButton>
                        </Grid>
                    }
                </Grid>
            </Form>
            }
        </Formik>
    );
};

export default PolicyHolderForm;