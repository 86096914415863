import { createStyles, FormControl, makeStyles, Theme, Typography } from "@material-ui/core";
import { Field, Form, FormikBag, FormikProps, withFormik } from "formik";
import React, { useEffect, useState } from "react";
import useDisableContinue from "../../hooks/useDisableContinue";
import ContinueButton from "../Controls/ContinueButton";
import MaterialDateInput from "../Controls/MaterialDateInput";
import MaterialTextField from "../Controls/MaterialTextField";
import { RetrieveQuoteRequest, RetrieveQuoteValidationSchema } from "./RetrieveQuoteRequest";

const useStyles = makeStyles((_: Theme) =>
    createStyles({
        failValidation: {
            color: '#a8203b',
            '& p': {
                fontSize: "0.875rem",
                fontFamily: "Spartan MB",
            },
        },
    }),
);

interface FormProps {
    Surname: string;
    DateOfBirth: string;
    Postcode: string;
    FailedValidation: boolean;
    SubmitHandler: (values: RetrieveQuoteRequest) => void;
};

const RetrieveQuoteDetails = ({ Surname, DateOfBirth, Postcode, FailedValidation, values }: FormikProps<RetrieveQuoteRequest> & FormProps) => {
    const classes = useStyles();
    const { isDisabled } = useDisableContinue();
    const [displayError, setDisplayError] = useState(false);

    const hasChangedValues = () => {
        if (Surname !== values.Surname || DateOfBirth !== values.DateOfBirth || Postcode !== values.Postcode) {
            setDisplayError(false);
            return false;
        }
    };

    useEffect(() => {
        if (FailedValidation) {
            setDisplayError(true);
        }
    }, [FailedValidation]);

    return (
        <Form noValidate>
            <Field
                id="retrieve-surname"
                name="Surname"
                label="Surname"
                component={MaterialTextField}
                placeholder="Type your surname here"
                inputProps={{
                    "data-testid": 'retrieve-surname'
                }}
            />
            <Field id="retrieve-date-of-birth"
                name="DateOfBirth"
                label="Date of Birth"
                component={MaterialDateInput}
                inputProps={{
                    "data-testid": 'retrieve-date-of-birth'
                }}
            />
            <Field id="retrieve-postcode"
                name="Postcode"
                label="Postcode"
                component={MaterialTextField}
                placeholder="eg: SK7345"
                inputProps={{
                    "data-testid": 'retrieve-postcode'
                }}
            />
            {
                displayError && !hasChangedValues() &&
                <FormControl fullWidth className={classes.failValidation}>
                    <Typography align="left">
                        We could not retrieve a quote with the given details
                    </Typography>
                </FormControl>
            }
            <ContinueButton disabled={isDisabled}>Retrieve Quote</ContinueButton>
        </Form>
    )
};

const RetrieveQuoteDetailsForm = withFormik<RetrieveQuoteRequest & FormProps, RetrieveQuoteRequest>({
    mapPropsToValues: (props: RetrieveQuoteRequest & FormProps) => {
        return {
            Code: props.Code,
            Surname: props.Surname,
            DateOfBirth: props.DateOfBirth,
            Postcode: props.Postcode,
        };
    },
    handleSubmit: (values, { props }: FormikBag<RetrieveQuoteRequest & FormProps, RetrieveQuoteRequest>) => {
        props.SubmitHandler(values);
    },
    validationSchema: RetrieveQuoteValidationSchema,
    displayName: 'RetrieveQuoteDetails',
    validateOnMount: true,
})(RetrieveQuoteDetails);

export default RetrieveQuoteDetailsForm;