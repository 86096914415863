import { Box, createStyles, makeStyles, Theme } from "@material-ui/core";
import { navigate } from "@reach/router";
import React from "react";
import { useStateValue } from "../../hooks/StateContext";
import ContinueButton from "../Controls/ContinueButton";
import EndorsementCard from "../Controls/EndorsementCard";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        endorsementText: {
            fontSize: '0.825rem',
            margin: theme.spacing(1, 0, 0),
        },
    })
);

const Endorsements = () => {
    const classes = useStyles();
    const { state } = useStateValue();

    const handleNext = () => {
        navigate('/direct/policyinformation');
    };

    const hasEndorsement = (id: number) => {
        if (state && state.EndorsementQuestions && state.EndorsementQuestions.find((e: any) => e.Number == id) !== undefined) {
            return true;
        }
        return false;
    };

    return (
        <React.Fragment>
            <p>Please note that there are a number of endorsements that will apply to your policy. These special conditions vary the policy and will be shown on your schedule.</p>
            {hasEndorsement(1) &&
                <EndorsementCard
                    id="1"
                    title="Minimum standards of security endorsement"
                >
                    <Box className={classes.endorsementText}>
                        <p>We will not pay for loss or damage caused by theft, attempted theft or malicious damage when your home is left unattended or when you and your family have retired for the night unless:</p>
                        <ul>
                            <li>Your final exit door, any other external doors, sliding doors, patio doors and double leaf French doors are secured with a 5 lever mortise deadlock conforming to BS3621, a rim automatic deadlock or a multi-point locking system; </li>
                            <li>All externally accessible windows are fitted with key operated window locks or key operated handles (Windows are considered to be externally accessible if they can be accessed from outside your home without a ladder or by climbing from a nearby flat roof); </li>
                            <li>Before going to bed, you or your family close and fully lock all external doors and windows, unless they are in occupied bedrooms; </li>
                            <li>Whenever you and your family leave your home, you close and fully lock all doors and windows; </li>
                            <li>Whenever you and your family leave your home, all keys are removed from locks and taken away from your home or placed out of sight. </li>
                        </ul>
                        <p>We will allow up to 28 days from the date that the endorsement applies for the locks to be fitted. If the locks are not installed within this time, the policy will not cover theft, attempted theft or malicious damage at the home until they are installed.</p>
                    </Box>
                </EndorsementCard>
            }
            {hasEndorsement(2) &&
                <EndorsementCard
                    id="2"
                    title="Flats endorsement"
                >
                    <Box className={classes.endorsementText}>
                        <p>The maximum claim limit for Section 1 - Buildings represents the value of that portion of the building owned by you (including external walls, roof and foundations and such common parts of the building for which you are legally responsible). In the event of a loss resulting from an insured event to any part of the property not occupied by you but for which you are legally responsible, we will only pay such portion of that loss as the sum insured bears to the reinstatement of the building.</p>
                    </Box>
                </EndorsementCard>
            }
            {hasEndorsement(3) &&
                <EndorsementCard
                    id="3"
                    title="Safe warranty clause"
                >
                    <Box className={classes.endorsementText}>
                        <p>This policy does not cover theft of any item of jewellery or watches valued more than £7,500 unless they are kept in a locked safe when not being worn. </p>
                    </Box>
                </EndorsementCard>
            }
            {hasEndorsement(4) &&
                <EndorsementCard
                    id="4"
                    title="Valuation for high-risk property value £5,000 or more"
                >
                    <Box className={classes.endorsementText}>
                        <p>It is a condition of this policy that a current valuation (of not more than 3 years old) is required when claiming for any items of high risk property valued more than £5,000.</p>
                    </Box>
                </EndorsementCard>
            }
            {hasEndorsement(5) &&
                <EndorsementCard
                    id="5"
                    title="Watches and jeweller and inspection warranty clause"
                >
                    <Box className={classes.endorsementText}>
                        <p>We will not pay for loss of any item of jewellery or watches valued more than £7,500 unless the clasps, settings and mountings have been checked by a qualified jeweller at least every three years and any defects addressed. A record of any inspections must be retained. </p>
                    </Box>
                </EndorsementCard>
            }
            {hasEndorsement(6) &&
                <EndorsementCard
                    id="6"
                    title="Pedal cycle endorsement"
                >
                    <Box className={classes.endorsementText}>
                        <p>We will not pay for loss or damage by theft or attempted theft of pedal cycles unless: </p>
                        <ul>
                            <li>the pedal cycle is kept in a locked building and there is physical evidence of violent forcible entry to or exit from the building; </li>
                            <li>the pedal cycle is secured through the frame to a permanently fixed structure by a ‘D’-shaped shackle lock designed for use with pedal cycles. </li>
                            <li>the pedal cycle was:
                                <ul>
                                    <li>contained inside a vehicle with all doors, windows and other openings securely locked and any security devices for the vehicle were in full effective operation; or </li>
                                    <li>secured through the frame by a security device to a roof or cycle rack attached to a vehicle.</li>
                                </ul>
                            </li>
                        </ul>
                    </Box>
                </EndorsementCard>
            }
            {hasEndorsement(7) &&
                <EndorsementCard
                    id="7"
                    title="Unoccupied property endorsement"
                >
                    <Box className={classes.endorsementText}>
                        <p>The cover which is excluded from your policy when your home is unoccupied for more than 60 days in a row is reinstated subject to the following terms applying: </p>
                        <p>You must: </p>
                        <ul>
                            <li>arrange for the home to be inspected internally and externally every 7 days by an authorised adult;</li>
                            <li>ensure that all door and window locks and other protective devices are put into operation whenever the home is left unattended;</li>
                            <li>during the period 1 October to 31 March inclusive: turn the water service off at the mains and drain the water and heating system, or</li>
                            <li>if the home is centrally heated, leave the central heating on to a minimum of 10 degrees Centigrade (50 degrees Fahrenheit).</li>
                        </ul>
                        <p>We will not pay for: </p>
                        <ul>
                            <li>water or oil leaking, theft or attempted theft or vandalism or malicious damage unless the above conditions have been met; </li>
                            <li>theft or attempted theft unless force and violence are used to break into the home; </li>
                            <li>the first £250 of any claim settlement in addition to any excess shown on your schedule; </li>
                            <li>loss or damage to high risk property; or </li>
                            <li>accidental damage </li>
                        </ul>
                    </Box>
                </EndorsementCard>
            }
            {hasEndorsement(8) &&
                <EndorsementCard
                    id="8"
                    title="Child minding endorsement"
                >
                    <Box className={classes.endorsementText}>
                        <p>Whilst the home is being used as a nursery, supervising infants within your care, this insurance will not cover accidental damage or accidental breakage and we will not pay for any theft or loss from the home unless following forcible and violent entry or exit. </p>
                        <p>In respect of your activities as a child minder, Public and Personal Liability cover is specifically excluded. </p>
                        <p>Cover is subject to: - </p>
                        <ul>
                            <li>There being no more than 3 children being looked after at any one time (excluding your own children). </li>
                            <li>There being no staff employed to assist in the child minding activities. </li>
                            <li>The child minder being registered with the local authority. </li>
                        </ul>
                    </Box>
                </EndorsementCard>
            }
            <ContinueButton testId="endorsementsNext" onClick={handleNext}>Next</ContinueButton>
        </React.Fragment>
    );
};

export default Endorsements;