import { createStyles, makeStyles, Theme } from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import { RouteComponentProps } from "@reach/router";
import React from "react";
import { useStateValue } from "../../../../hooks/StateContext";
import Overlay from "../../../Controls/Overlay";
import { setBuildingsNCD, setContentsNCD } from "../../../NoClaimsDiscount/NoClaimsDiscountActions";
import { NoClaimsDiscountTypeEnum } from "../../../NoClaimsDiscount/NoClaimsDiscountTypeEnum";
import YearsList from "../../../NoClaimsDiscount/YearsList";
import { refreshQuotes } from "../../../QuoteResults/QuoteResultsActions";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonList: {
            height: '100vh',
            listStyle: 'none',
            padding: 0,
            margin: 0,
            '& li:not(:last-child)': {
                borderBottom: '2px solid #e5e5e5',
            },
        },
        buttonDiv: {
            display: 'flex',
            width: '100%',
            height: '50px',
            alignItems: 'center',
        },
        button: {
            flexGrow: 1,
            fontFamily: "Spartan MB",
            fontSize: "14px",
            cursor: "pointer",
            background: "none",
            border: "none",
            margin: theme.spacing(1, 0),
            padding: "0",
            textAlign: 'left',
            '&:active, &:focus': {
                "outline": "none",
            },
        },
        tick: {
            color: theme.palette.secondary.main,
        },
    })
);

interface Props extends RouteComponentProps { }

const NoClaimsDiscountOverlay = ({ }: Props) => {
    const classes = useStyles();
    const { state, dispatch } = useStateValue();

    const handleSelectNCD = (id: number) => {
        dispatch(refreshQuotes());
        switch (state.EditingNoClaimsDiscount) {
            case NoClaimsDiscountTypeEnum.BuildingsNCD:
                dispatch(setBuildingsNCD(id));
                break;
            case NoClaimsDiscountTypeEnum.ContentsNCD:
                dispatch(setContentsNCD(id));
                break;
            default:
                throw new Error("Invalid NCD type.");
        }
        history.back();
    };

    return (
        <Overlay title="Years of no claims">
            <ul className={classes.buttonList}>
                {YearsList.map((x: any) =>
                    <li key={x.Id} data-testid="ncd-value">
                        <div className={classes.buttonDiv}>
                            <button className={classes.button} onClick={() => handleSelectNCD(x.Id)} data-testid="ncd-button">
                                {x.Value}
                            </button>
                            {state.EditingNoClaimsDiscount == NoClaimsDiscountTypeEnum.BuildingsNCD
                                ? state.BuildingsNoClaimsDiscountId === x.Id && <CheckIcon className={classes.tick} data-testid="selected-buildings" />
                                : state.ContentsNoClaimsDiscountId === x.Id && <CheckIcon className={classes.tick} data-testid="selected-contents" />
                            }
                        </div>
                    </li>
                )}
            </ul>
        </Overlay>
    );
};

export default NoClaimsDiscountOverlay;