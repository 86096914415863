import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { navigate } from "@reach/router";
import { Field, Form, FormikBag, FormikProps, withFormik } from "formik";
import React, { useState } from "react";
import { useStateValue } from "../../hooks/StateContext";
import useDisableContinue from "../../hooks/useDisableContinue";
import useWindowSize from "../../hooks/useWindowSize";
import { setCorrespondenceAddress } from "../Address/AddressDetailActions";
import { AddressDetailsModel } from "../Address/AddressDetailsModel";
import AddressEditModal from "../Address/AddressEditModal";
import AddressSummary from "../Address/AddressSummary";
import { addSecondApplicant, removeSecondApplicant, setNoSecondApplicant } from "../Applicant/ApplicantDetailActions";
import { ApplicantDetailsModel } from "../Applicant/ApplicantDetailsModel";
import ContinueButton from "../Controls/ContinueButton";
import MaterialDateInput from "../Controls/MaterialDateInput";
import Section from "../Controls/Section";
import SelectOverlay from "../Controls/SelectOverlay";
import YesNoQuestion from "../Controls/YesNoQuestion";
import { setPolicyDocumentsByEmail, setPolicyStartDate } from "../PolicyInformation/PolicyInformationActions";
import { PolicyFormModel, PolicyInformationValidationSchema } from "../PolicyInformation/PolicyInformationModel";
import PolicyHolderModal from "./PolicyHolderModal";

const useStyles = makeStyles((_: Theme) =>
    createStyles({
        questionText: {
            fontSize: '1rem',
            fontFamily: 'Spartan MB SemiBold',
            lineHeight: '1.25rem',
            marginBottom: '14px',
        },
    })
);

const PolicyInformationForm = ({ values }: FormikProps<PolicyFormModel>) => {
    const [openPolicyHolder, setOpenPolicyHolder] = useState(false);
    const [openCorrespondenceAddress, setOpenCorrespondenceAddress] = useState(false);
    const { state, dispatch } = useStateValue();
    const { isDisabled } = useDisableContinue();
    const { isMobile } = useWindowSize();
    const classes = useStyles();

    const checkIsDisabled = () => {
        if (state.IncludeSecondApplicant != undefined
            && state.Apply.ReceiveInsuranceDocumentsEmail != undefined
            && !isDisabled) {
            return false;
        }
        return true;
    };

    const handleAddPolicyHolderClick = (addSecondApplicant: boolean) => {
        if (addSecondApplicant && isMobile) {
            dispatch(setPolicyStartDate(values.PolicyStartDate));
        }

        if (addSecondApplicant && isMobile) {
            navigate('/direct/policyinformation/policyholder');
        } else if (addSecondApplicant && !isMobile) {
            setOpenPolicyHolder(true);
        } else {
            dispatch(setNoSecondApplicant());
        }
    };

    const handlePolicyDocumentsByEmail = (value: boolean) => {
        dispatch(setPolicyDocumentsByEmail(value));
    };

    const handleEditCorrespondenceAddress = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        if (isMobile) {
            navigate('/direct/policyinformation/correspondence');
        } else {
            setOpenCorrespondenceAddress(true);
        }
    };

    const handlePolicyHolderCancel = () => {
        setOpenPolicyHolder(false);
    };

    const handlePolicyHolderSave = (values: ApplicantDetailsModel) => {
        dispatch(addSecondApplicant(values));
        setOpenPolicyHolder(false);
    };

    const handleRemoveApplicant = () => {
        dispatch(removeSecondApplicant());
        setOpenPolicyHolder(false);
    };

    const handleCorrespondenceAddressCancel = () => {
        setOpenCorrespondenceAddress(false);
    };

    const handleCorrespondenceAddressSave = (values: AddressDetailsModel) => {
        setOpenCorrespondenceAddress(false);
        dispatch(setCorrespondenceAddress(values));
    };

    return (
        <>
            <Form id="policyInfoForm" noValidate>
                <Section testId="jointHelp" sectionTitle={"Joint policy holder"} >
                    {state.Client2 && (state.Client2.Title && state.Client2.Forenames && state.Client2.Surname)
                        ? <SelectOverlay
                            label={`${state.Client2.Title} ${state.Client2.Forenames} ${state.Client2.Surname}`}
                            value={state.Client2.DateOfBirth}
                            onClick={() => handleAddPolicyHolderClick(true)}
                            reducedMargin={true}
                        />
                        : <YesNoQuestion
                            questionText={"Do you want to add a joint policyholder?"}
                            onClick={(value) => handleAddPolicyHolderClick(value)}
                            initialAnswer={state.IncludeSecondApplicant}
                        />
                    }
                </Section>
                <Section testId="startDateHelp" sectionTitle={"Policy start date"}>
                    <Field name={`PolicyStartDate`}
                        id={`policy-start-date`}
                        label={
                            <div>
                                When would you like your policy to start?
                        </div>
                        }
                        component={MaterialDateInput}
                    />
                </Section>
                <Section testId="emailHelp" sectionTitle={"Policy documents by email?"} >
                    <YesNoQuestion
                        questionText={`Your documents will be posted to you in the next few days. Would you like us to also send a copy via email to ${state.Client1.EmailAddress}? `}
                        onClick={(value) => handlePolicyDocumentsByEmail(value)}
                        initialAnswer={state.Apply.ReceiveInsuranceDocumentsEmail} />
                </Section>
                <Section testId="correspondenceHelp" sectionTitle={"Correspondence address"}>
                    <Typography className={classes.questionText}>We have this as your correspondence address. Please check it's correct.</Typography>
                    <AddressSummary address={state.CorrespondenceAddress!} onClick={handleEditCorrespondenceAddress} hideLabel={true} />
                </Section>
                <ContinueButton testId="goToPayment" disabled={checkIsDisabled()}>Go to payment</ContinueButton>
            </Form>
            <PolicyHolderModal
                open={openPolicyHolder}
                onCancel={handlePolicyHolderCancel}
                onSave={handlePolicyHolderSave}
                onRemoveApplicant={handleRemoveApplicant}
            />
            <AddressEditModal
                open={openCorrespondenceAddress}
                address={state.CorrespondenceAddress!}
                onCancel={handleCorrespondenceAddressCancel}
                onSave={handleCorrespondenceAddressSave}
            />
        </>
    );
};

interface FormProps {
    SubmitHandler: (values: PolicyFormModel) => void;
};

export default withFormik<PolicyFormModel & FormProps, PolicyFormModel>({
    mapPropsToValues: (props: PolicyFormModel & FormProps) => {
        return {
            PolicyStartDate: props.PolicyStartDate
        }
    },
    handleSubmit: (values, { props }: FormikBag<PolicyFormModel & FormProps, PolicyFormModel>) => {
        props.SubmitHandler(values);
    },
    validationSchema: PolicyInformationValidationSchema,
    displayName: 'PolicyInformationForm',
})(PolicyInformationForm);