import axios from 'axios';
import { ApplicantDetailsModel } from "../components/Applicant/ApplicantDetailsModel";
import { AddressDetailsModel } from "../components/Address/AddressDetailsModel";
import { useEffect, useState } from "react";
import { getDateFromString } from '../components/utils';

export type useDocumentProps = {
    QuoteReference: string,
    Client1: ApplicantDetailsModel,
    RiskAddress: AddressDetailsModel,
    DocumentType: string;
};

const useDocument = ({ QuoteReference, Client1, RiskAddress, DocumentType }: useDocumentProps) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    useEffect(() => {
        async function postData() {
            try {
                setLoading(true);
                const url = `${process.env.FUNCTION_BASE_URL}api/getdocument`;

                const response = await axios.post(url,
                    {
                        QuoteReference,
                        DocumentType,
                        Client1: {
                            ...Client1,
                            DateOfBirth: getDateFromString(Client1.DateOfBirth)
                        },
                        RiskAddress,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        responseType: 'blob'
                    })
                    .then(blob => {
                        var url = window.URL.createObjectURL(new Blob([blob.data]));
                        var a = document.createElement('a');
                        a.href = url;
                        a.download = `${DocumentType}.pdf`;
                        a.click();
                        a.remove();
                        setTimeout(() => window.URL.revokeObjectURL(url), 100);
                    });

                return await response;
            }
            catch (error) {
                if (error.message != "Operation canceled by the user.") {
                    setLoading(false);
                }
                setError(true);
            } finally {
                setLoading(false);
            }
        };

        if (DocumentType !== '') {
            postData();
        }

        return function cleanup() {
            source.cancel('Operation canceled by the user.');
            setLoading(false);
        };
    }, [DocumentType]);

    return { loading, error }
};

export default useDocument;