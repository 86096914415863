import React from "react";
import SectionTitle from "../Controls/SectionTitle";
import Section from "../Controls/Section";
import { Typography, makeStyles, Theme, createStyles } from "@material-ui/core";

const useStyles = makeStyles((_: Theme) =>
    createStyles({
        text: {
            fontSize: '1rem',
            fontFamily: 'Spartan MB Semibold',
        },
        boldText: {
            fontSize: '1rem',
            fontFamily: 'Spartan MB Bold',
        },
    }));

type ApplicationSuccessProps = {
    CoverType: string;
    PolicyStartDate: string;
    IsAnnualPayment: boolean;
    FirstPaymentDate: string;
    PaymentAmount: string;
    PolicyDocumentsByEmail: boolean;
};

const ApplicationSuccess = ({ CoverType, PolicyStartDate, IsAnnualPayment, FirstPaymentDate, PaymentAmount, PolicyDocumentsByEmail }: ApplicationSuccessProps) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <SectionTitle title="Thank you! Your application for a policy has been accepted." icon="/img/Buildings_Contents_Uinsure-svg.png" />
            <Section sectionTitle="Start date">
                <Typography className={classes.text}>
                    <span id="coverType" >Your {CoverType} insurance policy starts on </span>
                    <span id="startDate" className={classes.boldText}>{PolicyStartDate}.</span>
                </Typography>
            </Section>
            <Section sectionTitle={IsAnnualPayment ? "Payment" : "First payment"}>
                <Typography className={classes.text}>
                    <span>{IsAnnualPayment ? "A payment of " : "First payment of "}</span>
                    <span id="paymentAmount" className={classes.boldText}>£{PaymentAmount}</span>
                    <span> will be taken around </span>
                    <span id="firstPaymentDate" className={classes.boldText}>{FirstPaymentDate}.</span>
                    <span> You will receive a full payment schedule in the post before any payments are collected.</span>
                </Typography>
            </Section>
            <Section sectionTitle="Need to change something?">
                <Typography className={classes.text}>
                    <span>
                        Don't worry, we don't charge for amending your policy if something isn't quite right.<br />
                        Please call us on&nbsp;
                    </span>
                    <span className={classes.boldText}>0330 100 9602</span>
                </Typography>
            </Section>
            <Section sectionTitle="Your policy documents">
                <Typography className={classes.text}>
                    <span id="policyDocs">
                        {PolicyDocumentsByEmail
                            ? "Your policy documents will be with you shortly by email or post."
                            : "Your policy documents will be with you shortly by post."}
                    </span>
                </Typography>
            </Section>
        </React.Fragment>
    );
};

export default ApplicationSuccess;