import { RouteComponentProps } from "@reach/router";
import React from "react";
import { useStateValue } from "../../../../hooks/StateContext";
import { addSecondApplicant, removeSecondApplicant } from "../../../Applicant/ApplicantDetailActions";
import { ApplicantDetailsModel } from "../../../Applicant/ApplicantDetailsModel";
import Overlay from "../../../Controls/Overlay";
import PolicyHolderForm from "../../../PolicyInformation/PolicyHolderForm";

interface Props extends RouteComponentProps { };

const PolicyHolderOverlay = (_: Props) => {
    const { dispatch } = useStateValue();

    const handleCancel = () => {
        history.back();
    };

    const handleSave = (values: ApplicantDetailsModel) => {
        dispatch(addSecondApplicant(values));
        history.back();
    };

    const handleRemoveApplicant = () => {
        dispatch(removeSecondApplicant());
        history.back();
    };

    return (
        <Overlay title="Second applicant">
            <PolicyHolderForm
                onCancel={handleCancel}
                onSave={handleSave}
                onRemoveApplicant={handleRemoveApplicant}
            />
        </Overlay>
    );
};

export default PolicyHolderOverlay;