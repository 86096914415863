import { createStyles, makeStyles, Theme, Hidden } from "@material-ui/core";
import React from 'react';
import MaterialButton from "./MaterialButton";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            fontFamily: "Spartan MB Bold",
            borderWidth: 2,
            borderColor: '#e5e5e5',
            borderStyle: 'solid',
            width: '140px',
            height: '50px',
            padding: theme.spacing(1, 0),
            margin: theme.spacing(2, 3, 2, 0),
        },
        buttonEnabled: {
            backgroundColor: 'transparent',
            color: '#9d9d9d',
        },
    })
);

type Props = {
    children: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    disabled?: boolean;
    testId?: string;
};

const BackButton = ({ children, onClick, disabled, testId }: Props) => {
    const classes = useStyles();
    const classList = [];
    if (!disabled) classList.push(classes.buttonEnabled);

    const historyBack = () => {
        history.back();
    };

    return (
        <Hidden only={'xs'}>
            <MaterialButton
                data-testid={testId || "back-button"}
                className={`${classes.button} ${classList.join(" ")}`}
                disabled={disabled}
                onClick={onClick ? onClick : historyBack}
                type="button"
            >
                {children}
            </MaterialButton>
        </Hidden>
    );
};

export default BackButton;