import { RouteComponentProps } from "@reach/router";
import React from "react";
import { useStateValue } from "../../../../hooks/StateContext";
import OverlayItemList, { SingleItem } from "../../../Controls/OverlayItemList";
import { setHighValueItemEdit } from "../../../Possessions/PossessionsActions";
import { ListOfHighValueItems } from "../../../Possessions/PossessionsValuesModel";

interface Props extends RouteComponentProps { }

const PossessionsHighValueItemListOverlay = ({ }: Props) => {
    const { state, dispatch } = useStateValue();

    const selectItem = (selectedItem: SingleItem) => (_: any) => {
        const newHighValueItem = { ...state.HighValueItemEdit!, ItemType: selectedItem.Id };
        dispatch(setHighValueItemEdit(newHighValueItem));
        history.back();
    };

    return (
        <OverlayItemList
            title="Item type"
            listItems={ListOfHighValueItems}
            selectedItem={state.HighValueItemEdit && state.HighValueItemEdit.ItemType}
            handleSelectItem={selectItem}
        />
    );
};

export default PossessionsHighValueItemListOverlay;