import { Box, createStyles, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import HelpButton from "./HelpButton";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        sectionTitle: {
            margin: theme.spacing(3.5, 0, 1, 0),
            color: theme.palette.text.secondary,
            fontSize: "1.1875rem",
            fontFamily: "Spartan MB Bold",
        },
        helpIcon: {
            marginTop: theme.spacing(0.5),
            float: "right",
        },
    })
);

type Props = {
    sectionTitle: string;
    helpTitle?: string;
    helpText?: string | JSX.Element;
    testId?: string;
    children: JSX.Element | JSX.Element[];
};

const Section = ({ sectionTitle, helpTitle, helpText, children, testId }: Props) => {
    const classes = useStyles();

    return (
        <Box>
            {
                helpTitle && helpText &&
                <div data-testid="modal-help-button">
                    <HelpButton
                        testId={testId}
                        helpText={helpText}
                        helpTitle={helpTitle}
                        className={classes.helpIcon}
                    />
                </div>
            }
            <h2 className={classes.sectionTitle} data-testid="render-header">{sectionTitle}</h2>
            {children}
        </Box>
    );
};

export default Section;