import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import { Field, Form, FormikProps, Formik } from "formik";
import React from "react";
import MaterialButton from "../../../Controls/MaterialButton";
import MaterialTextField from "../../../Controls/MaterialTextField";
import PossessionsHomeAwayCover from "../../../Controls/PossessionsHomeAwaySummary";
import { PossessionsModel } from "../../../Possessions/PossessionsModel";
import { useStateValue } from "../../../../hooks/StateContext";
import { ListOfHighValueItems } from "../../../Possessions/PossessionsValuesModel";
import MaterialFormattedNumericField from "../../../Controls/MaterialFormattedNumericField";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonsGroup: {
            padding: theme.spacing(1.5, 0),
        },
        button: {
            width: '100%',
            fontSize: '0.875rem',
            height: '40px',
            padding: '0',
            '&.addButton, &.saveUseButton': {
                borderColor: 'transparent',
                backgroundColor: theme.palette.primary.main,
                color: '#ffffff',
            },
            '&.cancelButton': {
                color: theme.palette.secondary.main,
            },
            '&.removeButton': {
                borderWidth: '1px',
                borderColor: '#fc6849',
                color: '#fc6849',
            },
        },
        removeButtonGroup: {
            margin: theme.spacing(2.5, 0),
        },
    })
);

interface FormProps {
    ItemDescription: string;
    ItemType: string;
    ItemValue: number;
    IncludeAtHome: boolean;
    IncludeAwayFromHome: boolean;
    IsEditing: boolean;
    onSaveChanges: (item: PossessionsModel) => void;
    onRemove: (item: PossessionsModel) => void;
    onCancel?: () => void;
};

const PossessionsBicycleForm = ({ onSaveChanges, onRemove, onCancel, ItemDescription, ItemType, ItemValue, IncludeAtHome, IncludeAwayFromHome, IsEditing }: FormProps) => {
    const classes = useStyles();
    const pedalCyclesOver1000Details = ListOfHighValueItems.find(e => e.Id === 'PedalCyclesOver1000');
    const { state } = useStateValue();

    const clearFormatting = (value: string) => {
        return Number(value.replace(/,/g, ''));
    };

    return (
        <Formik
            initialValues={{
                ItemDescription: ItemDescription,
                ItemType: ItemType,
                ItemValue: ItemValue,
                IncludeAtHome: IncludeAtHome,
                IncludeAwayFromHome: IncludeAwayFromHome,
                IsEditing: IsEditing
            }}
            validate={(v) => {
                let errors: any = {};
                const numericItemValue: number = clearFormatting(v.ItemValue.toString());

                if (v.ItemDescription === '') {
                    errors.ItemDescription = 'Description is required.';
                }

                if (numericItemValue < 1000 || numericItemValue > 5000) {
                    errors.ItemValue = 'Cost to replace must be between £1,000 and £5,000.';
                }

                const totalValueBicycles = (state.ContentsPersonalPossessions.filter(item => item.ItemType === 'PedalCyclesOver1000')
                    .reduce((sum: number, item: PossessionsModel) => +sum + +item.ItemValue, 0) - +ItemValue + +numericItemValue);
                if (totalValueBicycles > 5000) {
                    errors.ItemValue = 'Total value of all bicycles cannot exceed £5,000.';
                }

                const totalValueInside = (state.ContentsPersonalPossessions
                    .reduce((sum: number, item: PossessionsModel) => +sum + +item.ItemValue, 0) - +ItemValue + +numericItemValue);

                if (totalValueInside > 15000) {
                    errors.ItemValue = 'Total value of all possessions cannot exceed £15,000.';
                }

                return errors
            }}
            onSubmit={(values, { setSubmitting }) => {
                onSaveChanges({ ...values });
                setSubmitting(false);
            }}
        >{({ values }: FormikProps<any>) =>
            <Form noValidate>
                <Field id="itemDescription"
                    name="ItemDescription"
                    label="Describe the bicycle"
                    component={MaterialTextField}
                    placeholder="e.g. red mountain bike"
                />
                <Field id="itemValue"
                    name="ItemValue"
                    label="Cost to replace it"
                    component={MaterialFormattedNumericField}
                />
                <PossessionsHomeAwayCover
                    itemDetails={pedalCyclesOver1000Details}
                    initialAnswer={values.IncludeAwayFromHome}
                    values={values}
                />
                <Grid container spacing={2} className={classes.buttonsGroup}>
                    <Grid item xs={6}>
                        <MaterialButton
                            testId="bicycle-cancel"
                            className={`${classes.button} cancelButton`}
                            type="reset"
                            onClick={() => {
                                if (typeof (onCancel) !== 'undefined') {
                                    onCancel();
                                } else {
                                    history.back();
                                }
                            }}>
                            Cancel
                        </MaterialButton>
                    </Grid>
                    <Grid item xs={6}>
                        {values.IsEditing ?
                            <MaterialButton
                                testId="bicycle-saveuse"
                                className={`${classes.button} saveUseButton`}
                                onClick={() => onSaveChanges}>
                                Save &amp; use
                        </MaterialButton>
                            :
                            <MaterialButton
                                testId="bicycle-add"
                                className={`${classes.button} addButton`}
                                onClick={() => onSaveChanges}>
                                Add
                        </MaterialButton>
                        }
                    </Grid>
                    {values.IsEditing &&
                        <Grid item xs={12} className={classes.removeButtonGroup}>
                            <MaterialButton
                                testId="bicycle-remove"
                                className={`${classes.button} removeButton`}
                                type="reset"
                                onClick={() => onRemove(values)}>
                                Remove
                        </MaterialButton>
                        </Grid>
                    }
                </Grid>
            </Form>
            }
        </Formik>
    );
};

export default PossessionsBicycleForm;