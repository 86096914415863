import * as Yup from 'yup';
import { AddressDetailsModel } from '../Address/AddressDetailsModel';
import { PostcodeValidationSchema } from '../Address/AddressDetailsValidation';
import {
    ApplicantDetailsValidationSchema,
    contactDetailsValidationSchema,
} from '../Applicant/ApplicantDetailsModel';

export interface AllDetailsModel {
    Title: string;
    Forenames: string;
    Surname: string;
    DateOfBirth: string;
    EmailAddress?: string;
    TelephoneNumber?: string;
    RiskAddress: AddressDetailsModel;
}

export const AllDetailsInitial: AllDetailsModel = {
    Title: '',
    Forenames: '',
    Surname: '',
    DateOfBirth: '',
    EmailAddress: '',
    TelephoneNumber: '',
    RiskAddress: {
        AddressLine1: '',
        AddressLine2: '',
        AddressLine3: '',
        AddressLine4: '',
        Postcode: '',
    },
};

export const getDetailsValidationSchema = (showContactQuestionsBeforePremium: boolean) => {
    const contactDetailsValidation =
        showContactQuestionsBeforePremium && contactDetailsValidationSchema;

    return Yup.object({
        ...ApplicantDetailsValidationSchema,
        ...contactDetailsValidation,
        RiskAddress: Yup.object({
            ...PostcodeValidationSchema,
        }),
    });
};
