import { Box, createStyles, FormControl, Grid, makeStyles, Theme, Typography, useTheme } from "@material-ui/core";
import { Field, Form, FormikBag, FormikProps, withFormik } from "formik";
import React, { MouseEvent, useState } from 'react';
import { useStateValue } from "../../hooks/StateContext";
import useBankAPI from "../../hooks/useBankAPI";
import useDocument from "../../hooks/useDocument";
import BackButton from "../Controls/BackButton";
import ContinueButton from "../Controls/ContinueButton";
import MaterialCheckbox, { useCheckboxStyles } from "../Controls/MaterialCheckbox";
import MaterialTextField from "../Controls/MaterialTextField";
import MonthlyDirectDebitDisplay from "../Controls/MonthlyDirectDebitDisplay";
import PaymentRadioGroup from "../Controls/PaymentRadioGroup";
import Section from "../Controls/Section";
import { PaymentDetailsModel } from "./PaymentDetailsModel";
import { PaymentSchema } from "./PaymentValidation";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: theme.spacing(1),
        },
        directDebitLogo: {
            marginTop: theme.spacing(3),
            textAlign: 'center',
            '& img': {
                height: 40,
            },
        },
        accountDetailsInput: {
            margin: 0,
            '& .MuiInput-input': {
                fontSize: '0.875rem',
            },
        },
        accountDetailsAddress: {
            margin: theme.spacing(2, 0),
            '& p': {
                fontSize: '0.875rem',
            }
        },
        docLink: {
            textDecoration: 'underline',
        },
        quotePrice: {
            marginTop: theme.spacing(2),
            backgroundColor: theme.palette.background.paper,
        },
        quotePriceTitle: {
            fontFamily: 'Spartan MB Bold',
            fontSize: '0.875rem',
        },
        quotePriceLarge: {
            fontFamily: 'Spartan MB Bold',
            fontSize: '1.875rem',
        },
        quotePriceSmall: {
            fontFamily: 'Spartan MB Bold',
            fontSize: '0.8125rem',
        },
    }),
);

interface FormProps {
    MonthlyPremium: number;
    AnnualPremium: number;
    InitialValues: PaymentDetailsModel;
    SubmitHandler: (values: PaymentDetailsModel) => void;
};

const PaymentForm = ({ values, MonthlyPremium, AnnualPremium }: FormikProps<PaymentDetailsModel> & FormProps) => {
    const { state } = useStateValue();
    const classes = useStyles();
    const theme = useTheme();
    const checkboxClasses = useCheckboxStyles();
    const [checkedStatements, setCheckedStatements] = useState([false, false, false]);
    const { results } = useBankAPI(values.Bank.AccountNumber, values.Bank.SortCode);
    const [documentType, setDocumentType] = useState("");
    useDocument({ QuoteReference: state.SelectedQuote.QuoteReference, Client1: state.Client1, RiskAddress: state.RiskAddress, DocumentType: documentType });

    const monthlyPremiumSplit = MonthlyPremium ? MonthlyPremium.toString().split('.') : [0];
    const annualPremiumSplit = AnnualPremium ? AnnualPremium.toString().split('.') : [0];

    const handleChange = (index: number) => (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setCheckedStatements(checkedStatements.map((x, ind) => ind === index ? checked : x));
    };

    const handleClick = (documentType: string) => (event: MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        setDocumentType(documentType);
    };

    const canContinue = (): boolean => {
        if (values.Bank.AccountName &&
            values.Bank.AccountNumber && values.Bank.AccountNumber.length === 8 &&
            values.Bank.SortCode && values.Bank.SortCode.length === 6) {
            if (!results || !results.IsCorrect) {
                return false;
            }
        }
        else
            return false;

        if (!values.Payment)
            return false;

        return checkedStatements.filter(x => x === false).length === 0;
    };

    return (
        <Form noValidate>
            <PaymentRadioGroup
                annually={{
                    label: "Annually",
                    pricePounds: `£${annualPremiumSplit[0]}`,
                    pricePence: `.${annualPremiumSplit[1]}`,
                    priceDescription: <>A one-off<br />annual payment</>,
                }}
                monthly={{
                    label: "Monthly",
                    pricePounds: `£${monthlyPremiumSplit[0]}`,
                    pricePence: `.${monthlyPremiumSplit[1]}`,
                    priceDescription: <>Per month for <br />12 months</>,
                }}
            />
            <div className={classes.directDebitLogo}>
                <img src="/img/direct-debit.png" />
            </div>
            <MonthlyDirectDebitDisplay monthlyPrice={MonthlyPremium} />
            <Section sectionTitle={"Account Holder Name(s)"}>
                <Field name="Bank.AccountName"
                    id="account-holder-name"
                    className={classes.accountDetailsInput}
                    label=""
                    placeholder="Type your full name here"
                    component={MaterialTextField}
                    inputProps={{
                        'data-testid': 'account-holder-name',
                    }}
                />
            </Section>
            <Section sectionTitle={"Account Number"}>
                <Field name="Bank.AccountNumber"
                    id="account-number"
                    className={classes.accountDetailsInput}
                    label=""
                    placeholder="eg: 12345678"
                    component={MaterialTextField}
                    inputProps={{
                        maxLength: 8,
                        'data-testid': 'account-number',
                    }}
                />
            </Section>
            <Section sectionTitle={"Sort Code"}>
                <Field name="Bank.SortCode"
                    id="sort-code"
                    className={classes.accountDetailsInput}
                    label=""
                    placeholder="00 - 00 - 00"
                    component={MaterialTextField}
                    inputProps={{
                        maxLength: 6,
                        'data-testid': 'account-sort-code',
                    }}
                />
            </Section>
            <FormControl fullWidth className={classes.accountDetailsAddress}>
                <Box display="flex" justifyContent="flex-end">
                    <Typography id="invalidEntry" align="left">
                        {results && results.IsCorrect && values.Bank.SortCode.length == 6 && values.Bank.AccountNumber.length == 8 &&
                            `${results.Bank}, ${results.ContactAddressLine1}, ${results.ContactPostTown}, ${results.ContactPostcode}`
                        }
                        {results && results.Error &&
                            `${results.Cause} - ${results.Resolution}`
                        }
                        {results && !results.IsCorrect &&
                            `${results.StatusInformation}`
                        }
                    </Typography>
                </Box>
            </FormControl>
            <Section sectionTitle="Please check the following">
                <>
                    <MaterialCheckbox testId="statement" handleChange={handleChange(0)}>
                        <Typography className={checkboxClasses.formControlLabelSmall}>
                            I confirm I have checked the <a className={classes.docLink} onClick={handleClick("statementoffactandprice")}> statement of insurance</a> and am happy with the details I have provided.
                        </Typography>
                    </MaterialCheckbox>
                    <MaterialCheckbox testId="ipid" handleChange={handleChange(1)}>
                        <Typography className={checkboxClasses.formControlLabelSmall}>
                            I confirm I have checked the <a className={classes.docLink} onClick={handleClick("ipid")}>Insurance Product Information Document</a> and <a className={classes.docLink} onClick={handleClick("policywording")}>policy wording</a> and am happy with the cover offered.
                        </Typography>
                    </MaterialCheckbox>
                    <MaterialCheckbox testId="termsAndConditions" handleChange={handleChange(2)}>
                        <Typography className={checkboxClasses.formControlLabelSmall}>
                            I confirm I have read and understood the terms of business described in <a className={classes.docLink} href={theme.metadata.contentManagedLinks.paymentDocuments.termsOfBusinessUrl} target="_blank">about our insurance services.</a>
                        </Typography>
                    </MaterialCheckbox>
                </>
            </Section>
            <Grid container spacing={4} className={classes.quotePrice}>
                <Grid item xs={12}>
                    {
                        values.Payment &&
                        <div>
                            <Typography className={classes.quotePriceTitle} variant="body1" color="textSecondary">
                                Your quote
                            </Typography>
                            <Typography className={classes.quotePriceLarge} component="span">
                                £{values.Payment === 'Annually' ? annualPremiumSplit[0] : monthlyPremiumSplit[0]}
                            </Typography>
                            <Typography className={classes.quotePriceSmall} component="span">
                                .{values.Payment === 'Annually' ? annualPremiumSplit[1] : monthlyPremiumSplit[1]} {values.Payment}
                            </Typography>
                        </div>
                    }
                    <div style={{ display: 'flex' }}>
                        <BackButton>Back</BackButton>
                        <ContinueButton testId="makePayment" disabled={!canContinue()}>Make payment</ContinueButton>
                    </div>
                </Grid>
            </Grid>
        </Form >
    );
};

export default withFormik<FormProps, PaymentDetailsModel>({
    mapPropsToValues: (props: FormProps) => {
        return {
            HasStartDateWithin60Days: props.InitialValues.HasStartDateWithin60Days,
            PolicyStartDate: props.InitialValues.PolicyStartDate,
            MortgageProvider: props.InitialValues.MortgageProvider,
            MortgageRollNumber: props.InitialValues.MortgageRollNumber,
            ThirdPartyReference: props.InitialValues.ThirdPartyReference,
            SolicitorEmailAddress: props.InitialValues.SolicitorEmailAddress,
            ReceiveInsuranceDocumentsEmail: props.InitialValues.ReceiveInsuranceDocumentsEmail,
            ApplyDirect: props.InitialValues.ApplyDirect,
            PaidYearly: props.InitialValues.PaidYearly,
            Bank: {
                AccountName: props.InitialValues.Bank.AccountName,
                AccountNumber: props.InitialValues.Bank.AccountNumber,
                SortCode: props.InitialValues.Bank.SortCode,
            },
            Payment: props.InitialValues.Payment,
        };
    },
    handleSubmit: (values, { props }: FormikBag<FormProps, PaymentDetailsModel>) => {
        props.SubmitHandler(values);
    },
    validationSchema: PaymentSchema,
    displayName: 'PaymentForm',
})(PaymentForm);